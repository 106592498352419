import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Alert, Spin, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { DownloadOutlined, ClearOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import getParticipantesEstructutacion from '../../services/estructuracion/obtenerParticipantesEstructuracionService';
import { fetchAllProyectos } from '../../services/estructuracion/get_proyectos';

//Importamos libreria para la exportación de excel
import ExcelJS from 'exceljs';



const Caracterizacion = () => {
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [filteredParticipantes, setFilteredParticipantes] = useState([]);

    const [originalParticipantes, setOriginalParticipantes] = useState([]); // Estado para almacenar la lista original
    const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
    const [sortOrder, setSortOrder] = useState({});
    const [sortField, setSortField] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [proyectos, setProyectos] = useState([]);
    const [selectedProyectos, setSelectedProyectos] = useState([]); // Cambiado a una lista

    const appName = 'caracterizacion';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export === 'Y';

    const obtenerParticipantesEstructuracion = async () => {
        try {
            const { status, data } = await getParticipantesEstructutacion();
            setStatus(status);
            setFilteredParticipantes(data)
            setOriginalParticipantes(data); // Guardar la lista original
        } catch (error) {
            setStatus(500);
            setOriginalParticipantes([]);
            setFilteredParticipantes([]);
        } finally {
            setLoading(false);
        }
    };

    const obtenerProyectos = async () => {
        try {
            const data = await fetchAllProyectos();
            setProyectos(data);
        } catch (error) {
            setProyectos([]);
        }
    };

    useEffect(() => {
        obtenerProyectos();
        obtenerParticipantesEstructuracion();
    }, []);

    const handleProyectoChange = (value) => {
        setSelectedProyectos(value); // Guardar los proyectos seleccionados
        
        if (value.length > 0) {
            const filtered = originalParticipantes.filter(participante =>
                value.includes(participante.detalle.proyecto_estructuracion)
            );
            setFilteredParticipantes(filtered);
        } else {
            setFilteredParticipantes(originalParticipantes); // Restablecer la lista completa
        }
    };
    

    const renderErrorMessage = (status) => {
        if (status === 404) {
            return (
                <Alert
                    type="warning"
                    message="Advertencia"
                    description="Lo sentimos, no se encuentran participantes disponibles para mostrar."
                    showIcon
                    icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
                />
            );
        } else if (status === 500) {
            return (
                <Alert
                    type="error"
                    message="Error"
                    description="Lo sentimos, ha ocurrido un error al obtener los participantes. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co"
                    showIcon
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                />
            );
        }
    };

    const clearAllFilters = () => {
        setSelectedProyectos([]);
        setFilteredInfo({});
        setSelectedProyectos([]); // Restablecer los proyectos seleccionados
        setFilteredParticipantes(originalParticipantes); // Restablecer la lista original
    };

    const columns = [
        { title: 'Codigo', dataIndex: ['detalle', 'proyecto_codigo'], key: 'proyecto_codigo', width: 100 },
        { title: 'Proyecto', dataIndex: ['detalle', 'nombre_proyecto'], key: 'nombre_proyecto' },
        { title: 'Nombre', dataIndex: ['detalle', 'nombre'], key: 'nombre' },
        { title: 'Apellido', dataIndex: ['detalle', 'apellido'], key: 'apellido' },
        { title: 'Número de Identificación', dataIndex: ['detalle', 'numeroIdentificacion'], key: 'numeroIdentificacion' },
        { title: 'Fecha de Nacimiento', dataIndex: ['detalle', 'fechaNacimiento'], key: 'fechaNacimiento', render: (text) => new Date(text).toLocaleDateString() },
        { title: 'Edad', width: 100, dataIndex: ['detalle', 'edad'], key: 'edad' },
        { title: 'Sexo', dataIndex: ['detalle', 'sexo'], key: 'sexo' },
        { title: 'Departamento', width: 150, dataIndex: ['detalle', 'departamento'], key: 'departamento' },
        { title: 'Municipio', dataIndex: ['detalle', 'municipio'], key: 'municipio' },
    ];

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setSortOrder(sorter.order);
        setSortField(sorter.field);
        setFilteredInfo(filters);
    };

    const noDataConfig = {
        emptyText: (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InboxOutlined style={{ fontSize: '2rem' }} />
                <span>Sin resultados</span>
            </span>
        ),
    };

    if (loading) {
        return <div className="text-center"><Spin size="large" /></div>;
    }


    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileName = 'Participantes_estructuracion_UTF_COL_160' + fileExtension;

        // Crear un nuevo libro de trabajo
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Participantes');
        // Encabezado de múltiples niveles


        // Encabezado titulo
        worksheet.mergeCells('A1:AG1'); // Titulo
        worksheet.getCell('A1').value = 'CARACTERIZACIÓN POBLACIÓN PARTICIPANTE';


        // Ajustar el formato específico para la fila 1
        var row1 = worksheet.getRow(1);
        row1.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = {
                size: 16,
                bold: true,
                color: { argb: "1F3864" } // Color del texto
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
            // No se aplica color de fondo
        });


        // Encabezado de múltiples niveles
        worksheet.mergeCells('A2:A3'); // DATOS BÁSICOS
        worksheet.mergeCells('B2:L3'); // DATOS BÁSICOS
        worksheet.mergeCells('M2:R3'); // NIVEL DE ESCOLARIDAD
        worksheet.mergeCells('S2:AD3'); // POBLACIÓN
        worksheet.mergeCells('AE2:AT3'); // INFORMACIÓN FAMILIAR
        worksheet.mergeCells('AU2:BQ3'); // INFORMACIÓN DEL PROYECTO A ESTRUCTURAR
        worksheet.mergeCells('BR2:BU3'); // DATOS DE CONTACTO DEL PARTICIPANTE
        worksheet.mergeCells('BV2:BW3'); // CONECTIVIDAD
        worksheet.mergeCells('BX2:CE3'); // BANCARIZACIÓN
        worksheet.mergeCells('CF2:CP3'); // INFORMACIÓN ORGANIZACIONAL Y COMUNITARIA
        worksheet.mergeCells('CQ2:DY3'); // INFORMACIÓN PRODUCTIVA DE LA ACTIVIDAD SELECCIONADA PARA EL PROYECTO
        worksheet.mergeCells('DZ2:ED3'); // SERVICIOS TURÍSTICOS
        worksheet.mergeCells('EE2:ET3'); // DERECHO HUMANO A LA ALIMENTACIÓN ADECUADA (DHAA)
        worksheet.mergeCells('EU2:FB3'); // FIES: Durante los últimos 12 meses, DEBIDO A LA FALTA DE DINERO U OTROS RECURSOS hubo algún momento en que usted u otra persona del hogar:
        worksheet.mergeCells('FC2:FH3'); // Por favor mencione los tipos de alimentos (comidas  y refrigerios) que usted o cualquiera de los integrante de su familia comieron  o bebieron durante el día de ayer por el día y por la noche en el hogar.  Comience por la primera comida o bebida que tomaron por la mañana. NO OLVIDE INCLUIR BEBIDAS
        worksheet.mergeCells('FI2:FX3'); // ASPECTOS AMBIENTALES
        worksheet.mergeCells('FY2:GC3'); // VALIDACIÓN INTERNA ART

        // Asignación de valores a las celdas combinadas
        worksheet.getCell('A2').value = 'No.';
        worksheet.getCell('B2').value = 'DATOS BÁSICOS';
        worksheet.getCell('M2').value = 'NIVEL DE ESCOLARIDAD';
        worksheet.getCell('S2').value = 'POBLACIÓN';
        worksheet.getCell('AE2').value = 'INFORMACIÓN FAMILIAR';
        worksheet.getCell('AU2').value = 'INFORMACIÓN DEL PROYECTO A ESTRUCTURAR';
        worksheet.getCell('BR2').value = 'DATOS DE CONTACTO DEL PARTICIPANTE';
        worksheet.getCell('BV2').value = 'CONECTIVIDAD';
        worksheet.getCell('BX2').value = 'BANCARIZACIÓN';
        worksheet.getCell('CF2').value = 'INFORMACIÓN ORGANIZACIONAL Y COMUNITARIA';
        worksheet.getCell('CQ2').value = 'INFORMACIÓN PRODUCTIVA DE LA ACTIVIDAD SELECCIONADA PARA EL PROYECTO';
        worksheet.getCell('DZ2').value = 'SERVICIOS TURÍSTICOS';
        worksheet.getCell('EE2').value = 'DERECHO HUMANO A LA ALIMENTACIÓN ADECUADA (DHAA)';
        worksheet.getCell('EU2').value = 'FIES: Durante los últimos 12 meses, DEBIDO A LA FALTA DE DINERO U OTROS RECURSOS hubo algún momento en que usted u otra persona del hogar:';
        worksheet.getCell('FC2').value = 'Por favor mencione los tipos de alimentos (comidas  y refrigerios) que usted o cualquiera de los integrante de su familia comieron  o bebieron durante el día de ayer por el día y por la noche en el hogar.  Comience por la primera comida o bebida que tomaron por la mañana. NO OLVIDE INCLUIR BEBIDAS';
        worksheet.getCell('FI2').value = 'ASPECTOS AMBIENTALES';
        worksheet.getCell('FY2').value = 'VALIDACIÓN INTERNA ART';




        // Asignamos colores a las celdas combinadas
        worksheet.getCell('A2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '1E4E79' }
        };

        worksheet.getCell('B2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '1E4E79' }
        };

        worksheet.getCell('M2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '833C0B' }
        };

        worksheet.getCell('S2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '385623' }
        };

        worksheet.getCell('AE2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '2E75B5' }
        };

        worksheet.getCell('AU2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE598' }
        };

        worksheet.getCell('BR2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '7B7B7B' }
        };


        worksheet.getCell('BV2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '8EAADB' }
        };

        worksheet.getCell('BX2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '833C0B' }
        };

        worksheet.getCell('CF2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '7F6000' }
        };

        worksheet.getCell('CQ2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'A8D08D' }
        };

        worksheet.getCell('DZ2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00D6FF' }
        };

        worksheet.getCell('EE2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC001' }
        };

        worksheet.getCell('EU2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC001' }
        };

        worksheet.getCell('FC2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC001' }
        };

        worksheet.getCell('FI2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '558134' }
        };

        worksheet.getCell('FY2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '1E4E79' }
        };

        // Ajustar el formato específico para la fila 2
        var row2 = worksheet.getRow(2);
        row2.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = {
                size: 14,
                bold: true,
                color: { argb: 'FFFFFFFF' }  // Añadimos esta línea para hacer el texto blanco
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });

        const datos =
            [//DATOS BASICOS
                "",
                "Nombres",
                "Apellidos",
                "Tipo de Identificación",
                "No. de Identificación",
                "Fecha de Expedición",
                "Sexo",
                "Fecha De Nacimiento (dd_mm_aaaa)",
                "Edad Si/No",
                "¿Sabe leer y escribir? Si/No",
                "Ingresos Mensuales Promedio (pesos m/cte)",
                "Línea Económica Principal",
                "Ninguna",
                "Primaria",
                "Secundaria",
                "Tecnólogo/a",
                "Técnica",
                "Profesional",
                "Campesino/a",
                "Comunidades y pueblos indígenas",
                "Comunidad NARP",
                "Comunidad ROM",
                "Víctima del conflicto",
                "Personas reintegradas: acogidas en el marco de justicia y paz (AUC, FARC y ELN por ley 975)",
                "Personas restituidas/en proceso de restitución de tierras",
                "Discapacidad",
                "Mujer Rural",
                "Joven Rural",
                "Otro",
                "¿Cual?",
                "Grupo sisben",
                "Es Jefe/a de Familia (SI y NO)",
                "No. Personas a Cargo",
                "Total número de personas del núcleo familiar",
                "Estado Civil",
                "Nombre Completo Cónyuge, si lo tiene",
                "Tipo de Identificación del cónyuge",
                "No. Identificación del cónyuge",
                "Número de Contacto del cónyugue",
                "¿Cuantas horas del día usa para actividades diferentes a labores productivas, domésticas o comunitarias? (Tiempo de descanso)",
                "¿Qué hace en ese tiempo libre?",
                "¿Usted participa en la producción agropecuaria de la familia? ,¿Si la respuesta es sí, mencione que actividades desarrolla?",
                "¿En el hogar quién desarrolla en mayor medida las actividades domésticas y de cuidado? (Hombres o Mujeres)",
                "¿Cuántas horas al día le dedica a las actividades domésticas y de cuidado ?",
                "¿Las actividades agropecuarias de su familia en algún momento fueron afectadas por el conflicto armado? Si la respuesta es Sí describa como se afectó.",
                "¿Las personas jóvenes (hasta los 28 años) de su hogar prevén quedarse en el territorio? Si la respuesta es No, a donde prefieren irse.",
                "Código iniciativa ART",
                "Nombre del proyecto a estructurar",
                "Subregión PDET",
                "Departamento",
                "Municipio",
                "Tipo Zona de Influencia Directa",
                "Nombre de Zona de influencia directa",
                "Código Shape Veredas ART",
                "Nombre del Predio/Finca",
                "Relación de Tenencia con el Predio",
                "Georeferenciación Latitud",
                "Georeferenciación Longitud",
                "Área destinada al proyecto (ha)",
                "Línea Productiva 1 Proyecto",
                "Línea Productiva 2 Proyecto",
                "Distancia Lugar del Proyecto a Cabecera Municipal - (Kilómetros)",
                "Tiempo Lugar del Proyecto a Cabecera Municipal - (Minutos)",
                "Medio de Transporte",
                "¿Vive en el mismo lugar del Proyecto? SI/No",
                "¿Lugar de Vivienda?",
                "¿En cuál eslabón de la cadena productiva participan las mujeres?",
                "¿En cuál eslabón de la cadena productiva participan los y las jóvenes?",
                "¿La comunidad cuenta con Resguardo, Territorios colectivos constituidos y/o ZRC?",
                "Dirección de Correspondencia",
                "Número Teléfono de Contacto 1",
                "Número Teléfono de Contacto 2",
                "Correo Electrónico",
                "Telefónica Si/No",
                "Internet Si/No",
                "Tipo Establecimiento Bancario cercano a su Vivienda",
                "¿Cuenta con Productos Financieros? Si/No",
                "Cuenta de Ahorro Marque X",
                "Tarjeta de Crédito Marque X",
                "Otros Créditos Marque X",
                "Tipo de Entidad",
                "¿Ha recibido Capacitación en Educación Financiera? Si/No",
                "¿Interesado en Información de Inclusión Financiera? Si/No",
                "¿Vinculado a alguna Organización? Si/No",
                "Tipo de Organización",
                "Nombre de la Organización",
                "Es una organización de Mujeres? Si/No",
                "Es una organización de víctimas del conflicto armado? Si/No",
                "¿Considera usted que se presentan conflictos en su comunidad?",
                "¿Usted participa de actividades comunitarias?",
                "¿La comunidad cuenta con: plan de vida, plan de salvaguarda, plan de etnodesarrollo o plan de desarrollo sostenible?",
                "¿Ejercen prácticas tradicionales y ancestrales en el territorio? ¿cuales?",
                "¿Están organizados en Resguardo, Cabildo o Consejo Comunitario?",
                "¿Alguna institución pública o privada hace presencia en su comunidad? Si la respuesta es Si, menciónela.",
                "Línea productiva del proyecto",
                "Variedades o razas que tiene en la finca",
                "Área total de la finca (ha)",
                "El predio cuenta con servicios públicos agua y luz (Si/No)",
                "Área sembrada en el producto principal relacionado con el proyecto (ha)",
                "Cuenta con análisis de suelos de al menos el último año? SI/No",
                "Topografía del predio (Pendiente, muy pendiente, ondulada, plana)",
                "Fuente principal de agua para riego (Acueducto, agua lluvia, pozo, ciénaga, río)",
                "Régimen de precipitación (Monomodal, Bimodal)",
                "Densidad de siembra (árboles por ha o kg de semilla/ha) Para ganado (carga animal)",
                "Edad del cultivo (sólo para especies de ciclo largo)",
                "¿Está en producción? Si/No",
                "Cantidad producida el año pasado (Kg)",
                "¿Lleva registros de actividades y costos de la actividad productiva? Si/No",
                "¿Cuál es el costo de producción por Kg de producto?",
                "¿Hace algún tipo de transformación o agregación de valor? Si/No",
                "Si respondió que sí, descríba lo que realiza para transformar o agregar valor",
                "¿Quién participa en la transformación o agregación de valor? (Hombre o Mujer)",
                "¿Cuáles son los costos de la transformación por kg de producto?",
                "¿Cuál es su producto final?",
                "¿En cuáles municipios realiza la comercialización de los productos?",
                "¿A quién o quiénes le vende el producto final?",
                "¿Cuál es el precio unitario de venta de cada kg de producto final?",
                "¿Cuáles considera que son las principales problemáticas de su actividad productiva en su finca o municipio?",
                "¿Qué alternativas de solución considera las más adecuadas?",
                "¿En dónde o a quién le gustaría vender su producción?",
                "Lugar de compra de insumos (Cabecera municipal, capital, otros)",
                "Para reactivar o mejorar su producción, indique qué infraestructuras, equipos, materiales o suministros considera que son los más importantes",
                "¿Durante el último año recibió acompañamiento técnico o agroempresarial?",
                "Si su respuesta fue sí ¿En qué temas?",
                "¿Quién realizó ese acompañamiento?",
                "¿En qué temas le gustaría recibir acompañamiento o formación?",
                "¿Dónde Compra los insumos que utiliza para el desarrollo de la actividad agropecuaria?",
                "¿Le gustaría profundizar en temas asociado a la producción agropecuaria? Si la respuesta es Sí, mencione que temas.",
                "Comentarios adicionales del encuestador/a",
            
                "Tipo de organización (con ánimo de lucro, sin ánimo de lucro)",
                "¿Cuánto tiempo lleva la organización trabajando en temas turísticos?",
                "¿La Organización cuenta con Cámara de Comercio? (Si/No)",
                "¿La organización cuenta con Registro Nacional de Turismo? (Si/No)",
                "¿Cuál es el principal servicio turístico que ofrece? (Alojamiento, alimentación, operación turística, guianza, transporte turístico)",
             
                "Además de la línea productiva principal ¿Producen otros alimentos en su predio? (Si/No)",
                "Si respondió que sí ¿Cuáles y en qué área? Si respondió que no ¿Por qué?",
                "¿Tiene cría especies menores en su finca? (Sí/No) ¿Cuáles?",
                "De los alimentos y especies menores producidos/criadas en la finca ¿qué porcentaje destinan al autoconsumo?",
                "¿Cuentan con acompañamiento técnico u otras medidas para que su modelo productivo incorpore prácticas agroecológicas? (Diversificación de cultivos y especies animales, cuidado y protección de fuentes de agua y bosques, manejo sostenible de residuos sólidos, producción para autoconsumo, protección y conservación del suelo con materia orgánica, producción local de bioinsumos, uso y protección de semillas y razas criollas) (Si/No)",
                "Si la respuesta es Sí ¿de quién recibe ese acompañamiento?",
                "¿Implementan acciones de conservación e intercambio de semillas nativas y/o criollas, así como prácticas y saberes asociados a estas? (Si/No)",
                "Si la respuesta es Sí, descríbalas.",
                "¿Ha participado en espacios que promuevan la recuperación y fortalecimiento de los saberes tradicionales en torno a la alimentación? (Si/No)",
                "Si la respuesta es Sí, indique cuáles.",
                "¿Qué cambios ha percibido en los conocimientos y prácticas asociadas a la preparación y consumo de alimentos propios del territorio? (Si no ha percibido indique Ninguno)",
                "¿Ha participado en espacios que promuevan la adopción de prácticas de alimentación saludables? (Si/No)",
                "Si respondió que sí, menciónelos. Por ejemplo: espacios de formación institucionales (salud, ICBF, alcaldía), espacios comunitarios y organizativos, ONGs, academia, privados)",
                "¿Realiza prácticas para reducir el desperdicio de alimentos? (Si/No) Si la respuesta es Sí, menciónelas.",
                "¿Conoce usted alguna organización que trabaje en su comunidad o municipio alrededor del DHAA o soberanía alimentaria? Si la respuesta es Sí, indique cuál.",
                "¿Realiza algún tratamiento al agua para el consumo? Si la respuesta es Sí, mencione cuál.",

                //FIES: Durante los últimos 12 meses, DEBIDO A LA FALTA DE DINERO U OTROS RECURSOS hubo algún momento en que usted u otra persona del hogar:

                "1. Se preocupó por no tener suficientes alimentos para comer (Si/No/Ns-Nr)",
                "2. No pudo comer alimentos saludables y nutritivos (Si/No/Ns-Nr)",
                "3. Consumió poca variedad de alimentos (Si/No/Ns-Nr)",
                "4. Tuvo que saltar una comida (desayuno, almuerzo, comida o cena) (Si/No/Ns-Nr)",
                "5. Comió menos de lo que pensaba que debía comer (Si/No/Ns-Nr)",
                "6. El hogar se quedó sin alimentos (Si/No/Ns-Nr)",
                "7. Tuvo hambre pero no comió (Si/No/Ns-Nr)",
                "8. No comió en un día entero (Si/No/Ns-Nr)",
                //Por favor mencione los tipos de alimentos (comidas  y refrigerios) 


                "Desayuno (Hora; alimentos consumidos en casa)",
                "Refrigerio (Hora; alimentos consumidos en casa)",
                "Almuerzo (Hora; alimentos consumidos en casa)",
                "Refrigerio (Hora; alimentos consumidos en casa)",
                "Cena (Hora; alimentos consumidos en casa)",
                "Refrigerio (Hora; alimentos consumidos en casa)",

            //ASPECTOS AMBIENTALES
                "¿Cuáles considera son las principales problemáticas ambientales del territorio? Ejemplo: Deforestación, erosión y pérdida de suelo, disminución de la biodiversidad, contaminación del agua, expansión de la frontera agrícola, entre otras.",
                "¿Considera que ha cambiado el número de polinizadores como aves e insectos en el territorio? Si/No",
                "¿Conoce usted sobre buenas prácticas ambientales? Si/No",
                "¿Implementa buenas prácticas ambientales en la actividad productiva que desarrolla? Si/No Ejemplo: Manejo y conservación de suelos, uso mínimo de insumos químicos, protección de fuentes hídricas, labranza mínima, ahorro y uso eficiente del recurso hídrico, conservación de áreas boscosas, aprovechamiento de residuos orgánicos, clasificación y disposición de residuos sólidos inorgánicos.",
                "¿Cuáles son las figuras de protección o manejo ambiental en el entorno de su predio? Ejemplo: Parque Nacional Natural, Distrito de Manejo Integrado, Planes de ordenación y manejo de cuencas hídricas - POMCAS, Áreas de reserva forestal de Ley 2da, Reservas naturales de la sociedad civil, entre otros.",
                "¿Cuáles considera son las mayores necesidades de capacitación y formación en temas ambientales de su comunidad? Por ejemplo: Conservación y uso eficiente del agua, manejo y conservación de los suelos, eficiencia energética, manejo de residuos, mitigación del cambio climático, etc.",
                "¿Ha destinado áreas de su predio para la conservación ambiental? Si/No",
                "¿Implementa acciones para la cosecha, el ahorro y uso eficiente del agua? Si/No",
                "¿Cómo acceden al agua para consumo humano?",
                "¿Cómo acceden al agua para uso productivo?",
                "¿Implementa acciones de manejo de residuos sólidos? Si/No",
                "¿Implementa acciones de manejo de residuos líquidos? Si/No",
                "¿Su sistema productivo se ha visto afectado por alguna amenaza del orden climático-ambiental? Si/No ¿Cuál? Ejemplos: Remoción en masa, deslizamientos, incendios forestales, inundaciones, avenidas torrenciales, escasez de agua.",
                "¿Ha implementado acciones para enfrentar dichas amenazas? Ejemplos: Mitigación del efecto de desabastecimiento hídrico, cosechas de agua, estabilización de escorrentías, capacitación y equipamiento para el control de incendios forestales.",
                "¿Ha sufrido daños y/o pérdidas en su sistema productivo debido a estas situaciones?",
                "¿Cuáles instituciones han acompañado el desarrollo de acciones del orden ambiental en el territorio? Sepárelas por coma.",

                //VALIDACIÓN ART
                "¿Validado Por Registraduría?",
                "¿Validado Por Procuraduría?",
                "¿Validado Por Policía?",
                "RNMC Registro Nacional de Medidas correctivas.",
                "Estado dentro del proyecto",
            ];


        // Configuración del ancho de las columnas
        worksheet.columns = datos.map(() => ({ width: 20 }));
        // Agregar fila de encabezado
        worksheet.addRow(datos);

        // Ajustar el formato específico para la fila 4
        var row4 = worksheet.getRow(4);
        row4.height = 60; // Ajustar la altura fija de la fila

        row4.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = { size: 12, bold: true }; // Aplicar negrita y tamaño de fuente 12
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });


        // Define the valid options for nivel_escolaridad
        const opcionesValidas = ["Ninguna", "Primaria", "Secundaria", "Tecnólogo/a", "Técnica", "Profesional"];
        // Define the valid options for tipo_poblacion
        const opcionesTipoPoblacion = [
            "Campesino/a",
            "Comunidades y pueblos indígenas",
            "Comunidad NARP",
            "Comunidad ROM",
            "Víctima del conflicto",
            "Personas reintegradas: acogidas en el marco de justicia y paz (AUC, FARC y ELN por ley 975)",
            "Personas restituidas/en proceso de restitución de tierras",
            "Discapacidad",
            "Mujer Rural",
            "Joven Rural",
            "Otro",
            "¿Cual?"
        ];


        // Map and select all fields for each participant
        const dataToExport = filteredParticipantes.map(part => {
            // Create an array with default values "No" for each valid option
            let escolaridadArray = opcionesValidas.map(opcion => "");

            // Find the index of the participant's nivel_escolaridad in the valid options
            const index = opcionesValidas.indexOf(part.detalle.nivel_escolaridad);
            if (index !== -1) {
                // If the nivel_escolaridad is valid, set the corresponding position to "Sí"
                escolaridadArray[index] = "X";
            }
            // Create an array with default values "No" for each valid option
            let poblacionArray = opcionesTipoPoblacion.map(opcion => "");

            // Iterate over part.detalle.tipo_poblacion and set the corresponding positions to "Sí"
            part.detalle.tipo_poblacion.forEach(tipo => {
                const index = opcionesTipoPoblacion.indexOf(tipo);
                if (index !== -1) {
                    poblacionArray[index] = "X";
                }
            });

            // Handle the special case for "Otro"
            const indexOtro = opcionesTipoPoblacion.indexOf("Otro");
            if (indexOtro !== -1 && part.detalle.tipo_poblacion.includes("Otro")) {
                const indexCual = opcionesTipoPoblacion.indexOf("¿Cual?");
                poblacionArray[indexCual] = part.detalle.otro_tipo_poblacion || "No";
            }

            return [
                // Data DATOS BASICOS
                part.oid,
                part.detalle.nombre,
                part.detalle.apellido,
                part.detalle.tipoIdentificacion,
                part.detalle.numeroIdentificacion,
                part.detalle.fechaExpedicion,
                part.detalle.sexo,
                part.detalle.fechaNacimiento,
                part.detalle.edad,
                part.detalle.sabeLeerEscribir,
                part.detalle.ingresosMensuales,
                part.detalle.lineaEconomica,
                ...escolaridadArray, // Add the escolaridad array to the row
                ...poblacionArray, // Add the tipo_poblacion array to the row
                part.detalle.grupo_sisben + ' - ' + part.detalle.nivel_grupo,
                part.detalle.jefe_familia,
                part.detalle.total_personas_cargo,
                part.detalle.total_nucleo_familia,
                part.detalle.estado_civil,
                part.detalle.nombre_conyuge,
                part.detalle.tipo_identificacion_conyuge,
                part.detalle.numero_identificacion_conyuge,
                part.detalle.numero_contacto_conyuge,
                part.detalle.tiempo_libre_descanso,
                part.detalle.que_hace_tiempo_libre,
                part.detalle.participa_produccion_agropecuaria === "SÍ" ? 'SÍ  - ' + part.detalle.respuesta_participa_produccion_agropecuaria : 'NO',
                part.detalle.desarrolla_actividades_domesticas,
                part.detalle.horas_desarrolla_actividades_domesticas,
                part.detalle.actividades_domesticas_afectadas_conflictoArmado === "SÍ" ? 'SÍ  - ' + part.detalle.respuesta_actividades_domesticas_afectadas_conflictoArmado : 'NO',
                part.detalle.preven_quedarse_territorio === "NO" ? 'NO  - ' + part.detalle.respuesta_preven_quedarse_territorio : 'SÍ',
                part.detalle.proyecto_codigo,
                part.detalle.nombre_proyecto,
                part.detalle.subregion_pdet,
                part.detalle.departamento,
                part.detalle.municipio,
                part.detalle.tipo_zona_influencia,
                part.detalle.nombre_zona_influencia,
                part.detalle.codigo_shape_art,
                part.detalle.nombre_predio,
                part.detalle.relacion_tenencia_predio,
                part.detalle.georeferencia_latitud,
                part.detalle.georeferencia_longitud,
                part.detalle.area_destinada_proyecto,
                part.detalle.linea_productiva_1,
                part.detalle.linea_productiva_2,
                part.detalle.distancia_lugar_cabecera_municipal,
                part.detalle.tiempo_lugar_cabecera_municipal,
                part.detalle.medio_transporte,
                part.detalle.vive_mismo_lugar_proyecto,
                part.detalle.lugar_vivienda,
                part.detalle.eslabon_cadena_productiva_mujeres ? part.detalle.eslabon_cadena_productiva_mujeres.join(", ") : "",
                part.detalle.eslabon_cadena_productiva_jovenes ? part.detalle.eslabon_cadena_productiva_jovenes.join(", ") : "",
                part.detalle.comunidad_cuenta_resguardos ? part.detalle.comunidad_cuenta_resguardos.join(", ") : "",
                part.detalle.direccion_correspondencia,
                part.detalle.numero_telefono_1,
                part.detalle.numero_telefono_2,
                part.detalle.correo_electronico,
                part.detalle.telefonica,
                part.detalle.internet,
                part.detalle.tipo_establecimiento_bancario,
                part.detalle.cuenta_productos_financieros === "Sí" ? 'X ' : '',
                part.detalle.cuenta_ahorro === "Sí" ? 'X ' : '',
                part.detalle.tarjeta_credito === "Sí" ? 'X ' : '',
                part.detalle.otro_credito === "Sí" ? 'X ' : '',
                part.detalle.tipo_de_entidad,
                part.detalle.capacitacion_educacion_financiera,
                part.detalle.interesado_informacion_financiera,
                part.detalle.vinculado_alguna_organizacion,
                part.detalle.tipo_organizacion,
                part.detalle.nombre_organizacion,
                part.detalle.organizacion_mujeres,
                part.detalle.organizacion_conflicto_armado,
                part.detalle.presentan_conflictos_comunidad === "Sí" ? 'Sí  - ' + part.detalle.respuesta_presentan_conflictos_comunidad : 'No',
                part.detalle.participa_actividades_comunitarias === "Sí" ? 'Sí  - ' + part.detalle.respuesta_participa_actividades_comunitarias : 'No',
                part.detalle.comunidad_cuenta_con ? part.detalle.comunidad_cuenta_con.join(", ") : "",
                part.detalle.ejercen_practicas_tradicionales,
                part.detalle.estan_organizados_en,
                part.detalle.institucion_presencia_comunidad === "Sí" ? 'Sí  - ' + part.detalle.respuesta_institucion_presencia_comunidad : 'No',
                part.detalle.linea_productiva_proyecto,
                part.detalle.variedades_razas,
                part.detalle.area_total,
                part.detalle.servicios_publicos,
                part.detalle.area_sembrada,
                part.detalle.analisis_suelos,
                part.detalle.topografia,
                part.detalle.fuente_agua,
                part.detalle.regimen_precipitacion,
                part.detalle.densidad_siembra,
                part.detalle.edad_cultivo,
                part.detalle.produccion,
                part.detalle.cantidad_producida,
                part.detalle.registros_actividades,
                part.detalle.costo_produccion,
                part.detalle.transformacion_valor,
                part.detalle.respuesta_transformacion_valor ? part.detalle.respuesta_transformacion_valor : "",
                part.detalle.participacion_transformacion,
                part.detalle.costos_transformacion,
                part.detalle.producto_final,
                part.detalle.municipios_comercializacion ? part.detalle.municipios_comercializacion.join(", ") : "",
                part.detalle.compradores_producto ? part.detalle.compradores_producto.join(", ") : "",
                part.detalle.precio_unitario,
                part.detalle.problematicas ? part.detalle.problematicas.join(", ") : "",
                part.detalle.alternativas_solucion,
                part.detalle.venta_produccion ? part.detalle.venta_produccion.join(", ") : "",
                part.detalle.lugar_compra_insumos,
                part.detalle.infraestructura_equipos ? part.detalle.infraestructura_equipos.join(", ") : "",
                part.detalle.acompanamiento_tecnico,
                part.detalle.respuesta_acompanamiento_tecnico ? part.detalle.respuesta_acompanamiento_tecnico.join(", ") : "",
                part.detalle.quien_acompanamiento,
                part.detalle.temas_formacion,
                part.detalle.lugar_compra,
                part.detalle.profundizar_produccion === "Sí" ? 'Sí  - ' + part.detalle.respuesta_profundizar_produccion : 'No',
                part.detalle.comentarios_adicionales,

                //SERVICIOS TURISTICOS
                part.detalle.tipo_organizacion_turistico,
                part.detalle.tiempo_trabajando,
                part.detalle.camara_comercio,
                part.detalle.registro_turismo,
                part.detalle.principal_servicio ? part.detalle.principal_servicio.join(", ") : "",

               
                //DERECHO HUMANO A LA ALIMENTACIÓN ADECUADA (DHAA)
                part.detalle.produce_others,
                part.detalle.other_products_area,
                part.detalle.cria_especies_menores === "Sí" ? 'Sí  - ' + part.detalle.especies_menores : 'No',
                part.detalle.autoconsumo,
                part.detalle.acompanamiento_tecnico_modelo_productivo,
                part.detalle.acompanamiento_tecnico_modelo_productivo === "Sí" ? part.detalle.de_quien_acompanamiento : 'No',
                part.detalle.acciones_conservacion,
                part.detalle.acciones_conservacion === "Sí" ? part.detalle.acciones_descripcion.join(", ")  : 'No',
                part.detalle.espacios_tradicionales,
                part.detalle.espacios_tradicionales === "Sí" ? part.detalle.espacios_indicacion.join(", ") : 'No',
                part.detalle.cambios_conocimientos,
                part.detalle.practicas_saludables,
                part.detalle.practicas_saludables === "Sí" ? part.detalle.practicas_saludables_detalles : 'No',
                part.detalle.reduce_waste === "Sí" ? 'Sí  - ' + part.detalle.reduce_waste_detalles : '',
                part.detalle.conoce_organizaciones === "Sí" ? 'Sí  - ' + part.detalle.organizaciones_detalles : '',
                part.detalle.water_treatment === "SÍ" ? 'SÍ  - ' + part.detalle.water_treatment_detalles : '',

                //FIES: Durante los últimos 12 meses, DEBIDO A LA FALTA DE DINERO U OTROS RECURSOS hubo algún momento en que usted u otra persona del hogar:
                part.detalle.suficiente_comida,
                part.detalle.alimentos_saludables_nutritivos,
                part.detalle.variedad_alimentos,
                part.detalle.saltar_comida,
                part.detalle.comio_menos_comida,
                part.detalle.hogar_quedo_sin_alimentos,
                part.detalle.hambre_pero_no_comio,
                part.detalle.no_comio_dia_entero,

                //Por favor mencione los tipos de alimentos (comidas  y refrigerios) 
        
                part.detalle.desayuno,
                part.detalle.desayuno_refrigerio,
                part.detalle.almuerso,
                part.detalle.almuerzo_refrigerio,
                part.detalle.cena,
                part.detalle.cena_refrigerio,

                //ASPECTOS AMBIENTALES
                part.detalle.principales_problematicas_ambientales ?part.detalle.principales_problematicas_ambientales.join(", "): "",
                part.detalle.change_in_pollinators,
                part.detalle.buenas_practicas_ambientales.join(", "),
                part.detalle.implementa_buenas_practicas_ambientales.join(", "),
                part.detalle.figuras_de_proteccion.join(", "),
                part.detalle.necesidades_de_capacitacion.join(", "),
                part.detalle.areas_conservacion_ambiental + '-' + part.detalle.respuesta_areas_conservacion_ambiental,
                part.detalle.implementa_acciones_haorro_agua === 'Sí' ? part.detalle.respuesta_implementa_acciones_haorro_agua :  part.detalle.respuesta_implementa_acciones_haorro_agua,
                part.detalle.acceden_agua_consumo_humano,
                part.detalle.acceden_agua_consumo_productivo,
                part.detalle.acciones_manejo_residuos_solidos === 'Sí' ? part.detalle.respuesta_acciones_manejo_residuos_solidos :  part.detalle.respuesta_acciones_manejo_residuos_solidos,
                part.detalle.acciones_manejo_residuos_liquidos === 'Sí' ? part.detalle.respuesta_acciones_manejo_residuos_liquidos :  part.detalle.respuesta_acciones_manejo_residuos_liquidos,
                part.detalle.amenaza_orden_climatico_ambiental === 'Sí' ? part.detalle.respuesta_amenaza_orden_climatico_ambiental.join(", ") : "No",
                part.detalle.implementado_acciones_enfrendar_dichas_amenazas.join(", "),
                part.detalle.perdidas_sistema_productivo ? part.detalle.perdidas_sistema_productivo: "No",
                part.detalle.instituciones_acompanado_orden_ambiental.join(", "),

                //Validación interna ART
                part.detalle.validado_registraduria,
                part.detalle.validado_procuraduria,
                part.detalle.validado_policia,
                part.detalle.validado_RNMC,
                part.detalle.estado_proyecto,
                
            ];
        });


        // Agregar los datos a la hoja
        worksheet.addRows(dataToExport);




        // Ajustar el formato de todas las celdas
        worksheet.eachRow(
            { includeEmpty: true },
            function (row, rowNumber) {
                row.eachCell(
                    { includeEmpty: true },
                    function (cell, colNumber) {
                        cell.border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        }; // Bordes
                    }
                );
            }
        );


        // Guardar el archivo Excel
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };
    return (
        <div className="container">
            <React.Fragment>
                {status === 404 || status === 500 ? (
                    renderErrorMessage(status)
                ) : (
                    <React.Fragment>
                        <div className="row mb-2 d-flex align-items-center">
                            <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                                <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                                    <p>PROYECTO UTF <br /> COL 160 COL</p>
                                </div>
                                <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                                    <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                <h2 className="text-center mb-2">Listado de participantes</h2>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="input-group shadow-sm">
                                            {permissionInsert && (
                                                <Link to="/registrar-participante">
                                                    <Button
                                                        type="danger"
                                                        className="btn btn-primary me-2"
                                                        size="large"
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Nueva
                                                    </Button>
                                                </Link>
                                            )}
    
                                            {permissionExport && (
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary me-2"
                                                    size="large"
                                                    onClick={() => exportToExcel()}
                                                    icon={<DownloadOutlined />}
                                                >
                                                    Excel
                                                </Button>
                                            )}
    
                                            <Button
                                                type="danger"
                                                className="btn btn-primary"
                                                size="large"
                                                onClick={clearAllFilters}
                                                icon={<ClearOutlined />}
                                            >
                                                Limpiar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <div className="filter-section d-flex justify-content-start align-items-center">
                                    <label className="me-2" style={{ minWidth: '150px' }}>Filtrar por Proyecto:</label>
                                    <Select
                                        mode="multiple"
                                        placeholder="Selecciona proyectos"
                                        onChange={handleProyectoChange}
                                        value={selectedProyectos}
                                        style={{ width: '300px', marginBottom: '10px' }}
                                    >
                                        {proyectos.map((proyecto) => (
                                            <Select.Option key={proyecto.proyecto_oid} value={proyecto.proyecto_oid}>
                                                {proyecto.proyecto_codigo} - {proyecto.nombre_proyecto}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
    
                        {filteredParticipantes.length > 0 ? (
                            <Table
                                columns={columns}
                                dataSource={filteredParticipantes}
                                rowKey='oid'
                                bordered
                                pagination={pagination}
                                onChange={handleTableChange}
                                sortDirections={['ascend', 'descend']}
                                sortOrder={sortOrder}
                                onSorterChange={setSortOrder}
                                sortField={sortField}
                                locale={noDataConfig}
                                scroll={{ x: 1300 }}
                                style={{ fontSize: '14px' }}
                            />
                        ) : (
                            <div className="text-center">
                                <Alert
                                    type="warning"
                                    message="Advertencia"
                                    description="Lo sentimos, no se encuentran participantes disponibles para mostrar."
                                    showIcon
                                    icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        </div>
    );
    
};

export default Caracterizacion;
