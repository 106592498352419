import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { login as loginService } from '../services/auth_service/auth_service';
import { logout as logoutService } from '../services/auth_service/logout_service';
import { jwtDecode } from 'jwt-decode';
//import { useNavigate } from 'react-router-dom';
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  //const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('token'));
  const [user, setUser] = useState(Cookies.get('username') || null);
  const [applications, setApplications] = useState(() => {
    const apps = Cookies.get('applications');
    return apps ? JSON.parse(apps) : [];
  });



  const login = async (username, password) => {
    try {
      const data = await loginService(username, password);
      Cookies.set('token', data.token, { expires: 1 });
      Cookies.set('username', username, { expires: 1 });
      Cookies.set('applications', JSON.stringify(data.applications), { expires: 1 });
      setIsAuthenticated(true);
      setUser(username);
      setApplications(data.applications);
      if (data.applications.length === 0) {
        return { token: data.token, applications: [] };
      } else {
        return data;
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setIsAuthenticated(false);
      setApplications([]);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await logoutService();
      Cookies.remove('token');
      Cookies.remove('username');
      Cookies.remove('applications');
      setIsAuthenticated(false);
      setUser(null);
      setApplications([]);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, applications, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;