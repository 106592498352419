import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import BuscarProyecto from './components/BuscarProyecto/BuscarProyecto';
import ActualizarProyecto from './components/ActualizarProyecto/ActualizarProyecto';
import Organizaciones from './components/Organizaciones/Organizaciones';
import AccesoRestringido from './components/AccesoRestringido/AccesoRestringido';
import FileManager from './components/DetalleProyecto/FileManager';
import MerOrganizaciones from './components/Organizaciones/MerOrganizaciones';
import Poa from './components/Poa/Poa';
import LogosFooter from './components/LogosHeader/LogosFooter';
import NavBodecom from './components/header/navBodecom';
import LoginBodecom from './components/Login/loginBodecom';
import PrivateRoute from './components/Login/PrivateRoute';
import AuthProvider from './contexts/AuthContext';
import ReporteInformes from './components/Informes/InsumoReportes';
import MapaInicio from './components/mapas/mapaInicio';
import Inicio from './components/Inicio/Inicio';
import Participantes from './components/Participantes/Participantes';
import EsProyecto from './components/Estructuracion/esctructuracion';
import ProyectoView from './components/Estructuracion/proyecto_estructuracion';
import AcuerdosComerciales from './components/AcuerdosComerciales/AcuerdosComerciales';
import AppSlider from './components/appSlider/AppSlider';
import Formulario from './components/FormularioParticipantes/Formulario';
import Caracterizacion from './components/FormularioParticipantes/Caracteriazacion';
import Ippta from './components/Ippta/Ippta';
import Edah from './components/EncuestaEdah/Edah';

const AppContent = () => {
  const location = useLocation();
  const showNavAndFooter = !['/', '/acceso-restringido'].includes(location.pathname);

  return (
    <>
      {showNavAndFooter && <NavBodecom />}
      <Routes>
        <Route path="/" element={<LoginBodecom />} />
        <Route path="/mapas" element={<PrivateRoute element={<MapaInicio />} />} />
        <Route path="/reportes" element={<PrivateRoute element={<ReporteInformes />} />} />
        <Route path="/es_proyectos" element={<PrivateRoute element={<EsProyecto />} />} />
        <Route path="/es_estructura/:oid" element={<PrivateRoute element={<ProyectoView />} />} />
        <Route path="/inicio" element={<PrivateRoute element={<Inicio />} />} />
        <Route path="/buscar-proyecto" element={<PrivateRoute element={<BuscarProyecto />} />} />
        <Route path="/actualizar-proyecto" element={<PrivateRoute element={<ActualizarProyecto />} />} />
        <Route path="/organizaciones/:oid_proyecto_busqueda?" element={<PrivateRoute element={<Organizaciones />} />} />
        <Route path="/mer/:oid_proyecto_busqueda?" element={<PrivateRoute element={<MerOrganizaciones />} />} />
        <Route path="/file-manager" element={<PrivateRoute element={<FileManager />} />} />
        <Route path="/poa" element={<PrivateRoute element={<Poa />} />} />
        <Route path="/DocumentosFAO" element={<Navigate to="/acceso-restringido" />} />
        <Route path="/DocumentosActualizacion" element={<Navigate to="/acceso-restringido" />} />
        <Route path="/DocumentosOrganizaciones" element={<Navigate to="/acceso-restringido" />} />
        <Route path="/participantes" element={<PrivateRoute element={<Participantes />} />} />
        <Route path="/acuerdos-comerciales" element={<PrivateRoute element={<AcuerdosComerciales />} />} />
        <Route path="/acuerdos-comerciales" element={<PrivateRoute element={<AcuerdosComerciales />} />} />
        <Route path="/app-slider"  element={<PrivateRoute element={<AppSlider />} />}  />
        <Route path="/caracterizacion"  element={<PrivateRoute element={<Caracterizacion />} />}  />
        <Route path="/sistematizacionIppta"  element={<PrivateRoute element={<Ippta />} />}  />
        <Route path="/registrar-participante"  element={<PrivateRoute element={<Formulario />} />}  />
        <Route path="/encuestaEdah"  element={<PrivateRoute element={<Edah />} />}  />
        <Route path="*" element={<Navigate to="/acceso-restringido" />} />
      </Routes>
      {showNavAndFooter && <LogosFooter />}
    </>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
