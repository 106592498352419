import axios from 'axios';
import config from '../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/getOrgDetalle';
const getDetalleProyectos = async () => {
    try {

        const response = await axios.get(`${baseUrl}${endpoint}`, {}, {});
        return response.data; // Asegúrate de acceder a response.data para obtener el objeto completo
    } catch (error) {
        throw new Error('Error fetching projects');
    }
};

export default getDetalleProyectos;
