// src/components/MyForm.jsx
import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Typography, Radio, Row, Col, Modal, message } from 'antd';
import departmentListForm160 from './data';

import {
    listCereales, listRaices, listTuberculos, listVerdurasHojaVerde,
    listOtrasVerduras, listFrutasVitaminaA, listOtrasFrutas,
    listCarneVisceras, listCarnes, listHuevos, listPescadoMariscos,
    listLeguminosasLegumbresNuecesSemillas, listLecheProductosLacteos,
    listAceitesGrasas, listDulces, listEspeciasCondimentosBebidas
} from './data';

//Servicios
import insertEdah from '../../services/edah/insertEdahService';
import getEdahporIdentificacion from '../../services/edah/getEdahporIdentificacionService';

const { Option } = Select;
const { Title, Text } = Typography;



const Edahform = ({ visible, onClose, data }) => {
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState('');
    const [isEditing, setisEditing] = useState(false);
    const departments = departmentListForm160();
    const [form] = Form.useForm();

    const labelCereales = listCereales(departamentoSeleccionado, '¿La familia consumió cereales?');
    const labelRaices = listRaices(departamentoSeleccionado, '¿La familia consumió raíces y/o tubérculos blancos?');
    const labelTuberculos = listTuberculos(departamentoSeleccionado, '¿La familia consumió tubérculos y/o verduras ricos en vitamina A?');
    const labelVerdurasHojasVerdes = listVerdurasHojaVerde(departamentoSeleccionado, '¿La familia consumió verduras de hoja verde oscuro?');
    const labelOtrasverduras = listOtrasVerduras(departamentoSeleccionado, '¿La familia consumió otras verduras?');
    const labelFrutasVitaminaA = listFrutasVitaminaA(departamentoSeleccionado, '¿La familia consumió frutas ricas en vitamina A?');
    const labelOtrasFrutas = listOtrasFrutas(departamentoSeleccionado, '¿La familia consumió otras frutas?');
    const labelCarneVisceras = listCarneVisceras(departamentoSeleccionado, '¿La familia consumió carne de vísceras?');
    const labelCarnes = listCarnes(departamentoSeleccionado, '¿La familia consumió carnes?');
    const labelHuevos = listHuevos(departamentoSeleccionado, '¿La familia consumió huevos?');
    const labelPescadoMariscos = listPescadoMariscos(departamentoSeleccionado, '¿La familia consumió pescado y/o mariscos?');
    const labelLeguminosasLegumbresNuecesSemillas = listLeguminosasLegumbresNuecesSemillas(departamentoSeleccionado, '¿La familia consumió leguminosas, legumbres, nueces y/o semillas?');
    const labelLecheProductosLacteos = listLecheProductosLacteos(departamentoSeleccionado, '¿La familia consumió leche y/o productos lacteos?');
    const labelAceitesGrasas = listAceitesGrasas(departamentoSeleccionado, '¿La familia consumió aceites y/o grasas?');
    const labelDulces = listDulces(departamentoSeleccionado, '¿La familia consumió dulces?');
    const labelEspeciasCondimentosBebidas = listEspeciasCondimentosBebidas(departamentoSeleccionado, '¿La familia consumió especias, condimentos y/o bebidas??');

    const handleDepartamentoChange = (value) => {

        setDepartamentoSeleccionado(value);

    };

    useEffect(() => {
        if (Object.keys(data).length === 0) {
            setisEditing(false)

            // Limpiar los campos del formulario al registrar
            form.resetFields();
        } else {
            setisEditing(true)
            // Llenar el formulario con los datos recibidos
            form.setFieldsValue(data);
        }
    }, [form, data]); // Solo depende de form y data

    const handleCancel = () => {
        const fieldsValue = form.getFieldsValue();
        const hasFilledFields = Object.values(fieldsValue).some(value => value !== undefined && value !== '');

        if (hasFilledFields) {
            Modal.confirm({
                title: '¿Está seguro de cerrar?',
                content: 'Hay campos que han sido diligenciados o calificaciones seleccionadas. Si cierra, se perderán los datos.',
                onOk: () => {
                    onClose();

                },
            });
        } else {
            onClose();
        }
    }

    const handleFormSubmit = async () => {
        try {
            const values = await form.validateFields(); // Espera a que se validen los campos
            // Aquí debes asegurarte de que estás pasando los valores correctos a `insertEdah`
            const newFormData = { ...values }; // Asegúrate de ajustar esto según tu lógica
            const respuestaServicio = await insertEdah(newFormData);

            if (respuestaServicio.status === 200) {
                // Cierra el modal después de guardar
                onClose();
                message.success(respuestaServicio.message);
            } else {
                message.error(respuestaServicio.message);
            }

        } catch (error) {
            message.error('Error al enviar el formulario. Por favor, verifica los campos y vuelve a intentarlo.');
        }
    };
    const handleIdentificacionChange = async (value) => {
        try {
            const respuesta = await getEdahporIdentificacion(value);
            
            if (respuesta.validacion === true) {
                setisEditing(true);
                // Llenar el formulario con los datos recibidos
                form.setFieldsValue(respuesta.detalle);
                message.success(respuesta.message);
            } else {
                setisEditing(false);

                // Limpiar todos los campos del formulario, excepto el de identificación
                // Resetea todos los campos del formulario
                form.resetFields(); // Esto restablece todos los campos a su estado inicial
    
                // Mantener el campo de identificación con su valor actual
                form.setFieldsValue({
                    identification: value, // O puedes usar: identification: form.getFieldValue('identification')
                });
    
                message.warning(respuesta.message);
            }
        } catch (error) {
            message.error('Error al obtener datos: ' + error.message); // Asegúrate de manejar bien el error
        }
    };
    

    return (
        <Modal
            title={
                <div>
                    <div className="custom-modal-title">Encuesta de EDAH</div>
                    <div className="custom-modal-subtitle">Encuesta de la diversidad alimentaria en los hogares</div>
                </div>
            }
            open={visible}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleFormSubmit}>
                    {isEditing ? 'Actualizar' : 'Enviar'}
                </Button>
            ]}
            width={800} // Ajusta este valor según tus necesidades
        >

            <Form
                form={form}
                layout="vertical"
                name='form_encuestaEdah'

            >

                <Title style={{ color: '#052956' }} level={4}>Información del encuestado</Title>
                <Row gutter={16}>
                <Col xs={24} sm={12}>
                        <Form.Item
                            name="identification"
                            label="Documento de identidad del encuestado"
                            rules={[{ required: true, message: 'Por favor ingrese el documento de identidad del encuestado' }]}
                        >
                            <Input
                                placeholder="Documento de identidad del encuestado"
                                onBlur={(e) => handleIdentificacionChange(e.target.value)} // Asegúrate de que esto sea una función
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="encuestadoNombre"
                            label="Nombre del encuestado"
                            rules={[{ required: true, message: 'Por favor ingrese el nombre del encuestado' }]}
                        >
                            <Input placeholder="Nombre del encuestado" />
                        </Form.Item>
                    </Col>

                  
                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="department"
                            label="Departamento"
                            rules={[{ required: true, message: 'Por favor seleccione un departamento' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Seleccione un departamento"
                                optionFilterProp="children"
                                onChange={handleDepartamentoChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {departments.map((department) => (
                                    <Option key={department.name} value={department.name}>
                                        {department.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="city"
                            label="Ingrese el municipio"
                            rules={[{ required: true, message: 'Por favor ingrese el municipio' }]}
                        >
                            <Input placeholder="Municipio" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>

                        <Form.Item
                            name="place"
                            label="Ingrese la vereda"
                            rules={[{ required: true, message: 'Por favor ingrese la vereda' }]}
                        >
                            <Input placeholder="Vereda" />
                        </Form.Item>
                    </Col>


                </Row>

                <Title style={{ color: '#052956' }} level={4}>Ubicación del hogar</Title>

                <Row gutter={16}>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="latuser"
                            label="Latitud"
                            rules={[{ required: true, message: 'Por favor ingrese la latitud' }]}
                        >
                            <Input placeholder="Latitud" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>

                        <Form.Item
                            name="lnguser"
                            label="Longitud"
                            rules={[{ required: true, message: 'Por favor ingrese la longitud' }]}
                        >
                            <Input placeholder="Longitud" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="altitude"
                            label="Altitud"
                            rules={[{ required: true, message: 'Por favor ingrese la altitud' }]}
                        >
                            <Input placeholder="Altitud" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="accuracy"
                            label="Precisión"
                            rules={[{ required: true, message: 'Por favor ingrese la precisión' }]}
                        >
                            <Input placeholder="Precisión" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="measurement"
                            label="Medición"
                            rules={[{ required: true, message: 'Por favor seleccione un tipo de medición' }]}
                        >
                            <Radio.Group>
                                <Radio value="inicial">Inicial</Radio>
                                <Radio value="intermedia">Intermedia</Radio>
                                <Radio value="final">Final</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                </Row>

                <Title style={{ color: '#052956' }} level={4}>Diversidad alimentaria en el hogar</Title>


                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="snack1"
                            label="Anote el desayuno o primera comida del día"
                            rules={[{ required: true, message: 'Desayuno o primera comida del día' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Anote el desayuno o primera comida del día"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="lunch"
                            label="Anote el refrigerio (1)"
                            rules={[{ required: true, message: 'Ingresa el refrigerio (1)' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Refrigerio (1)"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="snack2"
                            label="Anote el almuerzo"
                            rules={[{ required: true, message: 'Ingresa el almuerzo' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Anote el almuerzo"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="refrigerio_2"
                            label="Anote el refrigerio (2)"
                            rules={[{ required: true, message: 'Ingresa el refrigerio (2)' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Refrigerio (2)"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="cena"
                            label="Anote la cena"
                            rules={[{ required: true, message: 'Ingresa la cena' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Anote la cena"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="refrigerio_3"
                            label="Anote el refrigerio (3)"
                            rules={[{ required: true, message: 'Ingresa el refrigerio (3)' }]}
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Recuerde incluir todas las preparaciones con sus ingredientes. Si no lo consume, escriba: ninguno.
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Refrigerio (3)"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="observationsLuch"
                            label="Observaciones"
                            extra={
                                <Text type="secondary" style={{ marginTop: 8 }}>
                                    Diligencia las observaciones que considere pertinentes frente a la indagación de la alimentación en el hogar (No obligatorio).
                                </Text>
                            }
                        >
                            <Input.TextArea
                                placeholder="Observaciones"
                                rows={4}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Title style={{ color: '#052956' }} level={4}>Puntaje de la diversidad alimentaria en el hogar</Title>


                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="cereales"
                            label={labelCereales}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="tuberculos"
                            label={labelRaices}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="verdurasVitaminaA"
                            label={labelTuberculos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="verdurasVerdeOscuro"
                            label={labelVerdurasHojasVerdes}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="otrasVerduras"
                            label={labelOtrasverduras}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="frutasVitaminaA"
                            label={labelFrutasVitaminaA}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="otrasFrutas"
                            label={labelOtrasFrutas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="carneVisceras"
                            label={labelCarneVisceras}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>



                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="carnes"
                            label={labelCarnes}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="huevos"
                            label={labelHuevos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="pescado"
                            label={labelPescadoMariscos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="leguminosas"
                            label={labelLeguminosasLegumbresNuecesSemillas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="leche"
                            label={labelLecheProductosLacteos}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="aceites"
                            label={labelAceitesGrasas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="dulces"
                            label={labelDulces}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="especias"
                            label={labelEspeciasCondimentosBebidas}
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="fueraCasa"
                            label="¿Alguiende la familia consumió o tomó algún alimento fuera de casa?"
                            rules={[{ required: true, message: 'Seleciona una opción' }]}
                        >
                            <Radio.Group>
                                <Radio value="Sí">Sí</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>


    );
};

export default Edahform;
