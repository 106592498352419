import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const selectUserApplications = createSelector(
    [selectAuth],
    (auth) => auth.applications
);

export const selectAppPermissions = createSelector(
    [selectUserApplications, (state, appName) => appName],
    (applications, appName) => {

        return applications.find(app => app.app_name === appName) || {};
    }
);
