import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgTEndpoint = '/api/getTiposOrg';

// Crear una instancia de Axios para configurar base URL y otras opciones
const api = axios.create({
    baseURL: config.baseUrl,
});

// Interceptor de solicitud para añadir el token de autorización
api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const getTiposOrganizacion = async () => {
    try {
        const response = await api.get(`${baseUrl}${orgTEndpoint}`);
        return {
            status: response.status,
            data: response.data.TiposOrganizacion
        };
    } catch (error) {
        console.error('Error fetching organization types:', error);
        return {
            status: error.response ? error.response.status : 500,
            message: 'Error fetching organization types'
        };
    }
};

export default getTiposOrganizacion;
