import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/guardar-ippta';

export const saveIpptaData = async (data) => {
  try {
    // Enviar los datos al endpoint '/api/ippta' de tu backend
    const response = await axios.post(`${baseUrl}${endpoint}`, data);
    return response.data; // Retornar la respuesta del backend
  } catch (error) {
    console.error('Error al guardar los datos:', error);
    throw error; // Lanzar el error para que pueda ser manejado en el frontend
  }
};
