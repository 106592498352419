import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { EnvironmentOutlined, DashboardOutlined } from '@ant-design/icons'; // Importa los íconos
import ColombiaMapComponent from '../Maps/MapsInicial';
import DashboardInicial from '../DashboardInicial/DashboardInicial';

function Inicio() {
    const [selectedComponent, setSelectedComponent] = useState('map'); // Cargar mapa por defecto

    return (
        <>
            <div style={{ textAlign: 'center', padding: '10px' }}>
                <Space style={{ marginBottom: '20px' }}>
                    <Button
                        type="primary btn btn-primary"
                        icon={<EnvironmentOutlined />} // Icono de mapa
                        onClick={() => setSelectedComponent('map')}
                        style={{ paddingTop: '2px' }}
                    >
                        Mapa
                    </Button>
                    <Button
                        type="primary  btn btn-primary"
                        icon={<DashboardOutlined />} // Icono de dashboard
                        onClick={() => setSelectedComponent('dashboard')}
                        style={{ paddingTop: '2px' }}
                    >
                        Dashboard
                    </Button>
                </Space>
                <div>
                    {selectedComponent === 'map' && <ColombiaMapComponent />}
                    {selectedComponent === 'dashboard' && <DashboardInicial />}
                </div>
            </div>
        </>
    );
}

export default Inicio;
