import axios from 'axios';
import config from '../../config'; // Asegúrate de que la ruta sea la correcta según tu estructura de proyecto

const baseUrl = config.baseUrl;
const endpoint = '/api/poaCorteUpdate';

// Función para actualizar la fecha en poaCorte
export const updatePoaCorteFecha = async (oid, fecha) => {
  try {
    // Realizar la solicitud PUT al endpoint
    const response = await axios.put(`${baseUrl}${endpoint}`, {
      oid,
      fecha,
    });

    // Devolver la respuesta del servidor
    return response.data;
  } catch (error) {
    console.error('Error al actualizar la fecha:', error.response?.data?.message || error.message);
    throw error;
  }
};

// Función para obtener todos los registros de poaCorte
export const fetchPoaCorteData = async () => {
  try {
    // Realizar la solicitud GET al endpoint para obtener los datos
    const response = await axios.get(`${baseUrl}${endpoint}`);

    // Devolver los datos obtenidos del servidor
    return response.data;
  } catch (error) {
    console.error('Error al obtener los datos de poaCorte:', error.response?.data?.message || error.message);
    throw error;
  }
};
