import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/deleteSupportMER/';

const deleteSoporteMER = async (soporteId) => {
  try {
    const response = await axios.delete(`${baseUrl}${endpoint}${soporteId}`, {});
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al eliminar el soporte MER:', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error al eliminar el soporte MER',
    };
  }
};

export { deleteSoporteMER };
