import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const appsEndpoint = '/api/some-protected-route';
const authEndpoint = '/api/auth/login';

const login = async (username, password) => {
  try {
    let token;
    try {
      const userAgent = navigator.userAgent;
      const response = await axios.post(`${baseUrl}${authEndpoint}`, {
        login: username,
        password,
        user_agent: userAgent
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      token = response.data.token;
    } catch (loginError) {
      console.error('Error during login:', loginError);
      throw new Error('Login failed');
    }

    try {
      const appsResponse = await axios.get(`${baseUrl}${appsEndpoint}`, {
        headers: {
          'authorization': `Bearer ${token}`
        },
        withCredentials: true
      });
      if (appsResponse.data.message === 'No apps') {
        return { token, applications: [] };
      }
      return { token, applications: appsResponse.data.applications };
    } catch (appsError) {
      console.error('Error fetching applications:', appsError.response?.data || appsError.message);

      if (appsError.response && appsError.response.status === 403 && appsError.response.data.message === 'No apps') {
        throw new Error('No tiene aplicaciones asignadas');
      }

      throw new Error('Fetching applications failed: ' + (appsError.response?.data.message || appsError.message));
    }



  } catch (error) {
    console.error('General error during login process:', error);
    throw error;
  }
};

export { login };
