import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/organizaciones-mer';

const getOrganizacionesMer = async () => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los datos:', error);
    throw error;
  }
};

export { getOrganizacionesMer };