import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/updateReport'; // Cambia el endpoint al adecuado para actualización

/**
 * Actualiza el estado de un reporte.
 * @param {number} oid - ID del reporte a actualizar.
 * @param {number} oidEstado - Nuevo estado para el reporte.
 * @param {string} periodo - Nuevo periodo para el reporte.
 * @param {string} observacion - Nueva observación para el reporte.
 * @returns {Promise<Object>} - Promesa que resuelve la respuesta del servidor.
 */
const updateReport = async (oid, oidEstado, periodo, observacion) => {
  try {
    // Realiza una solicitud PUT al endpoint con el ID del reporte y los nuevos datos
    const response = await axios.put(`${baseUrl}${endpoint}/${oid}`, {
      oidEstado: parseInt(oidEstado, 10), // Convierte a número entero para asegurarse de que sea correcto
      periodo,
      observacion
    });

    // Retorna el estado y los datos de la respuesta
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    // Manejo del error
    console.error('Error al actualizar el reporte:', error);
    throw error; // Lanza el error para ser manejado en el componente React
  }
};

export { updateReport };
