// Función para obtener la fecha y hora actual en la zona horaria de Bogotá, Colombia
function getLocalDateInTimezone(timeZone) {
    const nowUtc = new Date(); // Fecha y hora actual en UTC
    const options = {
        timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    // Convertir a la zona horaria específica
    const formattedDate = nowUtc.toLocaleString('en-US', options);

    // Separar la fecha y la hora
    const [date, time] = formattedDate.split(', ');

    // Cambiar el formato a 'YYYY-MM-DD HH:MM:SS'
    const [month, day, year] = date.split('/');
    const formattedDateMySQL = `${year}-${month}-${day} ${time}`;

    return formattedDateMySQL;
}

// Función que construye la estructura del log y la retorna
export function logAction(user, actionType, nameServer, resourceDetails) {
    const { nameResource, Path, fatherResource, typeResource, key, fullPath, resourceType } = resourceDetails;

    // Obtener la fecha y hora actual en la zona horaria de Bogotá dentro de la función
    const localDate = getLocalDateInTimezone('America/Bogota');

    // 1. Recolectar los datos de usuario
    const userData = {
        user,
        nameServer
    };

    // 2. Recolectar los datos de la acción
    const actionData = {
        actionType,
        fechaAction: localDate // Fecha actual en formato ISO 8601
    };

    // 3. Recolectar los datos del recurso
    const resourceData = {
        nameResource,
        Path,
        fullPath, // Ruta completa proporcionada por resourceDetails
        fatherResource,
        typeResource,
        key,
        resourceType
    };

    // 4. Construir el objeto del log
    const logEntry = {
        userData,
        actionData,
        resourceData
    };

    // 5. Retornar el objeto del log
    return logEntry;
}
