import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Button, Tree, Space, Tooltip, Popconfirm, message, Input, Form, DatePicker, Upload, Spin, Progress, Table } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { get_mv_support } from '../../services/poa/get_mv_support';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { QuestionCircleOutlined, PlusOutlined, MessageOutlined, CloudUploadOutlined, CloudDownloadOutlined, FileOutlined, HistoryOutlined, FolderOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import eliminarRegistro from '../../services/poa/delete_poa_MV';
import delete_folder_poa_MV from '../../services/poa/delete_folder_poa_MV';
import { get_file_support } from '../../services/poa/get_file_support';
import { view_support_file } from '../../services/poa/view_support_file';
import { upload_file } from '../../services/poa/post_support';
import './PoaObservaciones.css';
import { view_tree_file } from '../../services/create_tree/view_tree_file';
import { getTreeFile } from '../../services/create_tree/get_tree_file';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import { downloadFolder } from '../../services/poa/downloadServiceAllMV';


const { DirectoryTree } = Tree;
const { TextArea } = Input;

const PoaTree = ({ isVisible, onClose, oid }) => {
    const [activeKey, setActiveKey] = useState("1");
    const [treeData, setTreeData] = useState([]);
    const [treeData2, setTreeData2] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [form] = Form.useForm();
    const [selectedNode, setSelectedNode] = useState(null);
    const [isFormModified, setIsFormModified] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [foldersLoaded, setFoldersLoaded] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const maxExpandLevel = 3;
    const appName = 'poa';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    //const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const username = useSelector((state) => state.auth.user);
    const [messageShown, setMessageShown] = useState(false); // Estado para manejar el mensaje

    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        if (isVisible && oid) {
            fetchTreeData();
        }
    }, [isVisible, oid]);


    useEffect(() => {
        if (isVisible && oid) {
            if (treeData.length) {
                // Generar claves hasta el nivel especificado
                const generateExpandedKeys = (nodes, level = 0) => {
                    let keys = [];
                    const traverseNodes = (nodes, currentLevel) => {
                        nodes.forEach(node => {
                            keys.push(node.key);
                            if (node.children && currentLevel < maxExpandLevel) {
                                traverseNodes(node.children, currentLevel + 1);
                            }
                        });
                    };
                    traverseNodes(nodes, level);
                    return keys;
                };

                const keys = generateExpandedKeys(treeData);
                setExpandedKeys(keys);
            }
        }

    }, [treeData, maxExpandLevel]);

    const handleFormChange = () => {
        setIsFormModified(true);
    };
    const handleTabChange = (key) => {
        if (isFormModified || fileList.length > 0 || foldersLoaded.length > 0) {
            Modal.confirm({
                title: 'Cambios no guardados',
                content: 'Tienes cambios no guardados. ¿Estás seguro de que quieres cambiar de pestaña? Los datos no guardados se perderán.',
                okText: 'Sí',
                cancelText: 'No',
                onOk: async () => {
                    setFileList([]);
                    setActiveTab(key);
                    form.resetFields();
                    setIsFormModified(false);
                    setFoldersLoaded([]);
                    setFoldersLoaded(false);
                    await fetchTreeData();
                },
                onCancel: () => { }
            });
        } else {
            setActiveTab(key);
        }
    };

    const showModal = (e, node) => {
        setSelectedNode(node);
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        if (isFormModified || fileList.length > 0 || foldersLoaded.length > 0) {
            Modal.confirm({
                title: 'Cambios no guardados',
                content: 'Tienes cambios no guardados. ¿Estás seguro de que quieres cerrar el modal? Los datos no guardados se perderán.',
                okText: 'Sí',
                cancelText: 'No',
                onOk: async () => {
                    setFileList([]);
                    setIsModalVisible(false);
                    form.resetFields();
                    setIsFormModified(false);
                    setFoldersLoaded([]);
                    setFoldersLoaded(false);
                    await fetchTreeData();
                },
                onCancel: () => { }
            });
        } else {
            setIsModalVisible(false);
            form.resetFields(); // Limpiar los campos si es necesario
        }
    };

    const fetchTreeData = async () => {
        try {
            // Validar que oid y los campos necesarios no sean null, undefined, NaN o vacíos
            if (!oid || !oid.datoIndicador || !oid.datoIndicador.medioVerificacion || !oid.datoIndicador.medioVerificacion[0]) {
                return; // Detener la ejecución si las validaciones fallan
            }

            const oidA = oid.datoIndicador.medioVerificacion[0].oidA;
            const oidT = oid.datoIndicador.medioVerificacion[0].oidT;
            const oidI = oid.datoIndicador.medioVerificacion[0].oidI;

            // Verificar que los parámetros no sean NaN, null, undefined o vacíos antes de hacer la solicitud
            if ([oidA, oidT, oidI].some(param => param == null || param === '' || isNaN(param))) {
                console.error('Error: Uno o más parámetros son inválidos.');
                return; // Detener la ejecución si los parámetros no son válidos
            }

            // Realizar las solicitudes si los parámetros son válidos
            const data = await get_mv_support(oidA, oidT, oidI, 1);
            const data2 = await get_mv_support(oidA, oidT, oidI, 2);

            setTreeData(data);
            setTreeData2(data2);

        } catch (error) {
            console.error('Error al obtener los datos del árbol:', error);
        }
    };



    const handleChangeTab = (key) => {
        setActiveKey(key);
    };

    function getFileExtension(filename) {
        return filename.split('.').pop();
    }
    const isViewableExtension = (extension) => {
        const viewableExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
        return viewableExtensions.includes(extension);
    };

    // Función para manejar la acción de "Ver"
    const handleView = async (node) => {
        if (!isViewableExtension(getFileExtension(node.title))) {
            console.error('Tipo de archivo no soportado para visualización');
            message.error('El tipo de archivo no puede ser visualizado en el navegador.', 5);
            //setSpinnerVisible(false); // Ocultar spinner si el tipo de archivo no es soportado
            return;
        }
        try {
            const filePath = `${node.path}`;
            const result = await view_support_file(filePath);
            if (result.status !== 200) {
                console.error('Error al visualizar el archivo:', result.message);
                message.error('Error al descargar el archivo.', 5);
            } else {
                message.success('Archivo abierto correctamente.');
            }
        } catch (error) {
            console.error('Error al visualizar el archivo:', error.message || error);
            message.error('Error al descargar el archivo.', 5);
        } finally {
            //setSpinnerVisible(false); // Ocultar spinner cuando la visualización se complete
        }
    };

    // Función para manejar la acción de "Descargar"
    const handleDownload = async (path) => {

        try {
            if (!path || !path.path) {
                throw new Error('Información de nodo inválida');
            }
            const result = await get_file_support(path);
            if (result) {
                message.success('Archivo descargado correctamente.');
            } else {
                message.error('Error al descargar el archivo.', 5);
            }
        } catch (error) {
            // Manejo de diferentes tipos de errores
            if (error.response) {
                // Errores de respuesta HTTP
                message.error(`Error de red: ${error.response.status} - ${error.response.statusText}`, 5);
            } else if (error.request) {
                // Errores de solicitud (p. ej., el servidor no responde)
                message.error('No se recibió respuesta del servidor.', 5);
            } else {
                // Errores del cliente o de la aplicación
                message.error(`Error: ${error.message}`, 5);
            }
            console.error('Error al descargar el archivo:', error);
        }
    };

    // Función para manejar la acción de "Eliminar"
    const handleDelete = async (path) => {

        const user = username.username || '';
        try {
            if (path.type === 'folder') {
                const ruta = removeSubstring(path.path).replace(/^\/+/, '');
                await delete_folder_poa_MV(ruta, user);
            } else {
                await eliminarRegistro(path.oid, user);
            }
            message.success('El archivo o carpeta ha sido eliminado exitosamente.');
            await fetchTreeData();
        } catch (error) {
            message.error('Hubo un error al eliminar el archivo o carpeta.');
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }


    // Función para agregar botones a los nodos del árbol
    const addButtonsToNodes = (nodes) => {
        const maxTitleLength = 150; // Número máximo de caracteres permitidos en el título
        return nodes.map(node => {
            let buttons = [];
            let tooltipColor = '#87d068';
            let statusMessage = null;
            if (node.estado === 2) {
                tooltipColor = '#ff4d4f';
                statusMessage = <strong style={{ color: 'red' }}>Registro eliminado</strong>;
            }
            const uniqueKey = `${node.key}-${Date.now()}`;
            if (node.key.startsWith('medio-verificacion-') || node.key.startsWith('documento-') || node.key.startsWith('medio-verificacion-') || node.key.startsWith('folder-')) {
                if (node.oidI !== 73) {
                    if (node.type === 'folder' && !node.path.includes('Informe')) {
                        buttons = [
                            permissionInsert && (
                                <React.Fragment key={`fragment-upload-${uniqueKey}`}>
                                    <span key={`divider1-${uniqueKey}`} style={{ margin: '0 2px', paddingLeft: '30px', fontSize: '16px' }}> | </span>
                                    <Tooltip title="Cargar Datos">
                                        <Button key={`upload-${uniqueKey}`} className="focus-button btn-primary-lis" style={{ paddingRight: '10px' }} type="link" size="small" icon={<CloudUploadOutlined />} onClick={(e) => showModal(e, node)} />
                                    </Tooltip>
                                </React.Fragment>
                            ),
                            <span key={`divider2-${uniqueKey}`} style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                            <Tooltip title="Descargar Carpetas" key={`download-${uniqueKey}`}>
                                <Button className="focus-button btn-primary-lis" onClick={(e) => { e.stopPropagation(); download_folder(node); }} type="link" size="small" icon={<CloudDownloadOutlined />} />
                            </Tooltip>,
                            <span key={`divider3-${uniqueKey}`} style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                            permissionDelete && (
                                <Tooltip title="Eliminar" key={`delete-${uniqueKey}`}>
                                    <Popconfirm
                                        title="¿Seguro que quieres eliminar esta carpeta?"
                                        onConfirm={() => handleDelete(node)}
                                        okText="Sí"
                                        cancelText="No"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    >
                                        <Button
                                            className="focus-button"
                                            type="link"
                                            size="small"
                                            icon={<FontAwesomeIcon icon={faTrash} />}
                                            onClick={(e) => e.stopPropagation()} // Detiene la propagación del clic
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            ),
                        ];

                    } else if (node.type === 'file') {
                        // Botones para archivos
                        if (node.estado === 0) {
                            buttons = [
                                <Tooltip title="Ver">
                                <button
                                  className="btn-primary-lis"
                                  style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                                  onClick={() => handleViewInfo(node)}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </button>
                              </Tooltip>,
                              <span style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                              <Tooltip title="Descargar Archivo">
                                <button
                                  className="btn-primary-lis"
                                  style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                                  onClick={() => handleDownloadInfo(node)}
                                >
                                  <FontAwesomeIcon icon={faDownload} />
                                </button>
                              </Tooltip>,
                              <span style={{ margin: '0 2px', fontSize: '16px' }}> | </span>
                            ]
                        } else {
                            buttons = [
                                <span key={`title-${uniqueKey}`} style={{ margin: '0 2px', fontSize: '16px' }}>
                                    <strong>Usuario: </strong>{node.usuario_subida}
                                    {statusMessage && <span> | {statusMessage}</span>}                        </span>,
                                <span key={`divider5-${uniqueKey}`} style={{ margin: '0 2px', paddingLeft: '30px', fontSize: '16px' }}> | </span>,
                                <Tooltip title="Ver" key={`view-${uniqueKey}`}>
                                    <Button className="focus-button btn-primary-lis" type="link" size="small" onClick={() => handleView(node)} icon={<FontAwesomeIcon icon={faEye} />} />
                                </Tooltip>,
                                <span key={`divider6-${uniqueKey}`} style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                                <Tooltip title="Descargar Archivo" key={`download-${uniqueKey}`}>
                                    <Button className="focus-button btn-primary-lis blanck" type="link" size="small" onClick={() => handleDownload(node)} icon={<FontAwesomeIcon icon={faDownload} />} />
                                </Tooltip>,
                                <span key={`divider7-${uniqueKey}`} style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                                permissionDelete && (
                                    <Tooltip title="Eliminar" key={`delete-${uniqueKey}`}>
                                        <Popconfirm
                                            title="¿Seguro que quieres eliminar este archivo?"
                                            onConfirm={() => handleDelete(node)}
                                            okText="Sí"
                                            cancelText="No"
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        >
                                            <Button className="focus-button" type="link" size="small" icon={<FontAwesomeIcon icon={faTrash} />} />
                                        </Popconfirm>
                                    </Tooltip>
                                ),
                                <span key={`divider8-${uniqueKey}`} style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                                <Tooltip
                                    title={
                                        <div>
                                            <div><strong>Información</strong></div>
                                            <div><strong>Fecha Registro: </strong>{formatDate(node.fecha_creacion)}</div>
                                            <div><strong>Usuario: </strong>{node.usuario_subida}</div>
                                            <div><strong>Observación: </strong>{node.observacion}</div>
                                            {node.estado === 2 && (
                                                <>
                                                    <div><strong>Fecha de eliminacion: </strong>{formatDate(node.fecha_eliminado)}</div>
                                                    <div><strong>Usuario que borró el archivo: </strong>{node.user_eliminado}</div>
                                                </>
                                            )}
                                        </div>
                                    }
                                    key={`infoI-${node.key}`}
                                    color={tooltipColor} // Aplica el color condicionalmente
                                >
                                    <Button className="focus-button btn-primary-lis" type="link" size="small" icon={<MessageOutlined />} />
                                </Tooltip>,
                                <span key={`divider9-${uniqueKey}`} style={{ margin: '0 2px', fontSize: '16px' }}> | </span>,
                            ];
                        }

                    }


                }
            }

            const truncatedTitle = node.title.length > maxTitleLength
                ? `${node.title.substring(0, maxTitleLength)}...`
                : node.title;

            const prefixMapping = {
                'actividad-': 'Actividad: ',
                'tarea-': 'Tarea: ',
                'indicador-': 'Indicador: ',
                'medio-verificacion-': 'Medios de Verificación: '
            };

            const titlePrefix = Object.keys(prefixMapping).find(prefix => node.key.startsWith(prefix));

            const titleWithPrefix = titlePrefix
                ? <span><strong>{prefixMapping[titlePrefix]}</strong>{truncatedTitle}</span>
                : <span>{truncatedTitle}</span>;


            return {
                ...node,
                key: node.key,
                title: (
                    <Space><Tooltip title={node.title}>
                        <span>{titleWithPrefix}</span>
                    </Tooltip>{buttons}
                    </Space>
                ),
                children: addButtonsToNodes(node.children || [])
            };
        });
    };




    const treeDataWithButtons = addButtonsToNodes(treeData);

    const treeDataWithButtons2 = addButtonsToNodes(treeData2);
    const handleDownloadInfo = async (node) => {
        try {
          const result = await getTreeFile(node);
          if (result) {
            message.success('Archivo descargado correctamente.');
          } else {
            message.error('Error al descargar el archivo.', 5);
          }
        } catch (error) {
          message.error('Error al descargar el archivo.', 5);
          console.error('Error al descargar el archivo:', error);
        } 
      };
    const handleViewInfo = async (node) => {
        const path = node.path;
        if (!path) {
          console.error('No se proporcionó la ruta del archivo');
          message.error('No se proporcionó la ruta del archivo.', 5);
          return;
        }
    
        const extension = getFileExtension(path);
    
        if (!isViewableExtension(extension)) {
          console.error('Tipo de archivo no soportado para visualización');
          message.error('El tipo de archivo no puede ser visualizado en el navegador.', 5);
          return;
        }
    
        try {
          const result = await view_tree_file(path);
          if (result.status !== 200) {
            console.error('Error al visualizar el archivo:', result.message);
            message.error('Error al descargar el archivo.', 5);
          } else {
            message.success('Archivo abierto correctamente.');
          }
        } catch (error) {
          console.error('Error al visualizar el archivo:', error.message || error);
          message.error('Error al descargar el archivo.', 5);
        }
      };


    const download_folder = async (nodo) => {

        try {
            if (!nodo || !nodo.path) {
                throw new Error('Información de nodo inválida');
            }
            const result = await get_file_support(nodo);
            if (result) {
                message.success('Archivo descargado correctamente.');
            } else {
                message.error('Error al descargar el archivo.', 5);
            }
        } catch (error) {
            // Manejo de diferentes tipos de errores
            if (error.response) {
                // Errores de respuesta HTTP
                message.error(`Error de red: ${error.response.status} - ${error.response.statusText}`, 5);
            } else if (error.request) {
                // Errores de solicitud (p. ej., el servidor no responde)
                message.error('No se recibió respuesta del servidor.', 5);
            } else {
                // Errores del cliente o de la aplicación
                message.error(`Error: ${error.message}`, 5);
            }
            console.error('Error al descargar el archivo:', error);
        }
    };
    const handleUploadChange = (info) => {
        const { fileList: newFileList, file } = info;
        setFileList(newFileList);

        // Verifica si el archivo está en proceso de carga
        if (file.status === 'uploading') {
            setLoading(true);
            setUploadProgress(0);
            setMessageShown(false);
        }

        // Verifica si el archivo ha sido cargado completamente
        if (file.status === 'done') {
            setLoading(false);
            setUploadProgress(100);
            if (!messageShown) {
                message.success('Archivo cargado exitosamente.');
                setMessageShown(true);
            }
        }

        // Verifica si ha ocurrido un error
        if (file.status === 'error') {
            setLoading(false);
            setUploadProgress(0);
            if (!messageShown) {
                message.error('Error al cargar el archivo.');
                setMessageShown(true);
            }
        }
    };
    const handleRemove = (file) => {
        setFileList(fileList.filter(item => item.uid !== file.uid));
    };
    const getDirectoryPath = (filePath) => {
        // Asegúrate de que el nombre del archivo no esté vacío y contenga un punto
        if (filePath && filePath.includes('/')) {
            // Encuentra el último índice del separador de directorios
            const lastSlashIndex = filePath.lastIndexOf('/');
            // Retorna la subcadena que contiene solo la ruta de directorio
            return filePath.substring(0, lastSlashIndex + 1);
        }
        return ''; // Devuelve una cadena vacía si no se encuentra un separador de directorios
    };

    function removeSubstring(str) {
        const pathParts = str.split("/");
        let result = pathParts.slice(4).join("/");
        if (result.trim() === '') {
            return result;
        }
        if (!result.endsWith('/')) {
            result += '/';
        }
        return result;
    }

    const handleFormSubmit = async (values) => {
        if (fileList.length === 0 && !foldersLoaded) {
            message.error('Por favor, suba al menos un archivo');
            return;
        }

        setLoading(true); // Mostrar el spinner
        setUploadProgress(0); // Reiniciar el progreso

        try {
            // Extrae datos del objeto oid
            const { datoIndicador } = oid;
            const { oid: oid_MediosV_Indicadores, medioVerificacion } = datoIndicador;
            const { oidMV: oid_medio_verificacion } = medioVerificacion[0];

            // Obtén el nombre de usuario desde las cookies
            const user = username.username || '';
            const ruta2 = removeSubstring(selectedNode.path);
            // Crea una nueva instancia de FormData
            const formData = new FormData();
            formData.append('oid_mediosv_indicadores', oid_MediosV_Indicadores);
            formData.append('oidMV', oid_medio_verificacion);
            formData.append('fecha_creacion', values.fecha.format('YYYY-MM-DD'));
            formData.append('usuario_subida', user);
            formData.append('titulo', values.titulo);
            formData.append('observacion', values.observacion);
            formData.append('ruta', selectedNode.path); //Ruta de la carpeta
            // Añade los archivos al FormData
            fileList.forEach((file) => {
                formData.append('files', file.originFileObj || file);
            });

            // Añade metadata para cada archivo
            fileList.forEach((file) => {
                let rutaWeb = file.webkitRelativePath;
                if (!file.webkitRelativePath) {
                    rutaWeb = file.originFileObj.webkitRelativePath;
                }
                const metadata = {
                    nombre_soporte: file.name,
                    ruta_archivo: `${ruta2}${getDirectoryPath(rutaWeb)}`, //Ruta de BD
                    tipo_archivo: getFileExtension(file.name),
                };

                formData.append('file_metadata[]', JSON.stringify(metadata));
            });

            // Configura el progreso de carga
            const onUploadProgress = (progressEvent) => {
                const { loaded, total } = progressEvent;
                if (total > 0) {
                    setUploadProgress(Math.round((loaded * 100) / total));
                }
            };

            const response = await upload_file(formData, onUploadProgress);

            // Verifica el resultado de la respuesta
            if (response.status === 200 && response.data) {
                message.success('Registro guardado exitosamente');
                setIsModalVisible(false);
                form.resetFields();
                setIsFormModified(false);
                setFileList([]);
                setFoldersLoaded(false);
                await fetchTreeData();
            } else {
                message.error('Hubo un problema al guardar el registro');
            }
        } catch (error) {
            message.error('Error al enviar los datos: ' + error.message);
        } finally {
            setLoading(false); // Ocultar el spinner
        }
    };

    const beforeUpload = (file) => {
        const maxSize = 100 * 1024 * 1024; // 100 MB en bytes

        // Verificar si el archivo supera el tamaño máximo permitido
        if (file.size > maxSize) {
            message.error(`El archivo "${file.name}" es demasiado pesado para cargar. El límite es 100 MB.`, 5);
            setLoading(false); // Ocultar spinner si el archivo es demasiado grande
            return false; // Detener la carga si el archivo es demasiado grande
        }

        // Añadir el archivo a la lista de archivos para manejarlo posteriormente
        setFileList((prevList) => [...prevList, file]);
        return false; // Cancela la carga automática
    };

    const handleFolderChange = (info) => {
        const { fileList: newFileList } = info;
        setFileList(newFileList);
        setFoldersLoaded(false);

        // Mostrar spinner y progreso inmediatamente
        setLoading(true);
        setUploadProgress(0);

        // Asegurarse de que el spinner se muestre antes de comenzar la carga
        requestAnimationFrame(() => {
            const totalFiles = newFileList.length;
            let completedFiles = 0;
            let messageShown = false; // Estado para asegurar que solo mostramos el mensaje una vez

            // Simular la subida de archivos con un setTimeout para cada archivo
            newFileList.forEach((file, index) => {
                setTimeout(() => {
                    completedFiles += 1;

                    // Actualiza el progreso
                    setUploadProgress(Math.round((completedFiles / totalFiles) * 100));

                    // Verificar si todos los archivos han sido "subidos"
                    if (completedFiles === totalFiles) {
                        if (!messageShown) {
                            //message.success('Carpetas y archivos cargados. Están a la espera de ser guardados.');
                            messageShown = true; // Asegura que el mensaje solo se muestra una vez
                        }
                        setLoading(false);
                        setFoldersLoaded(true);
                    }
                }, 500 * index);
            });
        });
    };

    const customRequest = ({ file, onSuccess, onError }) => {
        // Verifica si el archivo es una carpeta vacía
        if (file.size === 0) {
            onError(new Error('La carpeta está vacía.'));
            return;
        }

        // Implementa la lógica de carga personalizada si es necesario
        onSuccess();
    };

    const findNodePathContaining = (data) => {
        for (const node of data) {
          if (node.key.includes('medio-verificacion-')) {
            return node.path; // Return the path if key contains the search string
          }
          if (node.children) {
            const result = findNodePathContaining(node.children, 'medio-verificacion-');
            if (result) return result; // If path found in children, return it
          }
        }
        return null; // If key not found
      };

    const handleDownloadMV = async () => {
        const basePath = 'D:/Comunix/bodecom_160/backend/DocumentosFAO/DocumentosPOA/';
        const folderPath = `${basePath}${findNodePathContaining(treeData)}`;
        const zipFileName = `${treeData[0].codigo_actividad} Actividad.zip`;
        const success = await downloadFolder(folderPath, zipFileName);
        if (success) {
            message.success('Descarga completada con éxito');
        } else {
            message.error('Hubo un problema durante la descarga');
        }
    };

    const tabItems = [
        {
            label: '',
            key: '1',
            children: (
                <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 50 }}
                    layout="vertical"
                    style={{ maxWidth: 800 }}
                    form={form}
                    initialValues={{
                        fecha: null,
                        titulo: '',
                        observacion: '',
                    }}
                    onFinish={handleFormSubmit}
                    onValuesChange={handleFormChange}

                >
                    <Form.Item
                        label="Fecha"
                        name="fecha"
                        rules={[{ required: true, message: 'Por favor, ingrese la fecha' }]}
                    >
                        <DatePicker
                            style={{ width: '100%' }} format="YYYY-MM-DD" />
                    </Form.Item>

                    <Form.Item
                        label="Título"
                        name="titulo"
                        rules={[{ required: true, message: 'Por favor, ingrese el título' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Observación"
                        name="observacion"
                    >
                        <TextArea rows={2} />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ maxHeight: '200px', maxWidth: '500px', overflowY: 'auto' }}>
                            <Upload
                                fileList={fileList}
                                onChange={handleUploadChange}
                                onRemove={handleRemove}
                                beforeUpload={beforeUpload}
                                multiple
                                listType="picture-card"
                                action={null} // Ensures no automatic upload
                            >
                                <div style={{ paddingLeft: "100px" }}>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Cargar archivo</div>
                                </div>
                            </Upload>
                        </div>

                    </Form.Item>
                    <Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ marginRight: 8 }}
                            >
                                Guardar
                            </Button>
                            <Button onClick={handleCancel}>
                                Cancelar
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            ),
        },
        {
            label: '',
            key: '2',
            children: (
                <Form
                    form={form}
                    layout="vertical"
                    style={{ maxWidth: 800 }}
                    initialValues={{ fecha: null, titulo: '', observacion: '' }}
                    onFinish={handleFormSubmit}
                >
                    <Form.Item
                        label="Fecha"
                        name="fecha"
                        rules={[{ required: true, message: 'Por favor, ingrese la fecha' }]}
                    >
                        <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                    </Form.Item>

                    <Form.Item
                        label="Título"
                        name="titulo"
                        rules={[{ required: true, message: 'Por favor, ingrese el título' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Observación"
                        name="observacion"
                    >
                        <TextArea rows={2} />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ maxHeight: '200px', maxWidth: '500px', overflowY: 'auto' }}>
                            <Upload
                                directory
                                multiple
                                fileList={fileList}
                                onChange={handleFolderChange}

                                onRemove={handleRemove}
                                showUploadList={false} // Oculta la lista de archivos
                                customRequest={customRequest}
                                beforeUpload={beforeUpload}
                            >
                                <Button icon={<PlusOutlined />}>Cargar Carpeta</Button>
                            </Upload>
                        </div>
                        {foldersLoaded && (
                            <div style={{ marginTop: 16, color: "#00A67D" }}>
                                <strong>Las carpetas han sido cargadas y están listas para ser guardadas.</strong>
                            </div>
                        )}
                    </Form.Item>

                    <Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ marginRight: 8 }}
                            >
                                Guardar
                            </Button>
                            <Button onClick={handleCancel}>
                                Cancelar
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            ),
        },
    ];

    const tabItems2 = [
        {
            label: '',
            key: '1',
            children: (
                <CSSTransition in={activeKey === "1"} timeout={300} classNames="fade" unmountOnExit>
                    <div style={{ flex: 1, overflowY: 'auto' }}>
                        <DirectoryTree multiple defaultExpandAll expandedKeys={expandedKeys}
                            onExpand={setExpandedKeys} treeData={treeDataWithButtons} />
                    </div>
                </CSSTransition>
            )
        },
        {
            label: '',
            key: '2',
            children: (
                <CSSTransition in={activeKey === "2"} timeout={300} classNames="fade" unmountOnExit>
                    <div>
                        <DirectoryTree multiple defaultExpandAll expandedKeys={expandedKeys}
                            onExpand={setExpandedKeys} treeData={treeDataWithButtons2} />
                    </div>
                </CSSTransition>
            )
        }
    ]

    return (
        <div>
            <Modal
                title={<span style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', display: 'block' }}>Árbol De Carpetas</span>}
                onCancel={onClose}
                footer={null}
                open={isVisible}
                width="80%"
                height="400px"
                maxWidth="400px"
                style={{ maxWidth: 1400, height: '80vh' }}

            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                        <Tooltip title="Arbol de carpetas"> <Button type={activeKey === "1" ? "primary" : "default"} onClick={() => handleChangeTab("1")} icon={<FileOutlined icon={faTrash} style={{ fontSize: 20, color: activeKey === "1" ? '#ffffff' : '#1890ff' }} />} >Soportes</Button></Tooltip>
                        <Tooltip title="Historial de carpetas">   <Button type={activeKey === "2" ? "primary" : "default"} onClick={() => handleChangeTab("2")} style={{ marginLeft: 8 }} icon={<HistoryOutlined icon={faTrash} style={{ fontSize: 20, color: activeKey === "2" ? '#ffffff' : '#1890ff' }} />} >Histórico</Button></Tooltip>
                        <Tooltip title="Descargar Actividad"><Button style={{ marginLeft: 8 }} onClick={handleDownloadMV} icon={<DownloadOutlined style={{ marginLeft: 8 , fontSize: 20, color: activeKey === '2' ? '#ffffff' : '#1890ff' }} />}>Descargar</Button></Tooltip>
                        </div>
                    <div style={{ overflow: 'auto', height: '300px' }}>
                        <Tabs activeKey={activeKey} onChange={handleChangeTab} items={tabItems2} />
                    </div>
                </div>
            </Modal>
            <Modal
                title={<span style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', display: 'block' }}>Cargar Datos</span>}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width="80vw" // Ajusta el ancho según sea necesario
                style={{ maxWidth: 500, maxHeight: '70vh' }} // Ajusta el alto según sea necesario
                footer={null} // Quita los botones predeterminados
            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                        <Tooltip title="Subir archivos">
                            <Button
                                type={activeTab === "1" ? "primary" : "default"}
                                onClick={() => handleTabChange("1")}
                                style={{ marginRight: 8 }}
                                icon={<FileOutlined style={{ fontSize: 20, color: activeTab === "1" ? '#ffffff' : '#1890ff' }} />}
                            >
                                Archivos
                            </Button>
                        </Tooltip>
                        {permissionInsert && (
                            <Tooltip title="Subir Carpetas">
                                <Button
                                    type={activeTab === "2" ? "primary" : "default"}
                                    onClick={() => handleTabChange("2")}
                                    icon={<FolderOutlined style={{ fontSize: 20, color: activeTab === "2" ? '#ffffff' : '#1890ff' }} />}
                                >
                                    Carpetas
                                </Button>
                            </Tooltip>
                        )}
                    </div>

                    <Tabs activeKey={activeTab} onChange={handleTabChange} items={tabItems} />
                    {loading && (
                        <div style={{
                            position: 'fixed', /* Cambia a 'fixed' para cubrir toda la pantalla */
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)', /* Fondo blanco opaco */
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000
                        }}>
                            <div style={{ textAlign: 'center' }}>
                                <Spin size="large" />
                                <Progress percent={uploadProgress} style={{ marginTop: 16 }} />
                            </div>
                        </div>
                    )}
                </div>
            </Modal>

        </div>

    );
};

export default PoaTree;
