import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgEndpointUpdate = '/api/updateAcuerdosComerciales';

const api = axios.create({
    baseURL: config.baseUrl,
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const updateAcuerdoComercial = async (data) => {
    try {
        const response = await api.put(`${baseUrl}${orgEndpointUpdate}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
        }
        });
         
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el acuerdo comercial:', error);
        throw error;
    }
};

export default updateAcuerdoComercial;
