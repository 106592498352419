import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const updateEndpoint = '/api/actualizar-ippta';  // Asumimos que este es el endpoint para actualizar

// Servicio para actualizar los datos
export const updateIpptaData = async (oid, data) => {
  try {
    // Enviar los datos al endpoint '/api/ippta/:oid' para actualizar los datos por oid
    const response = await axios.put(`${baseUrl}${updateEndpoint}/${oid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar los datos:', error);
    throw error;
  }
};
