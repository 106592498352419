import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const appsEndpoint = '/api/some-protected-route';
const authEndpoint = '/api/auth/login';
const logoutEndpoint = '/api/auth/logout'; // Asegúrate de tener un endpoint de logout en tu servidor

// Thunk para login
export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            const userAgent = navigator.userAgent;

            const response = await axios.post(`${baseUrl}${authEndpoint}`, {
                login: username,
                password,
                user_agent: userAgent
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const token = response.data.token;

            // Recuperar aplicaciones usando el token
            const appsResponse = await axios.get(`${baseUrl}${appsEndpoint}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true
            });

            if (appsResponse.data.message === 'No apps') {
                return { token, applications: [] };
            }

            return { token, applications: appsResponse.data.applications, username };

        } catch (error) {
            console.error('Error during login process:', error);

            if (error.response) {
                if (error.response.status === 403 && error.response.data.message === 'No apps') {
                    return rejectWithValue('No tiene aplicaciones asignadas');
                }
                return rejectWithValue(error.response.data.message);
            }

            return rejectWithValue('Login failed or applications fetch failed');
        }
    }
);

// Thunk para logout
export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            // Puedes hacer una solicitud al servidor para invalidar el token si es necesario
            await axios.post(`${baseUrl}${logoutEndpoint}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    // Incluye el token actual en el header si tu servidor lo requiere para invalidar la sesión
                }
            });
        } catch (error) {
            console.error('Error during logout process:', error);
            return rejectWithValue('Logout failed');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        isAuthenticated: false,
        useroid: null,
        user: null,
        applications: [],
        error: null
    },
    reducers: {
        // Si no necesitas una lógica específica de logout, puedes eliminar esta acción.
        logout: (state) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.useroid  = null;
            state.user  = null;
            state.applications = [];
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.user = {
                    token: action.payload.token,
                    username: action.payload.username
                }; 
                state.applications = action.payload.applications;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.error = action.payload;
            })
            .addCase(logout.pending, (state) => {
                state.loading = true;
            })
            .addCase(logout.fulfilled, (state) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.useroid = null;
                state.user = null;
                state.applications = [];
                state.error = null;
            })
            .addCase(logout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { logout: clearLogout } = authSlice.actions;
export default authSlice.reducer;
