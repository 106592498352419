// Importaciones
import React, { useEffect, useState } from 'react';
import { Button, Table, Alert, Spin, Input, Select } from 'antd';
import { DownloadOutlined, ClearOutlined, PlusOutlined, InboxOutlined, EditOutlined } from '@ant-design/icons';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import Edahform from './Edahform';
import './Edah.css'; // Importa tu archivo CSS

//Servicios
import getEdah from '../../services/edah/getEdahService';
import departmentListForm160 from './data';

const { Option } = Select;


const Edah = () => {
    const [dataSource, setDataSource] = useState([]);
    const [dataEncuestaEdit, setDataEncuestaEdit] = useState([]);
    const [filteredList, setFilteredList] = useState([]); // Lista filtrada



    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [status, setStatus] = useState(null);

    const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
    const [sortOrder, setSortOrder] = useState({});
    const [sortField, setSortField] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});

    const departments = departmentListForm160();
    const [numeroIdentificacion, setNumeroIdentificacion] = useState('');
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState('');



    const appName = 'encuesta_EDAH';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export === 'Y';

    const fetchData = async () => {
        setLoading(true);
        try {
            const { status, data } = await getEdah();
            setStatus(status)
            setDataSource(data)
            setFilteredList(data)
        } catch (error) {
            setStatus(500);
            setDataSource([]);
            setFilteredList([])

        } finally {
            setLoading(false);
        }
    };

    const filterData = () => {
        let filtered = dataSource;

        if (numeroIdentificacion) {
            filtered = filtered.filter(item =>
                item.detalle.identification.includes(numeroIdentificacion)
            );
        }

        if (departamentoSeleccionado) {
            filtered = filtered.filter(item =>
                item.detalle.department === departamentoSeleccionado
            );
        }

        setFilteredList(filtered);
    };

    const handleEdit = async (record) => {

        setDataEncuestaEdit(record.detalle)
        showModalEditar()


    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        filterData();
    }, [numeroIdentificacion, departamentoSeleccionado]);

    //Configuración d elas columnas d ela tabla
    const noDataConfig = {
        emptyText: (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InboxOutlined style={{ fontSize: '2rem' }} />
                <span>Sin resultados</span>
            </span>
        ),
    };

    const showModalCrear = () => {
        setIsModalVisible(true);
        setDataEncuestaEdit([])
    };


    const showModalEditar = () => {
        setIsModalVisible(true);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setSortOrder(sorter.order);
        setSortField(sorter.field);
        setFilteredInfo(filters);
    };
    //Funcion para mensajesdependiendo el estado de la solicitud al backend
    const renderErrorMessage = (status) => {
        if (status === 404) {
            return (
                <Alert type="warning" message="Advertencia" description="Lo sentimos, no se encuentran encustas disponibles para mostrar." showIcon icon={<FontAwesomeIcon icon={faExclamationTriangle} />} />
            );
        } else if (status === 500) {
            return (
                <Alert type="error" message="Error" description="Lo sentimos, ha ocurrido un error al obtener las encuestas. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co" showIcon icon={<FontAwesomeIcon icon={faExclamationCircle} />} />
            );
        }
    };
    const exportToExcel = () => {

    };

    //Función para limpiar filtro de busqueda
    const clearAllFilters = async () => {
        setFilteredInfo({});
        fetchData()
    };


    const columns = [
        {
            title: "Acciones",
            dataIndex: "actions",
            key: "actions",
            width: 100,
            filteredValue: null,
            render: (text, record) => (
                <div>

                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        size="small"
                        onClick={() => handleEdit(record)}
                        style={{ marginRight: 8 }} // Añadir margen entre botones
                    />
                </div>
            ),
        },
        {
            title: '#',
            dataIndex: 'Num',
            key: 'index',
            render: (text, record, index) => index + 1 // Enumeración
        },
        {
            title: 'Identificación',
            dataIndex: ['detalle', 'identification'],
            key: 'identification',
        },
        {
            title: 'Nombre encuestado',
            dataIndex: ['detalle', 'encuestadoNombre'],
            key: 'encuestadoNombre',
        },

        {
            title: 'Departamento',
            dataIndex: ['detalle', 'department'],
            key: 'department',
        },

        {
            title: 'Municipio',
            dataIndex: ['detalle', 'city'],
            key: 'city',
        },

        {
            title: 'Lugar',
            dataIndex: ['detalle', 'place'],
            key: 'place',
        },


    ];

    if (loading) {
        return <div className="text-center"><Spin size="large" /></div>;
      }
    return (
        <div className="container">
            <React.Fragment>
                {status === 404 || status === 500 ? (
                    renderErrorMessage(status)
                ) : (
                    <React.Fragment>
                        <div className="row mb-2 d-flex align-items-center">
                            <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                                <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                                    <p>PROYECTO UTF <br /> COL 160 COL</p>
                                </div>
                                <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                                    <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                <h2 className="text-center mb-2">Listado de encuestas EDAH</h2>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="input-group shadow-sm">
                                            {permissionInsert && (
                                                <React.Fragment>
                                                    <Button
                                                        type="danger"
                                                        className="btn btn-primary me-2"
                                                        size="large"
                                                        icon={<PlusOutlined />}
                                                        onClick={showModalCrear} // Abre el modal
                                                    >
                                                        Nueva
                                                    </Button>

                                                </React.Fragment>
                                            )}

                                            {permissionExport && (
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary me-2"
                                                    size="large"
                                                    onClick={exportToExcel}
                                                    icon={<DownloadOutlined />}
                                                >
                                                    Excel
                                                </Button>
                                            )}



                                            <Button
                                                type="danger"
                                                className="btn btn-primary"
                                                size="large"
                                                onClick={clearAllFilters}
                                                icon={<ClearOutlined />}
                                            >
                                                Limpiar
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row mb-4">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                      
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="form-group">
                                    <label htmlFor="filterInput">Número de Identificación:</label>
                                    <Input
                                        type="number"
                                        onChange={(e) => setNumeroIdentificacion(e.target.value)} // Actualiza el estado
                                        id="filterInput" // Asegúrate de tener un id para la accesibilidad
                                        placeholder="Ingrese un número" // Placeholder para mejor experiencia de usuario
                                        style={{ width: '100%' }} // Ajusta el ancho al 100% del contenedor
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-group">
                                    <label htmlFor="departmentSelect">Departamento:</label>
                                    <Select
                                        showSearch
                                        placeholder="Selecciona un departamento"
                                        optionFilterProp="children"
                                        onChange={(value) => setDepartamentoSeleccionado(value)} // Actualiza el estado
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        className="select-department"
                                        style={{ width: '100%' }} // Ajusta el ancho al 100% del contenedor
                                    >
                                        {departments.map((department) => (
                                            <Option key={department.name} value={department.name}>
                                                {department.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                {filteredList.length > 0 ? (
                    <Table
                        columns={columns}
                        dataSource={filteredList}
                        rowKey='oid'
                        bordered
                        pagination={pagination}
                        onChange={handleTableChange}
                        sortDirections={['ascend', 'descend']}
                        sortOrder={sortOrder}
                        onSorterChange={setSortOrder}
                        sortField={sortField}
                        locale={noDataConfig}
                        loading={loading}
                        filteredInfo={filteredInfo}
                        scroll={{ y: 500, x: 'max-content' }}
                    />
                ) : (
                    <Alert
                        message="Sin resultados"
                        description="No hay encuestas para mostrar."
                        type="info"
                        showIcon
                        className="mt-4"
                    />
                )}
            </div>
        </div>


                        <Edahform
                            visible={isModalVisible}
                            onClose={handleCloseModal}
                            data={dataEncuestaEdit}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>

        </div>
    );
};

export default Edah;
