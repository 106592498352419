import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/inf_visto_bueno';

// Servicio para obtener todos los datos (GET)
export const getInfVistoBueno = async () => {
    try {
        const response = await axios.get(`${baseUrl}${endpoint}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching inf_visto_bueno data:', error);
        throw error;
    }
};

// Servicio para insertar un nuevo registro (POST)
export const createInfVistoBueno = async (newData) => {
    try {
      const response = await axios.post(`${baseUrl}${endpoint}`, newData);
      return response.data;
    } catch (error) {
      console.error('Error creating inf_visto_bueno data:', error); // Agrega un log para el error
      throw error;
    }
};
  
// Servicio para actualizar un registro existente (PUT)
export const updateInfVistoBueno = async (oid, updatedData) => {
    try {
      const response = await axios.put(`${baseUrl}${endpoint}/${oid}`, updatedData); // oid es el parámetro de ruta
      return response.data;
    } catch (error) {
      console.error(`Error updating inf_visto_bueno with oid ${oid}:`, error);
      throw error;
    }
};
  
// Servicio para obtener datos por OID (GET by oid)
export const getInfVistoBuenoByOid = async (oid) => {
    try {
      const response = await axios.get(`${baseUrl}${endpoint}/${oid}`);
      return response.data;
    } catch (error) {
      // Maneja el error de manera específica si el código de estado es 404
      // Deja la excepción para otros errores que realmente necesiten manejo
      console.error(`Error fetching inf_visto_bueno with oid ${oid}:`, error);
      throw error; // Lanza el error para que el frontend lo pueda capturar
    }
};
  
// Nuevo servicio para obtener el nombre del usuario (GET by login)
export const getUserNameByLogin = async (login) => {
    try {
        const response = await axios.get(`${baseUrl}/api/user-name/${login}`);
        return response.data.name;
    } catch (error) {
        console.error('Error fetching user name:', error);
        throw error; // Lanza el error para que el frontend lo pueda capturar
    }
};
