import React, { useState, useEffect } from 'react';
import { Button, Drawer, message, Switch } from 'antd';
import { createInfVistoBueno, updateInfVistoBueno, getInfVistoBuenoByOid, getUserNameByLogin } from '../../services/inf_insumos/infVistoBuenoService';
import { SaveOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';


import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';

const ReportDrawerVB = ({ open, onClose, reportData }) => {
  const [checkbox, setCheckbox] = useState(2);
  const [infInsumos, setInfInsumos] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [tituloInforme, setTituloInforme] = useState('');
  const [oidVB, setOidVB] = useState('');
  const [userNameC, setUserNameC] = useState('');
  const appName = 'reporteVB';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  //const permissionAccess = permissions.priv_access  === 'Y';
  //const permissionDelete = permissions.priv_delete === 'Y';
  //const permissionExport = permissions.priv_export  === 'Y';
  const permissionInsert = permissions.priv_insert === 'Y';
  //const permissionPrint = permissions.priv_print  === 'Y';
  const permissionUpdate = permissions.priv_update === 'Y';


  const usernameFromState = useSelector((state) => state.auth.user);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // Fetch user name if the username is available
    if (usernameFromState.username) {
      getUserNameByLogin(usernameFromState.username)
        .then(name => setUserName(name))
        .catch(error => {
          console.error('Error fetching user name:', error);
        });
    }
  }, [usernameFromState.username]);


  useEffect(() => {
    if (reportData && reportData.oid) {
      getInfVistoBuenoByOid(reportData.oid)
        .then(data => {
          if (data.status === 200) {
            if (!data.datos) {
              // Maneja la ausencia de registros
              setCheckbox(2);
              setInfInsumos(reportData.oid || '');
              setTituloInforme(reportData.nombre_modulo || '');
              setIsEditing(false);
              setUserNameC('');
            } else {
              // Maneja el caso en que se encontraron registros
              setOidVB(data.datos.oid);
              setCheckbox(data.datos.checkbox);
              setInfInsumos(reportData.oid || '');
              setTituloInforme(reportData.nombre_modulo || '');
              setIsEditing(true);
              setUserNameC(data.datos.user || '');
            }
          }
        })
        .catch(error => {
          // Solo muestra un mensaje de error si hay un error de red o de servidor que no sea el manejo de datos
          message.error('Error fetching data');
        });
    } else {
      setCheckbox(false);
      setInfInsumos(reportData.oid || '');
      setTituloInforme(reportData.nombre_modulo || '');
      setIsEditing(false);
    }
  }, [reportData, open]);

  const handleAdd = async () => {
    const newData = {
      checkbox: checkbox ? 1 : 2,
      inf_insumos: infInsumos,
      user: usernameFromState.username  // Agrega el campo `user` aquí
    };
    try {
      await createInfVistoBueno(newData);
      message.success('Registro agregado exitosamente');
      onClose();
    } catch (error) {
      message.error('Error al agregar el registro');
    }
  };

  const handleUpdate = async () => {
    const updatedData = {
      oidVB,
      checkbox,
      inf_insumos: infInsumos,
      user: usernameFromState.username  // Agrega el campo `user` aquí
    };

    try {
      await updateInfVistoBueno(reportData.oid, updatedData);
      message.success('Registro actualizado exitosamente');
      onClose();
    } catch (error) {
      message.error('Error al actualizar el registro');
    }
  };


  const getSwitchColor = () => {
    if (checkbox === 1) {
      return 'green';
    }
    return 'red';
  };
  const tituloEnMayusculas = tituloInforme.toUpperCase();

  return (
    <Drawer
      width={500}
      title={`Validado por ART: ${tituloEnMayusculas}`}
      onClose={onClose}
      open={open}
    >
      <h6>Confirmado por: {userNameC || 'Sin confirmar'}</h6>
      <h6>Aprobación del supervisor técnico del convenio:</h6>
      <Switch
        checked={checkbox === 1} // Mapea 1 a true
        onChange={checked => setCheckbox(checked ? 1 : 2)}
        checkedChildren="Validado"
        unCheckedChildren="Faltan soportes"
        style={{
          backgroundColor: getSwitchColor(),
          color: 'white',
          padding: '10px 20px',
          borderColor: getSwitchColor(),
        }}
      />
      <div style={{ marginTop: 24 }}>
        {!isEditing ? (
          // Mostrar el botón de "Registrar" solo si el usuario tiene permiso de inserción
          permissionInsert && [
            <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
              Registrar
            </Button>
          ]
        ) : (
          // Mostrar el botón de "Actualizar" solo si el usuario tiene permiso de actualización
          permissionUpdate && [
            <Button type="primary" onClick={handleUpdate} style={{ marginRight: '8px' }} icon={<SaveOutlined />}>
              Actualizar
            </Button>
          ]
        )}
        <Button style={{ marginLeft: 8 }} danger onClick={onClose} icon={<CloseOutlined />}>
          Cancelar
        </Button>
      </div>
    </Drawer>
  );
};

export default ReportDrawerVB;
