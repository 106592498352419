import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgEndpoint = '/api/getParticipant';


const api = axios.create({
    baseURL: config.baseUrl,
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const getParticipantes = async () => {
    try {
        const response = await api.get(`${baseUrl}${orgEndpoint}`);
        return {
            status: response.status,
            data: response.data.Participantes
        };
    } catch (error) {
        console.error('Error fetching participants:', error);
        return {
            status: error.response ? error.response.status : 500,
            message: 'Error fetching participants'
        };
    }
};

export default getParticipantes;
