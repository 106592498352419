import React, { useEffect, useState } from 'react';
import { UploadOutlined, DownloadOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Upload, message } from 'antd';
import { guardarSoporteMER } from '../../services/services_mer/post_support_mer';
import { getSoportesMER } from '../../services/services_mer/get_support_mer';
import { deleteSoporteMER } from '../../services/services_mer/delete_support_mer';
import { downloadSupportMER } from '../../services/services_mer/download_support_mer';
import { viewSupportMER } from '../../services/services_mer/view_file_support_mer';
import './UploadComponent.css';

import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';

const UploadImage = ({ orgId, supportId, initialOrg, onUploadSuccess }) => {
  const [soportes, setSoportes] = useState([]);
  const [loading, setLoading] = useState(false);

  //Control de Permisos
  const appName = 'mer'; // El nombre de la aplicación actual
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionDelete = permissions.priv_delete === 'Y';
  const permissionUpdate = permissions.priv_update === 'Y';
  const permissionInsert = permissions.priv_insert === 'Y';

  // Función para obtener los soportes MER
  const fetchSoportes = async () => {
    try {
      const { status, data } = await getSoportesMER(orgId, supportId);
      if (status === 200) {
        setSoportes(data.data);
      } else {
        message.error('Error al obtener los soportes MER');
      }
    } catch (error) {
      message.error('Error al obtener los soportes MER');
    } finally {
      setLoading(false);
    }
  };

  // Cargar los soportes al montar el componente
  useEffect(() => {
    fetchSoportes();
  }, [orgId, supportId, initialOrg]);

  // Función para subir un archivo
  const customRequest = async ({ file, onError, onSuccess }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('nombre_soporte', file.name);
      formData.append('ruta_archivo', initialOrg);
      formData.append('tipo_archivo', file.type);
      formData.append('fecha_creacion', new Date().toISOString());
      formData.append('usuario_subida', '1');
      formData.append('org_organizacion', orgId);
      formData.append('org_soporte_tipo', supportId);
      const { status, data } = await guardarSoporteMER(formData);

      if (status === 201) {
        message.success('Archivo subido correctamente');
        onSuccess(data, file);
        fetchSoportes();
        onUploadSuccess();
      } else {
        message.error('Error al subir archivo');
        onError();
      }
    } catch (error) {
      console.error('Error al subir archivo:', error);
      message.error('Error al subir archivo');
      onError();
    }
  };

  // Función para eliminar un soporte
  const handleRemove = async (file) => {
    try {
      const soporteId = file.uid;
      const { status } = await deleteSoporteMER(soporteId);
      if (status === 200) {
        message.success('Archivo eliminado correctamente');
        fetchSoportes();
        onUploadSuccess();
      } else {
        message.error('Error al eliminar el archivo');
      }
    } catch (error) {
      console.error('Error al eliminar el archivo:', error);
      message.error('Error al eliminar el archivo');
    }
  };

  // Función para construir la URL de descarga del soporte
  const getDownloadUrl = (soporte) => {
    return ".xlsx";
  };

  // Función para descargar un archivo
  const handleDownload = async (file) => {
    try {
      const soporteId = file.uid;
      const nombreSoporte = file.name;
      const { status } = await downloadSupportMER(soporteId, nombreSoporte);
      if (status === 200) {
        message.success('Archivo descargado correctamente');
      } else {
        message.error('Error al descargar el archivo');
      }
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      message.error('Error al descargar el archivo');
    }
  };

  // Función para visualizar un archivo
  const handlePreview = async (file) => {
    try {
      const extension = file.name.split('.').pop().toLowerCase();
      const allowedTypes = ['png', 'jpeg', 'jpg', 'pdf']; // Extensiones permitidas
      const soporteId = file.uid;
      if (allowedTypes.includes(extension)) {
        const { status } = await viewSupportMER(soporteId);
        status === 200 ? message.success('Archivo abierto correctamente') : message.error('Error al abrir el archivo');
      } else {
        message.info('No se puede visualizar el archivo, se descargará automáticamente.');
        handleDownload(file);
      }
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
      message.error('Error al abrir el archivo');
    }
  };



  // Propiedades para el componente Upload de Ant Design
  const uploadProps = {
    customRequest,
    showUploadList: false,
  };
  const customUploadButton = (
    <Button icon={<UploadOutlined />} style={{ width: '100%', height: '100%' }}>
      <div style={{ marginTop: 8 }}>Cargar Archivo</div>
    </Button>
  );


  return (
    <div>
      {permissionInsert && (
        <div style={{
          border: '2px dashed #d9d9d9',
          padding: 8,
          borderRadius: '10px',
          width: '96%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px', // Ajusta la altura según sea necesario
          textAlign: 'center'
        }}>


          <Upload listType="picture-card" {...uploadProps}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <UploadOutlined style={{ fontSize: '24px' }} />
              <div style={{ marginTop: 8 }}>Cargar Archivo</div>
            </div>
          </Upload>


        </div>
      )}

      <div style={{ marginTop: 8 }}>

        <Upload
          listType="picture"
          fileList={soportes.map((soporte) => ({
            uid: soporte.oid.toString(),
            name: soporte.nombre_soporte,
            status: 'done',
            url: getDownloadUrl(soporte),
          }))}
          onRemove={handleRemove}
          onPreview={handlePreview}
          showUploadList={{
            showPreviewIcon: true,
            previewIcon: <EyeOutlined style={{ fontSize: '20px', color: '#1890ff' }} />,
            showDownloadIcon: true,
            downloadIcon: <DownloadOutlined style={{ fontSize: '20px', color: '#1890ff' }} />,
            showRemoveIcon: permissionDelete ? true : false,
            removeIcon: <DeleteOutlined style={{ fontSize: '20px', color: 'red' }} />,
          }}
          onDownload={handleDownload} />
      </div>

    </div>


  );

};

export default UploadImage;
