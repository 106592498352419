import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Input, Form, Progress, Modal, Space, Table, Alert, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import getOrganizacionesMER from '../../services/merServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFile, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import 'antd/dist/reset.css';
import { SearchOutlined, InboxOutlined, ClearOutlined, LeftOutlined } from '@ant-design/icons';
import './Organizaciones.css';
import * as XLSX from 'xlsx';
import { useLocation, Link } from 'react-router-dom';
import UploadImage from '../SoportesMer/UploadImage';

import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';

const MerOrganizaciones = () => {
    const [organizaciones, setOrganizaciones] = useState([]);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
    const [sortOrder, setSortOrder] = useState({});
    const [sortField, setSortField] = useState('');
    const [departamentosFilters, setDepartamentosFilters] = useState([]);
    const [, setTiposOrganizacionFilters] = useState([]);
    const [, setOrganizacionMujeresFilters] = useState([]);
    const [, setOrganizacionNitFilters] = useState([]);
    const [, setProyectosFilters] = useState([]);
    const [, setOrganizacionesNameFilters] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // Agrega este estado
    const [form] = Form.useForm();
    const [, setCurrentOrg] = useState({});
    const [viewMode,] = useState('table'); // Estado para manejar el modo de vista
    const [treeData, setTreeData] = useState([]);
    const location = useLocation();
    const { oid_proyecto_busqueda } = location.state || {};

    //Modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalOrgId, setOrgId] = useState("");
    const [modalSupportId, setSupportId] = useState("");
    const [modalInitialsOrg, setInitialsOrg] = useState("");

    //Funcion para obtener el primer municipio
    function getFirstMunicipio(municipios) {
        if (typeof municipios !== 'string') {
            return ''; // o manejar el caso cuando no es una cadena
        }

        const firstCommaIndex = municipios.indexOf(',');
        if (firstCommaIndex === -1) {
            return municipios; // No hay comas, devolver la cadena completa
        }
        return municipios.substring(0, firstCommaIndex).trim();
    }

    //Funcion para reemplazar espacios por guiones bajos
    function replaceSpacesWithUnderscores(text) {
        if (typeof text !== 'string') {
            return '';
        }
        return text.replace(/ /g, '_');
    }

    //Funcion para mostrar modal
    const showModal = (title, orgId, supportId, initialOrg) => {
       
        setModalTitle(title); // Titulo del Modal
        setOrgId(orgId); // ID de la organizacion
        setSupportId(supportId); // Tipo de soporte 1: Mer 2:Organizacion 3: Plan de Accion
        setInitialsOrg(initialOrg); // Inicial de la organizacion + Ruta
        setIsModalVisible(true); // Muestra el modal
    };

    //Funcion para obtener los tipos de organizacion
    const fetchOrganizaciones = async () => {
        try {
            const { status, data } = await getOrganizacionesMER();

            setStatus(status);

            if (status === 200) {
                let filteredData = data;

                // Verificar si oid_proyecto_busqueda está presente y filtrar datos
                if (oid_proyecto_busqueda) {
                    filteredData = data.filter(org => org.oid_proyecto === parseInt(oid_proyecto_busqueda));
                }
                setOrganizaciones(filteredData || []);
                // Obtener departamentos únicos para filtros
                const uniqueDepartamentos = [...new Set(filteredData.map(org => org.departamentos))];
                const filtersDepartamentos = uniqueDepartamentos.map(dep => ({ text: dep, value: dep }));
                setDepartamentosFilters(filtersDepartamentos);

                // Obtener tipos de organización únicos para filtros
                const uniqueTiposOrganizacion = [...new Set(filteredData.map(org => org.tipo_organizacion))];
                const filtersTiposOrganizacion = uniqueTiposOrganizacion.map(tipo => ({ text: tipo, value: tipo }));
                setTiposOrganizacionFilters(filtersTiposOrganizacion);

                // Obtener organizaciones de mujeres únicas para filtros
                const uniqueMujeresOrganizacion = [...new Set(filteredData.map(org => org.org_mujeres))];
                const filtersOrganizacionMujeres = uniqueMujeresOrganizacion.map(tipo => ({ text: tipo, value: tipo }));
                setOrganizacionMujeresFilters(filtersOrganizacionMujeres);

                // Obtener proyectos únicos para filtros
                const uniqueProyectos = [...new Set(filteredData.map(org => org.proyecto_detalle))];
                const filtersProyectos = uniqueProyectos.map(tipo => ({ text: tipo, value: tipo }));
                setProyectosFilters(filtersProyectos);

                // Obtener NIT único para filtros
                const uniqueNit = [...new Set(filteredData.map(org => org.Nit))];
                const filtersNit = uniqueNit.map(tipo => ({ text: tipo, value: tipo }));
                setOrganizacionNitFilters(filtersNit);

                // Obtener nombres de organizaciones únicos para filtros
                const uniqueName = [...new Set(filteredData.map(org => org.organizacion))];
                const filtersName = uniqueName.map(tipo => ({ text: tipo, value: tipo }));
                setOrganizacionesNameFilters(filtersName);
            } else {
                setOrganizaciones([]);
            }
        } catch (error) {
            console.error('Error fetching organizations:', error);
            setStatus(500);
            setOrganizaciones([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrganizaciones();
    }, [oid_proyecto_busqueda]);

    //Funcion para obtener los tipos de organizacion
    const renderErrorMessage = (status) => {

        if (status === 404) {
            return (
                <Alert type="warning" message="Advertencia" description="Lo sentimos, no se encuentran organizaciones disponibles para mostrar." showIcon icon={<FontAwesomeIcon icon={faExclamationTriangle} />} />
            );
        } else if (status === 500) {
            return (
                <Alert type="error" message="Error" description="Lo sentimos, ha ocurrido un error al obtener las organizaciones. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co" showIcon icon={<FontAwesomeIcon icon={faExclamationCircle} />} />
            );
        }
    };


    const handleSearchInputChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        if (viewMode === 'tree') {
            //const treeData = transformToTreeData(organizaciones, searchTerm);
            setTreeData(treeData);
        } else {
            const filteredOrganizaciones = organizaciones.filter(org =>
                org.organizacion.toLowerCase().includes(searchTerm)
            );
            setOrganizaciones(filteredOrganizaciones);
        }
    };

    const handleSearchClick = () => {
        const filteredOrganizaciones = organizaciones.filter(org =>
            org.organizacion.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setOrganizaciones(filteredOrganizaciones); // Actualiza el estado con las organizaciones filtradas
    };

    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'MER_Organizaciones_UTF_COL_160' + fileExtension;

        // Mapear y seleccionar solo las columnas que quieres exportar
        const dataToExport = organizaciones.map(org => ({
            OID: org.oid,
            Nit: org.Nit,
            Organización: org.organizacion,
            Sigla: org.sigla,
            'Tipo de Organización': org.tipo_organizacion,
            'Número de Hombres': org.n_hombres,
            'Número de Mujeres': org.n_mujeres,
            'Total Participantes': org.total_participantes,
            'Organización de Mujeres': org.org_mujeres,
            'Proyecto Detalle': org.proyecto_detalle,
            Municipios: org.municipios,
            Departamentos: org.departamentos,
            '¿Se ha aplicado MER?': org.medicion,
            '% MER': org.porcentaje,


        }));

        // Convertir los datos seleccionados a formato Excel
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { 'organizaciones': ws }, SheetNames: ['organizaciones'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Crear Blob y descargar
        const blob = new Blob([excelBuffer], { type: fileType });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
    };
    const clearAllFilters = async () => {
        setFilteredInfo({});
        setSearchTerm('');

        try {
            const { status, data } = await getOrganizacionesMER();
            if (status === 200) {
                if (oid_proyecto_busqueda) {
                    const filteredData = data.filter(org => org.oid_proyecto === parseInt(oid_proyecto_busqueda));
                    setOrganizaciones(filteredData || []);
                } else {
                    setOrganizaciones(data || []);
                }
            } else {
                setOrganizaciones([]);
            }
        } catch (error) {
            console.error('Error fetching organizations:', error);
            setOrganizaciones([]);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setFilteredInfo({ ...filteredInfo, [dataIndex]: selectedKeys });
    };

    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        setFilteredInfo({ ...filteredInfo, [dataIndex]: null });
        confirm();
    };
    const getColumnSearchProps = (dataIndex) => {
        let searchInput = React.createRef();

        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        id={`${dataIndex}-search-input`}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys ? selectedKeys[0] : ''}

                        ref={node => {
                            searchInput = node;
                        }}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Buscar
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Resetear
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) =>
                record[dataIndex]
                    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
            onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => {
                        searchInput?.select();
                    }, 100);
                }
            },
        };
    };

    const renderBotonesSoporte = (record) => {
        const tieneSoporteMer = record.mer > 0; // Verifica si tiene soporte MER
        const tieneSoporteRH = record.rh > 0;
        const tieneSoportePA = record.pa > 0;

        const departamento = replaceSpacesWithUnderscores(getFirstMunicipio(record?.departamentos));
        const municipio = replaceSpacesWithUnderscores(getFirstMunicipio(record?.municipios));
        const organizacionID = record?.oid;
        const ruta = departamento && municipio && organizacionID ? `${departamento}/${municipio}/Cod_${organizacionID}/` : '';


        return (
            <Space size="middle">
                <Tooltip placement="topLeft" title={"MER"}>
                    <Button
                        onClick={() => showModal("MER", record.oid, 1, `${ruta}Mer1/`)}
                        icon={<FontAwesomeIcon icon={faFile} />}
                        style={{ color: tieneSoporteMer ? 'green' : 'red' }}
                    />
                </Tooltip>
                <Tooltip placement="topLeft" title={"Reporte Homologación"}>
                    <Button
                        onClick={() => showModal("Reporte Homologación", record.oid, 2, `${ruta}Reporte_Homologacion/`)}
                        icon={<FontAwesomeIcon icon={faFile} />}
                        style={{ color: tieneSoporteRH ? 'green' : 'red' }}
                    />
                </Tooltip>
                <Tooltip placement="topLeft" title={"Plan de Acción"}>
                    <Button
                        onClick={() => showModal("Plan de Acción", record.oid, 3, `${ruta}Plan_de_Accion/`)}
                        icon={<FontAwesomeIcon icon={faFile} />}
                        style={{ color: tieneSoportePA ? 'green' : 'red' }}
                    />
                </Tooltip>
            </Space>
        );
    };


    const columns = [
        {
            title: 'ID', dataIndex: 'oid', key: 'oid', width: 50,
            align: 'center', fixed: 'left',
        },
        {
            title: 'Departamentos',
            dataIndex: 'departamentos',
            key: 'departamentos',
            sorter: {
                compare: (a, b) => a.departamentos.localeCompare(b.departamentos),
                multiple: 3,
            },
            filterSearch: true,
            fixed: 'left',
            filters: departamentosFilters,
            filteredValue: filteredInfo.departamentos || null,
            onFilter: (value, record) => record.departamentos.startsWith(value),
            width: 180,
        },
        { title: 'Municipios', dataIndex: 'municipios', key: 'municipios', width: 150 },
        {
            title: 'NIT',
            dataIndex: 'Nit',
            key: 'Nit',
            ...getColumnSearchProps('Nit'),
            width: 150,
            filterSearch: true,
            filteredValue: filteredInfo.Nit || null,
        },
        {
            title: 'Nombre de Organización',
            dataIndex: 'organizacion',
            key: 'organizacion',
            fixed: 'left',
            sorter: {
                compare: (a, b) => a.organizacion.localeCompare(b.organizacion),
                multiple: 3,
            },
            width: 320,
            filteredValue: filteredInfo.organizacion || null,
            ...getColumnSearchProps('organizacion'),
        },
        { title: 'Sigla', dataIndex: 'sigla', key: 'sigla', width: 220 },
        { title: '¿Se ha aplicado MER inicial?', dataIndex: 'medicion', key: 'medicion', width: 220, align: 'center', },
        {
            title: '% MER Inicial',
            dataIndex: 'porcentaje',
            key: 'porcentaje',
            width: 220,
            align: 'center',
            render: (porcentaje) => (
                <Progress percent={porcentaje} status="active" />
            ),

        },
        {
            title: 'Soportes',
            dataIndex: 'soportes',
            align: 'center',
            render: (_, record) => renderBotonesSoporte(record),
        },
    ];



    const handleTableChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setSortOrder(sorter.order);
        setSortField(sorter.field);
        setFilteredInfo(filters);
    };

    const noDataConfig = {
        emptyText: (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InboxOutlined style={{ fontSize: '2rem' }} />
                <span>Sin resultados</span>
            </span>
        ),
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        //setEditMode(false);
        setCurrentOrg(null);
    };




    return (
        <div className="container">
            {loading ? (
                <div className="text-center"><Spin size="large" /></div>
            ) : (


                <React.Fragment>
                    {status === 404 || status === 500 ? (
                        renderErrorMessage(status)
                    ) : (
                        <React.Fragment>

                            <div className="row mb-2 d-flex align-items-center">
                                <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                                    <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                                        <p>PROYECTO UTF <br /> COL 160 COL</p>
                                    </div>
                                    <div className='objeto' style={{ flexBasis: '75%', flexGrow: 0 }}>
                                        <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>

                                    </div>


                                </div>


                                <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                    <h2 className="text-center mb-2">MER Organizaciones</h2>
                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <div className="input-group shadow-sm">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Buscar organizaciones..."
                                                    value={searchTerm} // Vincula el estado de búsqueda al input
                                                    onChange={handleSearchInputChange} // Maneja el cambio en el input
                                                />
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={handleSearchClick} // Maneja el clic en el botón de búsqueda
                                                >
                                                    <SearchOutlined /> {/* Incluye el icono aquí */}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="d-flex justify-content-end mt-2">
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary me-2"
                                                    onClick={exportToExcel}
                                                    size="large"
                                                    icon={<DownloadOutlined />}
                                                >
                                                    Excel
                                                </Button>
                                                <Button
                                                    type="danger"
                                                    className="btn btn-primary"
                                                    onClick={clearAllFilters}
                                                    size="large"
                                                    icon={<ClearOutlined />}
                                                >
                                                    Limpiar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {organizaciones.length > 0 ? (
                                viewMode === 'table' ? (
                                    <Table
                                        columns={columns}
                                        dataSource={organizaciones}
                                        rowKey='oid'
                                        bordered
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                        sortDirections={['ascend', 'descend']}
                                        sortOrder={sortOrder}
                                        onSorterChange={setSortOrder}
                                        sortField={sortField}
                                        locale={noDataConfig}
                                        loading={loading}
                                        filteredInfo={filteredInfo}
                                        scroll={{ y: 500, x: 'max-content' }}
                                    />
                                ) : (
                                    <p className="text-center">No hay organizaciones para mostrar.</p>

                                )
                            ) : (
                                <p className="text-center">No hay organizaciones para mostrar.</p>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}


            <Modal
                title={<h3 style={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '10px' }}>{modalTitle}</h3>}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={400}
            >



                <Form form={form} layout="vertical" name="form_in_modal">

                    <UploadImage orgId={modalOrgId} supportId={modalSupportId} initialOrg={modalInitialsOrg} onUploadSuccess={fetchOrganizaciones} />


                </Form>

            </Modal>
            <div>
                {oid_proyecto_busqueda && (
                    <Link to="/buscar-proyecto">
                        <Button
                            type="danger"
                            className="btn btn-primary me-2"
                            size="large"
                            icon={<LeftOutlined />}
                        >
                            Volver
                        </Button>

                    </Link>
                )}
            </div>
        </div >
    );

};
export default MerOrganizaciones;
