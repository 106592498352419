import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;  // Asegúrate de que esta URL sea correcta
const orgEndpoint = '/api/acuerdo/';

const api = axios.create({
    baseURL: baseUrl,
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const getArchivosAcuerdosComerciales = async (id) => {
    try {
        const response = await api.get(`${orgEndpoint}${id}/archivos`);
        return {
            status: response.status,
            data: response.data  // Ajusta esto según la estructura real de la respuesta
        };
    } catch (error) {
        console.error('Error fetching archivos:', error);
        return {
            status: error.response ? error.response.status : 500,
            message: error.response ? error.response.data.message : 'Error fetching archivos'
        };
    }
};

export default getArchivosAcuerdosComerciales;
