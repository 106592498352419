// ImageComponent.js
import React from 'react';
import imgPorDefecto from '../../assets/img_lineas_productivas/pordefecto.png';

import img1 from '../../assets/img_lineas_productivas/SeguridadAlimentaria1.png'
import img2 from '../../assets/img_lineas_productivas/Café1.png'
import img3 from '../../assets/img_lineas_productivas/Cacao1.png'
import img4 from '../../assets/img_lineas_productivas/Piscicultura1.png'
import img5 from '../../assets/img_lineas_productivas/Cacao2.png'
import img6 from '../../assets/img_lineas_productivas/Piscicultura2.png'
import img7 from '../../assets/img_lineas_productivas/Turismo1.png'
import img8 from '../../assets/img_lineas_productivas/CañaPanelera1.png'
import img9 from '../../assets/img_lineas_productivas/Turismo2.png'
import img10 from '../../assets/img_lineas_productivas/MARACUYA-09.png'
import img11 from '../../assets/img_lineas_productivas/Cacao4.png'
import img12 from '../../assets/img_lineas_productivas/Seguridad-Alimentaria-16.png'
import img13 from '../../assets/img_lineas_productivas/Cacao5.png'
import img14 from '../../assets/img_lineas_productivas/Piscicultura3.png'
import img15 from '../../assets/img_lineas_productivas/CañaPanelera2.png'
import img16 from '../../assets/img_lineas_productivas/Cacao6.png'
import img17 from '../../assets/img_lineas_productivas/Aguacate1.png'
import img18 from '../../assets/img_lineas_productivas/Cacao7.png'
import img19 from '../../assets/img_lineas_productivas/Apicola1.png'
import img20 from '../../assets/img_lineas_productivas/Pesca.png'
import img21 from '../../assets/img_lineas_productivas/Apicola2.png'
import img22 from '../../assets/img_lineas_productivas/Arroz.jpg'
import img23 from '../../assets/img_lineas_productivas/Residuos-solidos-14.png';
import img24 from '../../assets/img_lineas_productivas/Cacao3.png'
import img25 from '../../assets/img_lineas_productivas/Ovino.png'
import img26 from '../../assets/img_lineas_productivas/Aguacate2.png'
import img27 from '../../assets/img_lineas_productivas/Fruticola.png'
import img28 from '../../assets/img_lineas_productivas/Café2.png'
import img29 from '../../assets/img_lineas_productivas/Café3.png'
import img30 from '../../assets/img_lineas_productivas/Café4.png'
import img31 from '../../assets/img_lineas_productivas/CACAO1-05.png'
import img32 from '../../assets/img_lineas_productivas/SeguridadAlimentaria3.png'


const ImageComponent = ({ projectId }) => {
    const imageMapping = {
        1: img1,
        2: img2,
        3: img3,
        4: img4,
        5: img5,
        6: img6,
        7: img7,
        8: img8,
        9: img9,
        10: img10,
        11: img11,
        12: img12,
        13: img13,
        14: img14,
        15: img15,
        16: img16,
        17: img17,
        18: img18,
        19: img19,
        20: img20,
        21: img21,
        22: img22,
        23: img23,
        24: img24,
        25: img25,
        26: img26,
        27: img27,
        28: img28,
        29: img29,
        30: img30,
        31: img31,
        32: img32


    };

    // Obtén la imagen del proyecto o usa la imagen por defecto
    const imageSrc = imageMapping[projectId] || imgPorDefecto;

    return (
        <div style={{
            backgroundImage: `url(${imageSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '225px',
            margin: '-5px',
            marginLeft: '-5px',
            transform: 'scale(1.50)',
        }}>
        </div>
    );
};

export default ImageComponent;
