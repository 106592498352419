import React, { useState, useEffect } from 'react';
import './BuscarProyecto.css';
import getProyectos from '../../services/fetch_projects/proyectosService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMars, faVenus, faSearch, faUsers, faDownload, faTh, faArrowLeft, faList, faBroom } from '@fortawesome/free-solid-svg-icons';
import ActualizarProyecto from '../ActualizarProyecto/ActualizarProyecto';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Dropdown, Space, message, Spin } from 'antd';
import { DownOutlined, FileExcelOutlined } from '@ant-design/icons';
import ImageComponent from '../imageMapping/ImageComponent';
import * as XLSX from 'xlsx';

import getDetalleProyectos from '../../services/detalleProyectos';
import downloadProject from '../../services/file/download_project';

function BuscarProyecto() {
    const [searchTerm, setSearchTerm] = useState('');
    const [projects, setProjects] = useState([]);
    const [originalProjects, setOriginalProjects] = useState([]);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('grid');
    const [mostrarDetalle, setMostrarDetalle] = useState(false);
    const [proyectoSeleccionado, setProyectoSeleccionado] = useState(null);
    const [mostrarProyectosTree, setMostrarProyectosTree] = useState(false);
    const [totalMujeres, setTotalMujeres] = useState(0);
    const [totalHombres, setTotalHombres] = useState(0);
    const [totalParticipantes, setTotalParticipantes] = useState(0);
    const [totalOrganizaciones, setTotalOrganizaciones] = useState(0);
    const [loading, setLoading] = useState(false); // Estado para el indicador de carga


    const navigate = useNavigate();

    const handleVerOrganizaciones = (oid_proyecto_busqueda) => {
        navigate('/organizaciones', { state: { oid_proyecto_busqueda: oid_proyecto_busqueda } });
    };

    const handleClickDirectories = (codigo_proyecto_busqueda, nameproyecto) => {
        navigate('/file-manager', { state: { codigo_proyecto_busqueda, nameproyecto } });
    };


    const handleMostrarProyectosTree = async (project) => {
        setProyectoSeleccionado(project);
        setLoading(true);

        try {
            const response = await downloadProject(project.codigo); // Llama al servicio de descarga

            if (response.success) {
                message.info('Descarga completa de directorio');
                setLoading(false);
            } else {
                message.error('Error al descargar directorio');
            }
        } catch (error) {
            console.error('Error al descargar directorio:', error);
            message.error('Error al descargar directorio');
        }

        setMostrarProyectosTree(false);
        setMostrarDetalle(false);
    };


    const switchToGrid = () => setViewMode('grid');
    const switchToList = () => setViewMode('list');

    const exportToExcelDetalle = async () => {
        try {
            // Llamada al servicio para obtener los detalles del proyecto
            const response = await getDetalleProyectos();

            if (response && response.status === 200) {
                const detalles = response.DetalleProyectos;

                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const fileName = 'Detalle_Proyectos_UTF_COL_160' + fileExtension;

                // Mapear y seleccionar solo las columnas que quieres exportar
                const dataToExport = detalles.map(detalle => ({
                    'Proyecto OID': detalle.proyecto_oid,
                    'Código Proyecto': detalle.codigo_proyecto,
                    'Nombre Proyecto': detalle.nombre_proyecto,
                    'Organización OID': detalle.organizacion_oid,
                    'NIT Organización': detalle.nit_organizacion,
                    'Nombre Organización': detalle.nombre_organizacion,
                    'Sigla Organización': detalle.sigla_organizacion,
                    'Tipo de Organización': detalle.tipo_de_organizacion,
                    'Número de Hombres': detalle.numero_hombres,
                    'Número de Mujeres': detalle.numero_mujeres,
                    'Total de Participantes': detalle.total_participantes,
                    'Organización de Mujeres': detalle.organizacion_mujeres
                }));

                // Convertir los datos seleccionados a formato Excel
                const ws = XLSX.utils.json_to_sheet(dataToExport);
                const wb = { Sheets: { 'detalle_proyectos': ws }, SheetNames: ['detalle_proyectos'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

                // Crear Blob y descargar
                const blob = new Blob([excelBuffer], { type: fileType });
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = fileName;
                downloadLink.click();
            } else {
                console.error("Error: No se pudieron obtener los detalles de los proyectos o respuesta inválida", response);
            }
        } catch (error) {
            console.error("Error al exportar a Excel: ", error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getProyectos();
                setProjects(data);
                setOriginalProjects(data);
                setError(null);

                // Calcular totales
                const totales = calcularTotales(data);
                setTotalMujeres(totales.totalMujeres);
                setTotalHombres(totales.totalHombres);
                setTotalOrganizaciones(totales.totalOrganizaciones);
                setTotalParticipantes(totales.totalParticipantes);

            } catch (error) {
                setError("Sin respuesta del servicio.");
            }
        };

        fetchData();
    }, []);

    const calcularTotales = (projects) => {


        let totalMujeres = 0;
        let totalHombres = 0;
        let totalOrganizaciones = 0;
        let totalParticipantes = 0;


        projects.forEach(project => {
            // Suma los valores de cada proyecto asegurándose de que las propiedades existen
            totalHombres += project.totHombres || 0;
            totalMujeres += project.totMujeres || 0;
            project.totalOrganizaciones = project.Organizaciones ? project.Organizaciones.length : 0;
            totalParticipantes += (project.totHombres || 0) + (project.totMujeres || 0);
        });



        return {
            totalMujeres,
            totalHombres,
            totalOrganizaciones,
            totalParticipantes,
        };
    };



    const handleSearch = (term) => {
        const filteredProjects = originalProjects.filter(project =>
            project.proyecto.toLowerCase().includes(term.toLowerCase()) ||
            project.codigo.toLowerCase().includes(term.toLowerCase())
        );
        setProjects(filteredProjects);
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        handleSearch(e.target.value);
    };

    const handleClear = () => {
        setSearchTerm('');
        handleSearch('');
    };

    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'Actualización_Proyectos_UTF_COL_160' + fileExtension;

        // Mapear y seleccionar solo las columnas que quieres exportar
        const dataToExport = projects.map(project => ({
            '#': projects.indexOf(project) + 1,
            Código: project.codigo,
            Proyecto: project.proyecto,
            'Línea Productiva': project.LineasProductivas && project.LineasProductivas.length > 0 ? project.LineasProductivas.map(linea => linea.lineaProductiva).join(', ') : 'No data',
            Tipo: project.LineasProductivas && project.LineasProductivas.length > 0 ? project.LineasProductivas.map(linea => linea.tipoActividad).join(', ') : 'No data',
            Organizaciones: project.totalOrganizaciones,
            Mujeres: project.totalMujeres,
            Hombres: project.totalHombres,
            'Total Participantes': project.totalParticipantes,
        }));

        // Convertir los datos seleccionados a formato Excel
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { 'proyectos': ws }, SheetNames: ['proyectos'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Crear Blob y descargar
        const blob = new Blob([excelBuffer], { type: fileType });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
    };



    const items = [
        {
            key: '1',
            label: (

                <button
                    className="m-2 me-2 btn-primary-lis"
                    onClick={() => exportToExcel()}
                    style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                >
                    Resumen
                </button>

            ),
        },
        {
            key: '2',
            label: (

                <button
                    className="m-2 me-2 btn-primary-lis"
                    onClick={() => exportToExcelDetalle()}
                    style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                >
                    Detalle
                </button>

            ),
        },
    ];




    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Código',
            dataIndex: 'codigo',
            key: 'codigo',
        },
        {
            title: 'Proyecto',
            dataIndex: 'proyecto',
            key: 'proyecto',
        },
        {
            title: 'Línea Productiva',
            dataIndex: 'LineasProductivas',
            key: 'lineaProductiva',
            render: (LineasProductivas) => (
                LineasProductivas && LineasProductivas.length > 0 ? (
                    LineasProductivas.map((linea, i) => (
                        <div key={i}>{linea.lineaProductiva}</div>
                    ))
                ) : (
                    <span>No data</span>
                )
            ),
        },
        {
            title: 'Tipo',
            dataIndex: 'LineasProductivas',
            key: 'tipoActividad',
            render: (LineasProductivas) => (
                LineasProductivas && LineasProductivas.length > 0 ? (
                    LineasProductivas.map((linea, i) => (
                        <div key={i}>{linea.tipoActividad}</div>
                    ))
                ) : (
                    <span>No data</span>
                )
            ),
        },
        {
            title: 'Organizaciones',
            dataIndex: 'totalOrganizaciones',
            key: 'totalOrganizaciones',
            align: 'center',
        },
        {
            title: 'Mujeres',
            dataIndex: 'totalMujeres',
            key: 'totalMujeres',
            align: 'center',
        },
        {
            title: 'Hombres',
            dataIndex: 'totalHombres',
            key: 'totalHombres',
            align: 'center',
        },
        {
            title: 'Total Participantes',
            dataIndex: 'totalParticipantes',
            key: 'totalParticipantes',
            align: 'center',
        },
        {
            title: 'Opciones',
            key: 'options',
            render: (text, project) => (
                <Space size="middle">
                    <button
                        className="m-2 me-2 btn-primary-lis"
                        onClick={() => handleClickDirectories(project.codigo, project.proyecto)}
                        style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                    >
                        <FontAwesomeIcon icon={faSearch} title="Contenido de actualización" />
                    </button>
                    <span className="separator">|</span>

                    <button
                        className="m-2 me-2 btn-primary-lis"
                        onClick={() => handleVerOrganizaciones(project.oid)}
                        style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                    >
                        <FontAwesomeIcon icon={faUsers} title="Organizaciones del proyecto" />

                    </button>
                    <span className="separator">|</span>
                    <button
                        className="m-2 me-2 btn-primary-lis"
                        onClick={() => handleMostrarProyectosTree(project)}
                        style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                        disabled={loading} // Desactivar el botón durante la descarga
                    >
                        <FontAwesomeIcon icon={faDownload} title="Descargar directorios de actualización" />

                    </button>
                </Space>
            ),
            align: 'end',
        },
    ];


    return (

        <Spin spinning={loading} tip="Descargando directorio..."> {/* Envuelve el contenido en Spin */}

            <div className="container">
                {mostrarProyectosTree && (
                    <div className="overlay d-flex align-items-start position-fixed top-0 start-0 w-100 h-100 bg-light">
                        <div>
                            <ActualizarProyecto />
                        </div>
                        <button className="btn btn-primary position-absolute top-0 end-0 m-3" onClick={() => setMostrarProyectosTree(false)}>
                            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />Regresar
                        </button>
                    </div>
                )}

                <div className="row mb-4 d-flex align-items-center">
                    <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                        <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                            <p>PROYECTO UTF <br /> COL 160 COL</p>
                        </div>
                        <div className='objeto' style={{ flexBasis: '75%', flexGrow: 0 }}>
                            <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                        </div>
                    </div>

                    <div className="col-md-4 d-flex align-items-center justify-content-between">
                        <div className="input-group me-2 shadow-sm">
                            <input
                                type="text"
                                className="form-control shadow-sm"
                                placeholder="Buscar proyecto"
                                value={searchTerm}
                                onChange={handleInputChange}
                            />
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleClear}
                            >
                                <FontAwesomeIcon icon={faBroom} />
                            </button>
                        </div>

                        <div className="d-grid gap-2 d-md-flex align-items-center">
                            <Dropdown menu={{ items }} trigger={['click']}>
                                <Button className="btn btn-primary m-0 d-flex align-items-center justify-content-center">
                                    <FileExcelOutlined /><DownOutlined />
                                </Button>
                            </Dropdown>


                        </div>
                        <div style={{ padding: '10px' }}>
                            <Space direction="vertical" size="large">
                                <div className="traffic-light">
                                    <div className="circle" style={{ backgroundColor: '#44ae4c' }}></div>
                                    <span style={{ fontSize: '12px' }}>Validado</span>
                                </div>
                                <div className="traffic-light">
                                    <div className="circle" style={{ backgroundColor: '#408cc8' }}></div>
                                    <span style={{ fontSize: '12px' }}>Entregado</span>
                                </div>
                                <div className="traffic-light">
                                    <div className="circle" style={{ backgroundColor: '#ff5722' }}></div>
                                    <span style={{ fontSize: '12px' }}>Pendiente</span>
                                </div>
                            </Space>
                        </div>



                    </div>
                </div >

                {
                    viewMode === 'grid' ? (
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                            {error && <p className="text-danger">{error}</p>}
                            {projects.length > 0 ? (
                                projects.map((project) => (


                                    <div key={project.oid} className="col-sm-12 col-md-4 mb-3">

                                        <div className="card h-100 redondear">
                                            <div className={`card-header align-items-center text-center ${project.estado_proyecto === 'Validado' ? 'validado' :
                                                project.estado_proyecto === 'Sin Soportes' ? 'sin-soportes' :
                                                    'pendiente'}`}>


                                                <h5 className="card-title fw-bold fs-8 m-0" style={{ color: '#FFF !important' }}>{project.proyecto}</h5>
                                                <p className="card-text"><small className="codproyecto">Código: {project.codigo}</small></p>

                                            </div>
                                            <div className="row g-0">
                                                <div className="col-md-7" style={{ height: '100%', overflow: 'hidden' }}>
                                                    <ImageComponent projectId={project.oid} />
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="card-body distribucion">

                                                        <div className="card-body align-items-center p-0">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="cumpliminetoporcentaje">

                                                                        <FontAwesomeIcon icon={faVenus} className='icototales' style={{ color: '#9C27B0' }} /><span className='icototalesvalor'>{project.totMujeres}</span> <br /><span className='text-muted actualizacion'>Mujeres</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="cumpliminetoporcentaje">
                                                                        <FontAwesomeIcon icon={faMars} className='icototales' style={{ color: '#03A9F4' }} /><span className='icototalesvalor'>{project.totHombres}</span> <br /><span className='text-muted actualizacion'>Hombres</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='pt-2'>
                                                                <p className="cumpliminetoporcentaje">
                                                                    <span className='icototalesvalor'>{project.totalOrganizaciones}</span> <br /><span className='text-muted actualizacion'>Organizaciones</span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="card-footer text-muted align-items-center m-0 p-0">
                                                            <div>
                                                                <button
                                                                    className="m-2 me-2 btn-primary-lis"
                                                                    onClick={() => handleClickDirectories(project.codigo, project.proyecto)}
                                                                    style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                                                                >
                                                                    <FontAwesomeIcon icon={faSearch} title="Contenido de actualización" />
                                                                </button>
                                                                <span className="separator">|</span>

                                                                <button
                                                                    className="m-2 me-2 btn-primary-lis"
                                                                    onClick={() => handleVerOrganizaciones(project.oid)}
                                                                    style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                                                                >
                                                                    <FontAwesomeIcon icon={faUsers} title="Organizaciones del proyecto" />

                                                                </button>

                                                                <span className="separator">|</span>
                                                                <button
                                                                    className="m-2 me-2 btn-primary-lis"
                                                                    onClick={() => handleMostrarProyectosTree(project)}
                                                                    style={{ background: 'none', border: 'none', color: 'inherit', padding: 0, font: 'inherit', cursor: 'pointer' }}
                                                                >
                                                                    <FontAwesomeIcon icon={faDownload} title="Descargar directorios de actualización" />

                                                                </button>
                                                            </div>
                                                            <div>
                                                                <small className="text-muted actualizacion" style={{ whiteSpace: 'nowrap' }}>

                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>Cargando Proyectos...</p>
                            )}
                        </div>
                    ) : (
                        <div className="list-container">

                            {projects.length > 0 ? (
                                <Table
                                    id="proyectos-table"
                                    columns={columns}
                                    dataSource={projects}
                                    rowKey="oid"
                                    pagination={false}
                                />
                            ) : (
                                <p>No se encontraron proyectos.</p>
                            )}

                        </div>

                    )
                }
            </div >

        </Spin>

    );
}

export default BuscarProyecto;
