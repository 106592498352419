// src/services/authService/authService.js
import axios from 'axios';
import config from '../../config';
import Cookies from 'js-cookie';

const baseUrl = config.baseUrl;
const logoutEndpoint = '/api/auth/logout';

const logout = async () => {
    try {
        await axios.post(`${baseUrl}${logoutEndpoint}`);
        // Elimina el token de las cookies
        Cookies.remove('token');
        // Opcionalmente, podrías realizar otras acciones como limpiar el estado de la aplicación
        // o redirigir al usuario.
    } catch (error) {
        console.error('Error during logout:', error);
        throw error;
    }
};

export { logout };
