// services/organizacionService.js
import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgEndpoint = '/api/organizacionUpdate';


export const updateOrganizacion = async (data) => {
  try {
    const response = await axios.put(
      `${baseUrl}${orgEndpoint}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al actualizar la organización:', error);
    throw error;
  }
};

