import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgEndpoint = '/api/getOrganizacionesxproyectos';


const api = axios.create({
    baseURL: config.baseUrl,
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const getOrganizacionesxproyectos = async (proyectoIds) => {
    try {
        // Hacer la solicitud POST con los IDs de proyectos en el cuerpo
        const response = await api.post(`${baseUrl}${orgEndpoint}`, {
            proyectos_id: proyectoIds // Enviar los IDs de proyectos en el cuerpo de la solicitud
        });

        return {
            status: response.status,
            data: response.data.lista_organizaciones // Asegúrate de que esta clave coincida con la que envías desde el servidor
        };
    } catch (error) {
        console.error('Error fetching organizations:', error);
        return {
            status: error.response ? error.response.status : 500,
            message: 'Error fetching organizations'
        };
    }
};

export default getOrganizacionesxproyectos;
