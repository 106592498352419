import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  YAxis,
  Legend,
} from "recharts";
import "./DashboardInicial.css";
import { getTotalProyectos } from "../../services/dashboardInicial/getTotalProyectos";
import { getTotalOrganizaciones } from "../../services/dashboardInicial/getTotalOrganizaciones";
import { getTotalHombres } from "../../services/dashboardInicial/getTotalHombres";
import { getTotalMujeres } from "../../services/dashboardInicial/getTotalMujeres";
import { getMujeresHombresPorProyecto } from "../../services/dashboardInicial/getMujeresHombresPorProyecto";
import { getOrganizacionesPorProyecto } from "../../services/dashboardInicial/getOrganizacionesPorProyecto";
import { getMujeresHombresPorOrganizacion } from "../../services/dashboardInicial/getMujeresHombresPorOrganizacion";
import { getLineaProductivaPorProyecto } from "../../services/dashboardInicial/getLineaProductivaPorProyecto";
import { getOrganizacionesMer } from "../../services/dashboardInicial/getOrganizacionesMer";
import { getProyectosPorDepartamento } from "../../services/dashboardInicial/getProyectosPorDepartamento";
import { getProyectosPorMunicipio } from "../../services/dashboardInicial/getProyectosPorMunicipio";
import { getArbolDirectorios } from "../../services/dashboardInicial/getArbolDirectorios";
import { ContainerFilled, TeamOutlined, ManOutlined, WomanOutlined, FolderOpenOutlined, DownOutlined } from "@ant-design/icons";


function DashboardInicial() {
  const [totalProyectos, setTotalProyectos] = useState(0);
  const [totalOrganizaciones, setTotalOrganizaciones] = useState(0);
  const [totalHombres, setTotalHombres] = useState(0);
  const [totalMujeres, setTotalMujeres] = useState(0);
  const [dataHombresMujeres, setDataHombresMujeres] = useState([]);
  const [dataOrganizacionesProyecto, setDataOrganizacionesProyecto] = useState([]);
  const [dataMujeresHombresPorOrganizacion, setDataMujeresHombresPorOrganizacion] = useState([]);
  const [dataLineaProductivaProyecto, setDataLineaProductivaProyecto] = useState([]);
  const [dataOrganizacionesMer, setDataOrganizacionesMer] = useState([]);
  const [data, setData] = useState([]);
  const [isDrilldown, setIsDrilldown] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [dataDirectorios, setDataDirectorios] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const handleMouseEnter = () => setShowDetails(true);
  const handleMouseLeave = () => setShowDetails(false);
  const formatPercentage = (value) => `${value}%`;

  useEffect(() => {
    const fetchTotalProyectos = async () => {
      try {
        const result = await getTotalProyectos();
        setTotalProyectos(result.data.totalProyectos || 0);
      } catch (error) {
        console.error("Error al obtener el total de proyectos:", error);
      }
    };

    fetchTotalProyectos();
  }, []);

  useEffect(() => {
    const fetchTotalOrganizaciones = async () => {
      try {
        const result = await getTotalOrganizaciones();
        setTotalOrganizaciones(result.data.totalOrganizaciones || 0);
      } catch (error) {
        console.error("Error al obtener el total de organizaciones:", error);
      }
    };

    fetchTotalOrganizaciones();
  }, []);

  useEffect(() => {
    const fetchTotalHombres = async () => {
      try {
        const result = await getTotalHombres();
        setTotalHombres(result.data.totalHombres || 0);
      } catch (error) {
        console.error("Error al obtener el total de hombres:", error);
      }
    };

    fetchTotalHombres();
  }, []);

  useEffect(() => {
    const fetchTotalMujeres = async () => {
      try {
        const result = await getTotalMujeres();
        setTotalMujeres(result.data.totalMujeres || 0);
      } catch (error) {
        console.error("Error al obtener el total de mujeres:", error);
      }
    };

    fetchTotalMujeres();
  }, []);

  useEffect(() => {
    const fetchMujeresHombresPorProyecto = async () => {
      try {
        const result = await getMujeresHombresPorProyecto();
        const data = result.data.data || [];

        // Añadir una nueva propiedad que combine codigo_proyecto y nombre_proyecto_organizaciones
        const updatedData = data.map((item) => ({
          ...item,
          nombre_completo_proyecto: `${item.codigo_proyecto} ${item.nombre_proyecto}`,
        }));

        // Filtrar datos para asegurar que no hay valores nulos en total_hombres y total_mujeres
        const filteredData = updatedData.filter(
          (item) => item.total_hombres !== null && item.total_mujeres !== null
        );

        // Ordenar los datos de acuerdo al código de proyecto
        const sortedData = filteredData.sort((a, b) => {
          if (a.codigo_proyecto < b.codigo_proyecto) return -1;
          if (a.codigo_proyecto > b.codigo_proyecto) return 1;
          return 0;
        });

        setDataHombresMujeres(sortedData);
      } catch (error) {
        console.error("Error al obtener datos por proyecto:", error);
      }
    };

    fetchMujeresHombresPorProyecto();
  }, []);

  useEffect(() => {
    const fetchOrganizacionesPorProyecto = async () => {
      try {
        const result = await getOrganizacionesPorProyecto();
        const data = result.data.data || [];

        // Añadir una nueva propiedad que combine codigo_proyecto y nombre_proyecto_organizaciones
        const updatedData = data.map((item) => ({
          ...item,
          nombre_completo_proyecto: `${item.codigo_proyecto} ${item.nombre_proyecto_organizaciones}`,
        }));

        // Ordenar los datos de acuerdo al código de proyecto
        const sortedData = updatedData.sort((a, b) => {
          if (a.codigo_proyecto < b.codigo_proyecto) return -1;
          if (a.codigo_proyecto > b.codigo_proyecto) return 1;
          return 0;
        });

        setDataOrganizacionesProyecto(sortedData);
      } catch (error) {
        console.error("Error al obtener organizaciones por proyecto:", error);
      }
    };

    fetchOrganizacionesPorProyecto();
  }, []);

  useEffect(() => {
    const fetchHombresMujeresPorOrganizacion = async () => {
      try {
        const result = await getMujeresHombresPorOrganizacion();
        setDataMujeresHombresPorOrganizacion(result.data || []);
      } catch (error) {
        console.error(
          "Error al obtener hombres y mujeres por organización:",
          error
        );
      }
    };

    fetchHombresMujeresPorOrganizacion();
  }, []);

  useEffect(() => {
    const fetchLineaProductivaProyectos = async () => {
      try {
        const result = await getLineaProductivaPorProyecto();
        setDataLineaProductivaProyecto(result.data || []);
      } catch (error) {
        console.error(
          "Error al obtener datos de línea productiva por proyecto:",
          error
        );
      }
    };

    fetchLineaProductivaProyectos();
  }, []);

  useEffect(() => {
    const fetchOrganizacionesMer = async () => {
      try {
        const result = await getOrganizacionesMer();
        setDataOrganizacionesMer(result.data || []);
      } catch (error) {
        console.error(
          "Error al obtener datos de línea productiva por proyecto:",
          error
        );
      }
    };

    fetchOrganizacionesMer();
  }, []);


  useEffect(() => {
    const fetchDepartamentos = async () => {
      try {
        const resultDepartamentos = await getProyectosPorDepartamento();
        if (resultDepartamentos && resultDepartamentos.data) {
          const departamentosData = resultDepartamentos.data.map(departamento => ({
            name: departamento.departamento,
            value: departamento.total_proyectos,
            departamento_id: departamento.departamento_id,
          }));
          setData(departamentosData);
          setOriginalData(departamentosData);  // Guardar datos originales
        }
      } catch (error) {
        console.error('Error al obtener datos de departamentos:', error);
      }
    };

    fetchDepartamentos();
  }, []);

  const handleBarClick = async (data) => {
    try {
      const resultMunicipios = await getProyectosPorMunicipio(data.departamento_id);

      if (resultMunicipios && resultMunicipios.data) {
        // Agrupar y contar proyectos por municipio
        const municipioProjectsCount = resultMunicipios.data.reduce((acc, municipio) => {
          if (acc[municipio.municipio]) {
            acc[municipio.municipio].value += 1;
            acc[municipio.municipio].projects.push({
              name: municipio.nombre_proyecto,
              code: municipio.codigo_proyecto
            });
          } else {
            acc[municipio.municipio] = {
              name: municipio.municipio,
              value: 1,
              municipio_id: municipio.municipio_id,
              projects: [{
                name: municipio.nombre_proyecto,
                code: municipio.codigo_proyecto
              }]
            };
          }
          return acc;
        }, {});

        // Convertir el objeto a un array
        const municipioProjectsArray = Object.values(municipioProjectsCount);

        setData(municipioProjectsArray);
        setIsDrilldown(true);
      }
    } catch (error) {
      console.error('Error al obtener datos de municipios:', error);
    }
  };

  const handleBackClick = async () => {
    try {
      const resultDepartamentos = await getProyectosPorDepartamento();
      if (resultDepartamentos && resultDepartamentos.data) {
        const departamentosData = resultDepartamentos.data.map(departamento => ({
          name: departamento.departamento,
          value: departamento.total_proyectos,
          departamento_id: departamento.departamento_id,
        }));
        setData(departamentosData);
        setOriginalData(departamentosData);  // Guardar datos originales
        setIsDrilldown(false);
      }
    } catch (error) {
      console.error('Error al obtener datos de departamentos:', error);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const municipioData = payload[0].payload;
      return (
        <div className="custom-tooltip my-custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="intro">{`Proyectos: ${municipioData.value}`}</p>
          {municipioData.projects && municipioData.projects.map((project, index) => (
            <p key={index} className="intro">{`${project.name} (Código: ${project.code})`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    const fetchArbolDirectorios = async () => {
      try {
        const result = await getArbolDirectorios();
        const data = result.data || [];
        setDataDirectorios(data);
        // Calcular el total de archivos después de obtener los datos
        setTotalFiles(calcularTotalArchivos(data));
      } catch (error) {
        console.error(
          "Error al obtener datos de línea productiva por proyecto:",
          error
        );
      }
    };

    fetchArbolDirectorios();
  }, []);

  // Función recursiva para contar archivos y carpetas
  const contarElementos = (nodos) => {
    return nodos.reduce(
      (acc, nodo) => {
        if (nodo.type === "folder") {
          // Contar elementos en la carpeta actual
          const { totalCarpetas, totalArchivos } = contarElementos(
            nodo.children || []
          );
          acc.totalCarpetas += 1 + totalCarpetas;
          acc.totalArchivos += totalArchivos;
        } else if (nodo.name !== "fileInfo.json") {
          // Contar cada archivo excepto fileInfo.json
          acc.totalArchivos += 1;
        }
        return acc;
      },
      { totalCarpetas: 0, totalArchivos: 0 }
    );
  };

  // Función para calcular el total de archivos en toda la estructura
  const calcularTotalArchivos = (nodos) => {
    return nodos.reduce((acc, nodo) => {
      if (nodo.type === "folder") {
        // Sumar archivos en esta carpeta y en sus subcarpetas
        return acc + calcularTotalArchivos(nodo.children || []);
      } else if (nodo.name !== "fileInfo.json") {
        // Contar cada archivo excepto fileInfo.json
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  // Obtener datos formateados
  const obtenerDatosFormateados = () => {
    return dataDirectorios
      .filter((nodo) => nodo.name !== "fileInfo.json") // Filtrar nodos para excluir fileInfo.json
      .map((nodo) => {
        const { totalCarpetas, totalArchivos } = contarElementos(
          nodo.children || []
        );
        return {
          name: nodo.name,
          totalCarpetas,
          totalArchivos,
        };
      });
  };
  return (
    <>
      <div>
        <h1
          style={{ color: "#104379", textAlign: "center", fontWeight: "bold" }}
        >
          Gráficos UTF-COL-160-COL
        </h1>
      </div>

      <div className="container my-4" id="contenedorCards">
        <div className="row d-flex justify-content-between">
          <div className="col-md-2 mb-4">
            <div id="card-proyectos" className="card-graficos custom-card">
              <div className="card-body d-flex flex-column align-items-center custom-card-body">
                <div className="d-flex align-items-center">
                  <ContainerFilled
                    style={{ fontSize: "1.500rem", marginRight: "0.625rem" }}
                  />
                  <h2 id="value-proyectos" className="custom-value">
                    {totalProyectos}
                  </h2>
                </div>
                <h2 id="title-proyectos" className="custom-title">
                  Proyectos Actualización
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-2 mb-4">
            <div id="card-organizaciones" className="card-graficos custom-card">
              <div className="card-body d-flex flex-column align-items-center custom-card-body">
                <div className="d-flex align-items-center">
                  <TeamOutlined
                    style={{ fontSize: "1.500rem", marginRight: "0.625rem" }}
                  />
                  <h2 id="value-organizaciones" className="custom-value">
                    {totalOrganizaciones}
                  </h2>
                </div>
                <h2 id="title-organizaciones" className="custom-title">
                  Organizaciones
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-2 mb-4">
            <div id="card-hombres" className="card-graficos custom-card">
              <div className="card-body d-flex flex-column align-items-center custom-card-body">
                <div className="d-flex align-items-center">
                  <ManOutlined
                    style={{ fontSize: "1.500rem", marginRight: "0.625rem" }}
                  />
                  <h2 id="value-hombres" className="custom-value">
                    {totalHombres}
                  </h2>
                </div>
                <h2 id="title-hombres" className="custom-title">
                  Hombres
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-2 mb-4">
            <div id="card-mujeres" className="card-graficos custom-card">
              <div className="card-body d-flex flex-column align-items-center custom-card-body">
                <div className="d-flex align-items-center">
                  <WomanOutlined
                    style={{ fontSize: "1.500rem", marginRight: "0.625rem" }}
                  />
                  <h2 id="value-mujeres" className="custom-value">
                    {totalMujeres}
                  </h2>
                </div>
                <h2 id="title-mujeres" className="custom-title">
                  Mujeres
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-2 mb-4">
            <div
              id="card-documentos"
              className="card-graficos custom-card"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="card-body d-flex flex-column align-items-center custom-card-body">
                <div className="d-flex align-items-center">
                  <FolderOpenOutlined
                    style={{
                      fontSize: "1.500rem",
                      marginRight: "0.625rem",
                      color: "#ddd",
                    }}
                  />
                  <h2 id="value-documentos" className="custom-value">
                    {totalFiles}
                  </h2>
                </div>
                <div className="d-flex align-items-center">
                  <h2 id="title-documentos" className="custom-title">
                    Documentos Actualización
                  </h2>
                  <DownOutlined className="down-arrow" />
                </div>
              </div>

              {showDetails && (
                <div className="card-details">
                  <div className="details-table-container">
                    <table className="details-table">
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Total Archivos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {obtenerDatosFormateados().map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.totalArchivos}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="containerGraficos">
        <div className="graficoBarras1">
          <h3
            style={{
              color: "#338BC7",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Cantidad de hombres y mujeres por proyecto
          </h3>
          <div className="chartWrapper">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={dataHombresMujeres}
                margin={{ top: 20, right: 30, left: 20, bottom: 90 }} // Aumenta el margen inferior
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="nombre_completo_proyecto"
                  angle={-60} // Ajusta el ángulo para una mejor visibilidad
                  textAnchor="end"
                  interval={0}
                  tick={{ fontSize: 12 }} // Ajusta el tamaño del texto
                />
                <YAxis domain={[0, 700]} />
                <Tooltip />
                <Legend
                  verticalAlign="top" // Mueve la leyenda a la parte superior
                  align="center" // Centra la leyenda horizontalmente
                />
                <Bar dataKey="total_hombres" fill="#477337" name="Hombres">
                  <LabelList dataKey="total_hombres" position="top" />
                </Bar>
                <Bar dataKey="total_mujeres" fill="#8DC044" name="Mujeres">
                  <LabelList dataKey="total_mujeres" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="graficoBarras2">
          <h3
            style={{
              color: "#338BC7",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Organizaciones por proyecto
          </h3>
          <div className="chartWrapper">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={dataOrganizacionesProyecto}
                margin={{ top: 20, right: 30, left: 20, bottom: 90 }} // Aumenta el margen inferior
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="nombre_completo_proyecto"
                  angle={-60} // Ajusta el ángulo para una mejor visibilidad
                  textAnchor="end"
                  interval={0}
                  tick={{ fontSize: 12 }} // Ajusta el tamaño del texto
                />
                <YAxis domain={[0, 10]} /> {/* Ajusta el dominio del eje Y */}
                <Tooltip />
                <Bar
                  dataKey="total_organizaciones"
                  fill="#0B80C4"
                  name="Organización"
                  barSize={30}
                >
                  <LabelList dataKey="total_organizaciones" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="containerGraficos">
        <div className="graficoBarras1">
          <h3
            style={{
              color: "#338BC7",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Cantidad de hombres y mujeres por Organización
          </h3>
          <div className="chartWrapper">
            <ResponsiveContainer width="100%" height={500}>
              <BarChart
                data={dataMujeresHombresPorOrganizacion.data}
                margin={{
                  top: 60, // Aumenta el margen superior para dar espacio a la leyenda
                  right: 30,
                  left: 20,
                  bottom: 20, // Aumenta el margen inferior
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="nombre_organizacion"
                  angle={-60} // Rotar las etiquetas verticalmente
                  textAnchor="end"
                  height={60} // Ajusta la altura del contenedor de etiquetas
                  interval={0} // Muestra todas las etiquetas
                  tick={{ fontSize: 12 }} // Ajusta el tamaño del texto
                />
                <YAxis domain={[0, "auto"]} />
                <Tooltip />
                <Legend
                  verticalAlign="top" // Mueve la leyenda a la parte superior
                  align="center" // Centra la leyenda horizontalmente
                />
                <Bar
                  dataKey="total_hombres"
                  stackId="a"
                  fill="#0B80C4"
                  name="Hombres"
                  barSize={120} // Ajusta el tamaño de las barras
                >
                  <LabelList
                    dataKey="total_hombres"
                    position="inside"
                    fill="#fff"
                    fontSize={10}
                  />
                </Bar>
                <Bar
                  dataKey="total_mujeres"
                  stackId="a"
                  fill="#ad4bad"
                  name="Mujeres"
                  barSize={120} // Ajusta el tamaño de las barras
                >
                  <LabelList
                    dataKey="total_mujeres"
                    position="inside"
                    fill="#fff"
                    fontSize={10}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="graficoBarras2">
          <h3
            style={{
              color: "#338BC7",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Linea productiva por cantidad de proyectos asociados
          </h3>
          <div className="chartWrapper">
            <ResponsiveContainer width="100%" height={500}>
              <BarChart
                data={dataLineaProductivaProyecto.data}
                layout="vertical" // Configura el layout del gráfico a horizontal
                margin={{ top: 80, right: 700, left: 20, bottom: 20 }} // Aumenta el margen inferior
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="linea_productiva_nombre"
                  width={150} // Ajusta el ancho del contenedor de etiquetas
                  tick={{ fontSize: 12 }} // Ajusta el tamaño del texto
                  interval={0} // Muestra todas las etiquetas
                />
                <Tooltip />
                <Bar
                  dataKey="cantidad_proyectos"
                  fill="#8DC044"
                  name="cantidad_proyectos"
                >
                  <LabelList dataKey="cantidad_proyectos" position="right" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="containerGraficos">
        <div className="graficoBarras1">
          <h3 style={{ color: "#338BC7", textAlign: "center", fontWeight: "bold" }}>
            Organizaciones porcentaje MER
          </h3>
          <div className="chartWrapper">
            <ResponsiveContainer width="100%" height={500}>
              <BarChart
                data={dataOrganizacionesMer.data}
                margin={{
                  top: 60, // Aumenta el margen superior para dar espacio a la leyenda
                  right: 30,
                  left: 20,
                  bottom: 20, // Aumenta el margen inferior
                }}
                barCategoryGap={30} // Aumenta el espacio entre las categorías de barras
                barGap={5} // Ajusta el espacio entre las barras dentro de una categoría
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="organizacion_sigla"
                  angle={-60} // Rotar las etiquetas verticalmente
                  textAnchor="end"
                  height={60} // Ajusta la altura del contenedor de etiquetas
                  interval={0} // Muestra todas las etiquetas
                  tick={{ fontSize: 12 }} // Ajusta el tamaño del texto
                />
                <YAxis tickFormatter={formatPercentage} />
                <Tooltip formatter={(value) => `${value}%`} />
                <Legend
                  verticalAlign="top" // Mueve la leyenda a la parte superior
                  align="center" // Centra la leyenda horizontalmente
                />
                <Bar
                  dataKey="porcentaje_mer"
                  stackId="a"
                  fill="#47AE4C"
                  name="Porcentaje"
                  barSize={20} // Ajusta el tamaño de las barras
                >
                  <LabelList
                    dataKey="porcentaje_mer"
                    position="top"
                    formatter={formatPercentage}
                    style={{ fontSize: '10px', fill: '#333' }} // Ajusta el tamaño de la letra y color
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="graficoBarras2">
          <h3 style={{ color: "#338BC7", textAlign: "center", fontWeight: "bold" }}>
            Proyecto en actualización por departamento
          </h3>
          <div className="chartWrapper">
            <ResponsiveContainer width="100%" height={500}>
              <BarChart data={data} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 5]} />
                <YAxis type="category" dataKey="name" width={150} tick={{ fontSize: 12 }} interval={0} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="value" fill="#477337" onClick={handleBarClick}>
                  <LabelList dataKey="value" position="right" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            {isDrilldown && <button onClick={handleBackClick}>Volver</button>}
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardInicial;
