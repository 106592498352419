import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/view_tree_file';

const view_tree_file = async (pathFromClient) => {
    try {
        const response = await axios.post(
            `${baseUrl}${endpoint}`,
            { pathFromClient },
            { responseType: 'blob' } // Especifica que la respuesta es un blob (archivo binario)
        );

        let contentType = response.headers['content-type'];
        let fileType;

        // Determina el tipo de archivo por el content-type de la respuesta
        if (contentType.includes('image')) {
            fileType = 'image/png'; // Tipo MIME para imágenes
        } else if (contentType.includes('pdf')) {
            fileType = 'application/pdf'; // Tipo MIME para PDF
        } else {
            throw new Error('Tipo de archivo no soportado');
        }

        // Crear un URL de objeto para el blob recibido
        const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));

        // Abre el archivo en una nueva ventana
        window.open(url);

        return {
            status: response.status,
            message: 'Archivo abierto correctamente',
        };
    } catch (error) {
        console.error('Error al abrir el archivo:', error);
        return {
            status: error.response ? error.response.status : 500,
            message: 'Error al abrir el archivo',
        };
    }
};

export { view_tree_file };
