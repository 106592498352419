// mapaInicio.jsx
import React from 'react';
import './mapaInicio.css'; // Asegúrate de crear este archivo para los estilos

const MapaInicio = () => {
    return (
        <div className="map-container">
            <iframe
                src="https://arcg.is/1Druar1"
                title="Mapa"
                className="map-iframe"
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default MapaInicio;
