import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/upload_tree_dir';

// Función para obtener la ruta de la carpeta completa
function getFolderPath(filePath) {
    const parts = filePath.split('/');
    if (parts.length > 1) {
        parts.pop(); // Elimina el nombre del archivo
        return parts.join('/'); // Une las partes restantes en la ruta de la carpeta
    }
    return '';
}


// Servicio para cargar múltiples archivos (carpetas) y su información adicional al backend
const upload_tree_dir = async (pathFromClient, fileDataArray) => {
    try {
        const formData = new FormData();
        formData.append('pathFromClient', pathFromClient);

        // Añadir cada archivo al formData junto con webkitRelativePath
        fileDataArray.forEach((fileData) => {
            formData.append('file', fileData.file);
            formData.append('webkitRelativePath', (getFolderPath(fileData.webkitRelativePath)) || '');
        });
        
        const response = await axios.post(`${baseUrl}${endpoint}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al cargar los archivos:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export { upload_tree_dir };
