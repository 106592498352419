import axios from 'axios';
import config from '../../config'; // Ajusta la ruta según tu estructura

const baseUrl = config.baseUrl;
//const baseUrl = 'http://localhost:5000';

// Función para reemplazar valores nulos por '-'
const replaceNullWithDash = (value) => (value === null || value === undefined) ? '-' : value;


// Función para obtener datos del mapa de Colombia
const getMapColombia = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/mapColombia`);
        const data = response.data.data; // Ajusta según el formato de respuesta



        const processedData = data.map(depto => ({

            id: depto.codLib,
            idCodInterno: depto.oid,
            nameFusion: depto.nameFusion,
            value: replaceNullWithDash(depto.numOrganizaciones),
            color: '#4275af', // Color base de la región
            hoverBorderThickness: '2', // Grosor del borde en hover
            toolText: `<div style="font-family: Arial, sans-serif; font-size: 14px;">
            <table style="width: 100%; border-collapse: collapse; margin-left: auto; margin-right: auto;">
                <tbody>
                    <!-- Título del departamento -->
                    <tr>
                        <td colspan="2" style="font-weight: bold; font-size: 16px; color: #333; padding-bottom: 10px; border-bottom: 2px solid #d0d0d0;">${depto.departamento}</td>
                    </tr>
                    <!-- Total Municipios y Total Proyectos -->
                    <tr>
                        <td style="font-weight: bold; color: #0056b3; border-right: 2px solid #d0d0d0; padding-right: 10px;">Municipios: <span style="color: #333;"></span></td>
                        <td style="font-weight: bold; color: #0056b3; padding-left: 10px;">Proyectos: <span style="color: #333;"></span></td>
                    </tr>
                    <!-- Municipios y Proyectos -->
                    <tr>
                        <td style="font-weight: bold; color: #0056b3; border-right: 2px solid #d0d0d0; padding-right: 10px;"></td>
                        <td style="font-weight: bold; color: #0056b3; padding-left: 10px;"></td>
                    </tr>
                    <tr>
                        <td style="border-right: 2px solid #d0d0d0; padding-right: 10px;">
                            <ul style="margin: 0; padding-left: 15px; list-style-type: disc;">
                                ${depto.listaMunicipios ? depto.listaMunicipios.split('</li><li>').map(m => `<li>${m.replace(/<\/?li>/g, '')}</li>`).join('') : '-'}
                            </ul>
                        </td>
                        <td>
                            <ul style="margin: 0; padding-left: 15px; list-style-type: disc;">
                                ${depto.listaProyectos ? depto.listaProyectos.split('</li><li>').map(p => `<li>${p.replace(/<\/?li>/g, '')}</li>`).join('') : '-'}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="border-top: 2px solid #d0d0d0;">&nbsp;</td>
                    </tr>
                    <!-- Datos de Organizaciones -->
                    <tr>
                        <td colspan="2" style="text-align: left; font-weight: bold; color: #333; padding-bottom: 10px; border-bottom: 2px solid #d0d0d0;">Datos de Organizaciones</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; color: #0056b3; border-right: 2px solid #d0d0d0; padding-right: 10px;">Total Organizaciones: <span style="color: #333;">${replaceNullWithDash(depto.numOrganizaciones)}</span></td>
                        <td style="font-weight: bold; color: #0056b3; padding-left: 10px;">Hombres: <span style="color: #333;">${replaceNullWithDash(depto.totalHombres)}</span></td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; color: #0056b3; border-right: 2px solid #d0d0d0; padding-right: 10px;">Total Participantes: <span style="color: #333;">${replaceNullWithDash(Number(depto.totalHombres) + Number(depto.totalMujeres))}</span></td>
                        <td style="font-weight: bold; color: #0056b3; padding-left: 10px;">Mujeres: <span style="color: #333;">${replaceNullWithDash(depto.totalMujeres)}</span></td>
                    </tr>
                    <!-- Listado de Organizaciones -->
                    <tr>
                        <td colspan="2" style="font-weight: bold; color: #0056b3; padding-top: 10px; border-top: 2px solid #d0d0d0;">Organizaciones</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <ul style="margin: 0; padding-left: 15px; list-style-type: disc;">
                                ${depto.listaOrganizaciones ? depto.listaOrganizaciones.split('</li><li>').map(o => `<li>${o.replace(/<\/?li>/g, '')}</li>`).join('') : '-'}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>`,
        }));
        return {
            status: response.status,
            data: {
                data: processedData // Asegúrate de que este campo se corresponda con lo esperado por FusionCharts
            }
        };
    } catch (error) {
        console.error('Error al obtener datos del mapa de Colombia:', error);
        throw error; // Lanza el error para ser manejado en el componente React
    }
};

export { getMapColombia };
