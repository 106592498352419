import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/ippta-radar';

const getIppta = async (identificacion, codigoProyecto) => {
    try {
        const params = {};
        if (identificacion) params.identificacion = identificacion;
        if (codigoProyecto) params.codigoProyecto = codigoProyecto;

        const response = await axios.get(`${baseUrl}${endpoint}`, { params });
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        console.error('Error al obtener los reportes:', error);
        throw error; // Lanza el error para ser manejado en el componente React
    }
};

export { getIppta };
