import React, { useState, useEffect, useRef } from 'react';
import { Spin, Layout, Table, Descriptions } from 'antd';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import { getPlanesPedagogicos } from '../../services/planesPedagogicos/planesPedagogicosServices'; // Importa tu servicio

const { Content } = Layout;
const PlanPedagogicoI = (codigo_tarea) => {

    const [treeData, setTreeData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const tmpCodigo = String(codigo_tarea.codigo_tarea.codigo_tarea).replace(/\./g, '');
    const codigo_proyecto_busqueda = 'tarea_' + tmpCodigo;
    const [loading, setLoading] = useState(true);
    const nameproyecto = "Tarea POA";
    const [rootNodeSelected, setRootNodeSelected] = useState(true);
    const inputRef = useRef(null);
    //Control de Permisos
    const appName = 'file-managerPOA';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export === 'Y';
    const permissionPrint = permissions.priv_print === 'Y';



    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getPlanesPedagogicos();
                const transformedData = transformData(response.data);  // Ahora transformamos todos los proyectos
                setTreeData(transformedData);  // Guardamos los datos transformados
            } catch (error) {
                console.error('Error fetching planes pedagógicos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [codigo_proyecto_busqueda]);





    const { codActividad, nameActividad, codigo_tarea: codigoTarea, tarea, indicador } = codigo_tarea.codigo_tarea;
    // Inicializar el valor predeterminado
    let medioVerificacion = "Medio de Verificación";
    const transformData = (data) => {
        return data.map((proyecto, index) => {
            // Calcular los porcentajes
            const avanceEcas = calculatePercentage(proyecto.ecas[0].cantidadArchivos, proyecto.ecas[0].meta);
            const avanceVisitas = calculatePercentage(proyecto.visitas[0].cantidadArchivos, proyecto.visitas[0].meta);
            const avanceSesiones = calculatePercentage(proyecto.sesiones[0].cantidadArchivos, proyecto.sesiones[0].meta);

            // Calcular el promedio general
            const general = calcularGeneral(avanceEcas, avanceVisitas, avanceSesiones);

            return {
                key: `${proyecto.oid}-nombre`,  // Clave única para cada proyecto
                contador: index + 1,  // Añadir un contador que empieza en 1
                name: proyecto.nombre,  // Mostramos el nombre del proyecto

                ejecucionEcas: proyecto.ecas[0].cantidadArchivos,
                metasEcas: proyecto.ecas[0].meta,
                avanceEcas: `${avanceEcas}%`,

                ejecucionVisitas: proyecto.visitas[0].cantidadArchivos,
                metasVisitas: proyecto.visitas[0].meta,
                avanceVisitas: `${avanceVisitas}%`,

                ejecucionSesiones: proyecto.sesiones[0].cantidadArchivos,
                metaSesiones: proyecto.sesiones[0].meta,
                avanceSesiones: `${avanceSesiones}%`,

                general: `${general}%`  // Promedio general
            };
        });
    };



    const calculatePercentage = (ejecucion, metas) => {
        return metas === 0 ? 0 : ((ejecucion / metas) * 100).toFixed(2);
    };

    const calcularGeneral = (ecaPercentage, visitaPercentage, sesionPercentage) => {
        // Convertir los porcentajes a valores numéricos
        const ecaValue = parseFloat(ecaPercentage) / 100; // Convertir de % a decimal
        const visitaValue = parseFloat(visitaPercentage) / 100; // Convertir de % a decimal
        const sesionValue = parseFloat(sesionPercentage) / 100; // Convertir de % a decimal

        // Sumar y calcular el promedio
        const total = ecaValue + visitaValue + sesionValue;
        const promedio = total / 3;

        // Devolver el promedio en formato porcentaje
        return (promedio * 100).toFixed(2); // Devuelve el promedio como string con dos decimales
    };


    return (
        <Layout>

            {loading ? (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="container-fluid py-0 my-2" style={{ marginBottom: '10px !important' }}>
                    <Descriptions bordered column={1} style={{ padding: '0 0 0 10px !important' }}>
                        <Descriptions.Item label="Actividad">
                            {codActividad} - {nameActividad}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tarea">
                            {codigoTarea} - {tarea}
                        </Descriptions.Item>
                        <Descriptions.Item label="Indicador">
                            {indicador}
                        </Descriptions.Item>
                        <Descriptions.Item label="Medio de verificación">
                            <div>
                                {typeof medioVerificacion === 'string'
                                    ? medioVerificacion
                                    : JSON.stringify(medioVerificacion)}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Layout>
                        <Layout style={{ padding: '0px 24px 24px' }}>
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                {rootNodeSelected && !selectedItem && (
                                    <div style={{ textAlign: 'center', padding: '0px', marginTop: '-5px !important' }}>
                                        <p className="titulo_proyecto display-4">Bienvenid@</p>
                                        <div>
                                            <Table
                                                dataSource={treeData}
                                                defaultExpandAllRows={true}
                                                scroll={{ y: 400 }}  // Puedes ajustar la altura aquí si es necesario
                                                pagination={{
                                                    pageSize: 15,  // Número de filas por página
                                                }}
                                                columns={[
                                                    {
                                                        title: 'No.',  // Título del contador
                                                        dataIndex: 'contador',  // Columna de contador
                                                        key: 'contador',
                                                        width: 80,
                                                        align: 'center',
                                                    },
                                                    {
                                                        title: 'Planes pedagógicos implementados',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                        width: 300,
                                                    },
                                                    {
                                                        title: 'ECAS',
                                                        children: [
                                                            {
                                                                title: 'Metas',
                                                                dataIndex: 'metasEcas',
                                                                key: 'metasEcas',
                                                                width: 80,
                                                                align: 'center', // Centra el contenido de esta columna
                                                            },
                                                            {
                                                                title: 'Ejecución',
                                                                dataIndex: 'ejecucionEcas',
                                                                key: 'ejecucionEcas',
                                                                width: 100,
                                                                align: 'center', // Centra el contenido de esta columna
                                                            },
                                                            {
                                                                title: '% Avance',
                                                                dataIndex: 'avanceEcas',
                                                                key: 'avanceEcas',
                                                                width: 100,
                                                                align: 'center', // Centra el contenido de esta columna
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        title: 'Visitas de acompañamiento',
                                                        children: [
                                                            {
                                                                title: 'Metas',
                                                                dataIndex: 'metasVisitas',
                                                                key: 'metasVisitas',
                                                                width: 80,
                                                                align: 'center',
                                                            },
                                                            {
                                                                title: 'Ejecución',
                                                                dataIndex: 'ejecucionVisitas',
                                                                key: 'ejecucionVisitas',
                                                                width: 100,
                                                                align: 'center',
                                                            },
                                                            {
                                                                title: '% Avance',
                                                                dataIndex: 'avanceVisitas',
                                                                key: 'avanceVisitas',
                                                                width: 100,
                                                                align: 'center',
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        title: 'Sesiones de capacitación',
                                                        children: [
                                                            {
                                                                title: 'Metas',
                                                                dataIndex: 'metaSesiones',
                                                                key: 'metaSesiones',
                                                                width: 80,
                                                                align: 'center',
                                                            },
                                                            {
                                                                title: 'Ejecución',
                                                                dataIndex: 'ejecucionSesiones',
                                                                key: 'ejecucionSesiones',
                                                                width: 100,
                                                                align: 'center',
                                                            },
                                                            {
                                                                title: '% Avance',
                                                                dataIndex: 'avanceSesiones',
                                                                key: 'avanceSesiones',
                                                                width: 100,
                                                                align: 'center',
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        title: 'Total',
                                                        children: [
                                                            {
                                                                title: '% General',
                                                                dataIndex: 'general',
                                                                key: 'general',
                                                                width: 100,
                                                                align: 'center',
                                                            },
                                                        ],
                                                    },
                                                ]}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Content>
                        </Layout>
                    </Layout>
                </div >
            )}
        </Layout >
    );
};

export default PlanPedagogicoI;
