import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpointVerificar = '/api/verificarEdah';

// Crear una instancia de Axios
const api = axios.create({
    baseURL: baseUrl,
});

// Interceptor para agregar el token de autenticación a las solicitudes
api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Función para verificar si un participante existe por número de identificación
export const getEdahporIdentificacion = async (identificacion) => {
    try {
        const response = await api.get(`${endpointVerificar}/${identificacion}`, {
            headers: {
                'Content-Type': 'application/json',  // Encabezado JSON para enviar datos correctamente
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al verificar la encuesta:', error);
        throw error;
    }
};

export default getEdahporIdentificacion;
