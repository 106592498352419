import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/proyectos/getEstadoProyecto';

const getEstadoProyecto = async (codigo) => {
    try {
        const response = await axios.get(`${baseUrl}${endpoint}/${codigo}`);

        return {
            status: response.status,
            data: response.data,
        };
    } catch (error) {
        console.error('Error fetching project state:', error.response ? error.response.data : error.message);
        return {
            status: error.response ? error.response.status : 500,
            message: error.response ? error.response.data.message : 'Error fetching project state.',
        };
    }
};

export { getEstadoProyecto };
