import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/appSlider/';


const deleteAppSlider = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}${endpoint}${id}`);
    return response
  } catch (error) {
    console.error('Error al eliminar el documento:', error);
  }
};

export default deleteAppSlider;
