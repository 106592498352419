import axios from 'axios';
import config from '../../config'; // Asegúrate de que esta ruta sea correcta

const baseUrl = config.baseUrl;
const orgEndpoint = 'api/insert-poa-ejecucion'; // Endpoint para actualizar ítems

export const insertItems = async (items) => {

    // Verifica si `items` es un array, si no lo es, lo convierte en un array
    const dataToSend = Array.isArray(items) ? items : [items];

    try {
        // Convierte los datos a JSON si no están ya en formato JSON
        const jsonData = JSON.stringify(dataToSend);
        const response = await axios.post(`${baseUrl}/${orgEndpoint}`, jsonData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // Manejo de la respuesta basada en el código de estado
        switch (response.status) {
            case 200:
                return response.data; // Devuelve la respuesta del servidor
            case 400:
                throw new Error('Solicitud incorrecta. Verifica los datos enviados.');
            case 500:
                throw new Error('Error en el servidor. Inténtalo de nuevo más tarde.');
            default:
                throw new Error(`Código de estado inesperado: ${response.status}`);
        }
    } catch (error) {
        // Manejo del error, por ejemplo, mostrar un mensaje al usuario
        throw error; // Propaga el error para manejarlo en el componente
    }
};
