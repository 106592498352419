import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/upload_tree_file'; 
// Servicio para cargar un archivo y su información adicional al backend
const upload_tree_file = async (pathFromClient, file, uploadedBy) => {
  try {
    const formData = new FormData();
    formData.append('pathFromClient', pathFromClient);
    formData.append('uploadedBy', uploadedBy);
    formData.append('file', file);
    const response = await axios.post(`${baseUrl}${endpoint}`, formData, {
      
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // Incrementar tiempo de espera si es necesario para cargas grandes
      timeout: 0 // 0 significa sin límite (configura a un valor más alto si prefieres)
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error al cargar el archivo 1:', error.response.data);
    } else if (error.request) {
      console.error('Error al cargar el archivo 2: No se recibió respuesta del servidor', error.request);
    } else {
      console.error('Error al cargar el archivo 3:', error.message);
    }
    throw error;
  }
};

export { upload_tree_file };
