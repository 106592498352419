// src/components/Participantes.tsx
import React, { useEffect, useState } from 'react';
import { green, red, yellow, orange } from '@ant-design/colors';
import { Button, Input, Space, Table, Alert, Spin, Progress, Tooltip} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { SearchOutlined, DownloadOutlined, ClearOutlined} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { getArbolDirectorios } from '../../services/dashboardInicial/getArbolDirectorios';

//Estilos css personalizados
import './Participantes.css';
//Importamos libreria para la exportación de excel
import ExcelJS from 'exceljs';


//Servicios
import getParticipantes from '../../services/participant/participantesServices';

const Participantes = () => {

  const [participantes, setParticipantes] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewMode] = useState('table'); // Estado para manejar el modo de vista
  const [departamentosFilters, setDepartamentosFilters] = useState([]);
  const [municipiosFilters, setMunicipiosFilters] = useState([]);
  const [organizacionesFilters, setOrganizacionesFilters] = useState([]);
  const [lineaProductiva_1Filters, setLineaProductiva_1Filters] = useState([]);


  const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
  const [sortOrder, setSortOrder] = useState({});
  const [sortField, setSortField] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});


  const [searchTerm, setSearchTerm] = useState(''); // Agrega este estado

  const location = useLocation();
  const { nombre_participante } = location.state || {};

  const [isModalVisible, setIsModalVisible] = useState(false);


  const handleClose = () => {
    setIsModalVisible(false);
  };

  const fetchPartcipantes = async () => {
    try {
      const { status, data } = await getParticipantes();
      setStatus(status);

      if (status === 200) {
        let filteredData = data;
        // Verificar si nombre_participante está presente y filtrar datos
        if (nombre_participante) {
          filteredData = data.filter(part => part.nombre_participante === parseInt(nombre_participante));
        }

        // Obtener el árbol de directorios
        const { data: dataDirectorios } = await getArbolDirectorios();

        // Recorremos los participantes para verificar archivos
        const participantesConArchivo = data.map(part => {

          // Define las rutas de búsqueda para el participante
          const rutas = [
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Cédula`, campo: 'validado_registraduria', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Procuraduría`, campo: 'validado_procuraduria', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Policía`, campo: 'validado_policia', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/RNMC`, campo: 'RNMC', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Contraloría`, campo: 'validado_contraloria', valor: 'X' }
          ];

          // Verifica los archivos para cada ruta y actualiza el campo correspondiente
          const updatedPart = { ...part };
          let progreso = 0;
          rutas.forEach(({ ruta, campo, valor }) => {
            // Convierte la ruta en una lista de nombres
            const rutaLista = transformarRutaARutaLista(ruta);

            // Verifica si el archivo existe
            if (contieneArchivo(dataDirectorios, rutaLista)) {
              progreso = progreso + 20;
              updatedPart[campo] = valor;
            } else {
              updatedPart[campo] = ''; // O cualquier valor que desees para el caso en que no se encuentra el archivo
            }
          });
          updatedPart['progress'] = progreso;
          return updatedPart;
        });

        setParticipantes(participantesConArchivo || []);

        // Obtener departamentos únicos para filtros
        const uniqueDepartamentos = [...new Set(filteredData.map(part => part.departamento))];
        const filtersDepartamentos = uniqueDepartamentos.map(dep => ({ text: dep, value: dep }));
        setDepartamentosFilters(filtersDepartamentos);

        // Obtener municipios únicos para filtros
        const uniqueMunicipios = [...new Set(filteredData.map(part => part.municipio))];
        const filtersMunicipios = uniqueMunicipios.map(mun => ({ text: mun, value: mun }));
        setMunicipiosFilters(filtersMunicipios);

        // Obtener organizaciones únicos para filtros
        const uniqueOrganizaciones = [...new Set(filteredData.map(part => part.organizacion))];
        const filtersOrganizaciones = uniqueOrganizaciones.map(org => ({ text: org, value: org }));
        setOrganizacionesFilters(filtersOrganizaciones);

        // Obtener linea productiva  únicos para filtros
        const uniqueLineaproductiva_1 = [...new Set(filteredData.map(part => part.linea_productiva_1))];
        const filtersLineaproductiva_1 = uniqueLineaproductiva_1.map(lp => ({ text: lp, value: lp }));
        setLineaProductiva_1Filters(filtersLineaproductiva_1);


      } else {
        setParticipantes([]);
      }
    } catch (error) {
      setStatus(500);
      setParticipantes([]);
    } finally {
      setLoading(false);
    }
  };

  //Obtenemos la lista de participantes 
  useEffect(() => {

    fetchPartcipantes();

  }, [nombre_participante]);


  // Función para transformar una ruta de cadena a una lista de nombres
  function transformarRutaARutaLista(ruta) {
    return ruta.split('/').filter(segment => segment);
  }

  const contieneArchivo = (dataDirectorios, nombres) => {
    // Inicia la búsqueda desde el nivel más alto
    let currentNode = dataDirectorios.find(node => node.name === nombres[0]);

    // Iterar sobre los nombres proporcionados
    for (let i = 1; i < nombres.length; i++) {
      if (!currentNode || !currentNode.children) {
        // Si el nodo o sus hijos no existen, retornamos false
        return false;
      }

      // Avanzar al siguiente nodo en la jerarquía
      currentNode = currentNode.children.find(child => child.name === nombres[i]);
    }

    // Finalmente, verificar si el nodo final tiene archivos
    if (currentNode && currentNode.children && currentNode.children.length > 0) {
      return currentNode.children.some(child => child.type === "file");
    }

    return false;
  }





  //Funcion para mensajesdependiendo el estado de la solicitud al backend
  const renderErrorMessage = (status) => {
    if (status === 404) {
      return (
        <Alert type="warning" message="Advertencia" description="Lo sentimos, no se encuentran participantes disponibles para mostrar." showIcon icon={<FontAwesomeIcon icon={faExclamationTriangle} />} />
      );
    } else if (status === 500) {
      return (
        <Alert type="error" message="Error" description="Lo sentimos, ha ocurrido un error al obtener los participantes. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co" showIcon icon={<FontAwesomeIcon icon={faExclamationCircle} />} />
      );
    }
  };





  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPagination(pagination);
    setSortOrder(sorter.order);
    setSortField(sorter.field);
    setFilteredInfo(filters);
  };


  //Configuración d elas columnas d ela tabla
  const noDataConfig = {
    emptyText: (
      <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <InboxOutlined style={{ fontSize: '2rem' }} />
        <span>Sin resultados</span>
      </span>
    ),
  };


  // Define la función de formato de fecha
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0-indexados
    const day = String(date.getDate()).padStart(2, '0'); // Asegura que el día tenga dos dígitos

    return `${day}/${month}/${year}`;
  }


  const exportToExcel = () => {
    const fileExtension = '.xlsx';
    const fileName = 'Participantes_UTF_COL_160' + fileExtension;

    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Participantes');
    // Encabezado de múltiples niveles

    // Función auxiliar para convertir columna a número
    const columnToNumber = (column) => {
      let result = 0;
      for (let i = 0; i < column.length; i++) {
        result *= 26;
        result += column.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
      }
      return result;
    };

    // Función auxiliar para convertir número a columna
    const numberToColumn = (number) => {
      let result = '';
      while (number > 0) {
        let remainder = (number - 1) % 26;
        result = String.fromCharCode(65 + remainder) + result;
        number = Math.floor((number - 1) / 26);
      }
      return result;
    };

    // Función para aplicar color de fondo a un rango de celdas
    const applyBackgroundColor = (startCol, endCol, rowNum, color) => {
      const startColNum = columnToNumber(startCol);
      const endColNum = columnToNumber(endCol);

      for (let colNum = startColNum; colNum <= endColNum; colNum++) {
        const currentCol = numberToColumn(colNum);
        const cellRef = `${currentCol}${rowNum}`;
        worksheet.getCell(cellRef).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        };
      }
    };

    // Encabezado titulo
    worksheet.mergeCells('A1:I1'); // Titulo
    worksheet.getCell('A1').value = 'LISTA DE PARTICIPANTES';


    // Ajustar el formato específico para la fila 1
    var row1 = worksheet.getRow(1);
    row1.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 16, bold: true }; // Aplicar negrita y tamaño de fuente 16
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "5b9bd5" }, // Color de fondo
      };
    });



    // Encabezado de múltiples niveles
    worksheet.mergeCells('A2:K2'); // DATOS BÁSICOS
    worksheet.mergeCells('L2:W2'); // POBLACIÓN
    worksheet.mergeCells('X2:AE2'); // INFORMACIÓN FAMILIAR
    worksheet.mergeCells('AF2:BB2'); // INFORMACIÓN DEL PROYECTO VINCULADO
    worksheet.mergeCells('BC2:BF2'); // DATOS DEL CONTACTO DEL BENEFICIARIO
    worksheet.mergeCells('BG2:BH2'); // CONECTIVIDAD
    worksheet.mergeCells('BI2:BP2'); // INFORMACIÓN BANCARIA
    worksheet.mergeCells('BQ2:BT2'); // INFORMACIÓN ORGANIZACIONAL
    worksheet.mergeCells('BU2:BZ2'); // VALIDACIÓN INTERNA

    // Asignación de valores a las celdas combinadas
    worksheet.getCell('A2').value = 'DATOS BÁSICOS';
    worksheet.getCell('L2').value = 'POBLACIÓN';
    worksheet.getCell('X2').value = 'INFORMACIÓN FAMILIAR';
    worksheet.getCell('AF2').value = 'INFORMACIÓN DEL PROYECTO VINCULADO';
    worksheet.getCell('BC2').value = 'DATOS DEL CONTACTO DEL BENEFICIARIO';
    worksheet.getCell('BG2').value = 'CONECTIVIDAD';
    worksheet.getCell('BI2').value = 'INFORMACIÓN BANCARIA';
    worksheet.getCell('BQ2').value = 'INFORMACIÓN ORGANIZACIONAL';
    worksheet.getCell('BU2').value = 'VALIDACIÓN INTERNA';

    // Asignamos colores a las celdas combinadas
    worksheet.getCell('A2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '1E4E79' }
    };

    worksheet.getCell('L2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '385623' }
    };

    worksheet.getCell('X2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2E75B5' }
    };

    worksheet.getCell('AF2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BF9000' }
    };

    worksheet.getCell('BC2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '7B7B7B' }
    };

    worksheet.getCell('BG2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '8EAADB' }
    };


    worksheet.getCell('BQ2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '7F6000' }
    };

    worksheet.getCell('BU2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '1E4E79' }
    };
    // Ajustar el formato específico para la fila 2
    var row2 = worksheet.getRow(2);
    row2.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = {
        size: 14,
        bold: true,
        color: { argb: 'FFFFFFFF' }  // Añadimos esta línea para hacer el texto blanco
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const datos =
      [//DATOS BASICOS
        'ID', 'Nombre completo',
        'Tipo de Identificación',
        'Número de Identificación',
        'Sexo',
        'Fecha de Nacimiento',
        '¿Sabe leer y escribir?',
        'Ingresos Mensuales (pesos m/cte)',
        'Línea Económica Principal',
        'Nivel de Escolaridad',
        'Estado dentro del proyecto',
        //POBLACIÓN
        'Campesino',
        'Comunidad Indígena	',
        'Comunidad NARP',
        'Comunidad ROM',
        'Victima',
        'Discapacidad',
        'LGBT_plus',
        'Mujer rural',
        'Joven rural',
        'Adulto mayor',
        'Reincorporado',
        'Otro',
        //INFORMACIÓN FAMILIAR
        'Cabeza de Familia',
        'Número de Personas a Cargo',
        'Total Núcleo Familiar',
        'Estado Civil',
        'Nombre del Cónyuge',
        'Tipo de Identificación Cónyuge',
        'Identificación Cónyuge',
        'Número de Contacto Cónyuge',

        //INFORMACIÓN DEL PROYECTO VINCULADO
        'Codigo proyecto',
        'Nombre proyecto',
        'Subregión PDET',
        'Departamento',
        'Municipio',
        'Tipo de zona',
        'Nombre de zona',
        'Código shape veredas ART',
        'Nombre del Predio/Finca',
        'Relación de Tenencia con el Predio',
        'Latitud',
        'Longitud',
        'No. Hectáreas Destinadas al Proyecto',
        'Linea productiva 1',
        'Variedad establecida',
        'Linea productiva 2',
        'Número de Hectáreas',
        'Producción anual',
        'Distancia Lugar del Proyecto a Cabecera Municipal - (Kilómetros)',
        'Tiempo Lugar del Proyecto a Cabecera Municipal - (Minutos)',
        'Medio de Transporte',
        '¿Vive en el mismo lugar del Proyecto?',
        '¿Lugar de Vivienda?',


        //DATOS DEL CONTACTO BENEFICIARIOS
        'Dirección de Correspondencia',
        'Número Telefónico 1',
        'Número Telefónico 2',
        'Correo eléctronico',


        //CONECTIVIDAD
        'Telefónica',
        'Internet	',


        //INFORMACIÓN BANCARIA
        'Tipo Establecimiento Bancario cercano a su Viviendaónica',
        '¿Cuenta con Productos Financieros?',
        'Cuenta de Ahorro',
        'Tarjeta de Crédito',
        'Otros Créditos',
        'Tipo de entidad',
        '¿Ha recibido Capacitación en Educación Financiera?',
        '¿Interesado en Información de Inclusión Financiera?',

        //INFORMACIÓN ORGANIZACIONAL
        '¿Vinculado a algúna Organización?',
        'Tipo de Organización',
        'Nombre de la organización',
        'Es una organización de Mujeres?',


        //VALIDACIÓN INTERNA
        '¿Validado Por Registraduría?',
        '¿Validado Por Procuraduría?',
        '¿Validado Por Policía?',
        'Mediadas correctivas',
        '¿Validación Por Contraloría',
        '% Documentación'];


    // Configuración del ancho de las columnas
    worksheet.columns = datos.map(() => ({ width: 20 }));
    // Agregar fila de encabezado
    worksheet.addRow(datos);



    // Mapear y seleccionar todos los campos de participantes
    const dataToExport = participantes.map(part => [
      //Data DATOS BASICOS
      part.oid,
      part.nombre_participante,
      part.tipo_identificacion,
      part.numero_identificacion,
      part.sexo,
      formatDate(part.fecha_nacimiento),
      part.sabe_leer_escribir,
      part.ingresos_mensuales,
      part.linea_economica,
      part.nivel_escolaridad,
      'Pendiente',
      //DATA POBLACION
      part.Campesino,
      part.Comunidad_Indigena,
      part.Comunidad_NARP,
      part.Comunidad_ROM,
      part.Victima,
      part.Discapacidad,
      part.LGBT_plus,
      part.Mujer_Rural,
      part.Joven_Rural,
      part.Adulto_Mayor,
      part.Reincorporado,
      part.Otro,
      //DATA INFORMACIÓN FAMILIAR
      part.cabeza_familia,
      part.num_personas_cargo,
      part.total_nucleo_familiar,
      part.estado_civil,
      part.nombre_conyuge,
      part.tipo_identificacion_conyuge,
      part.identificacion_conyuge,
      part.numero_contacto_conyuge,


      //INFORMACIÓN DEL PROYECTO
      part.codigo,
      part.proyecto,
      part.nombre_subregion_pdet,
      part.departamento,
      part.municipio,
      part.tipo_zona_influencia,
      part.nombre_zona,
      part.codigo_shape,
      part.nombre_predio,
      part.relacion_tenencia_predio,
      part.latitud,
      part.longitud,
      part.num_hectarias_proyecto,
      part.linea_productiva_1,
      part.variedad_establecida,
      part.linea_productiva_segundaria,
      part.num_hectareas,
      part.produccion_anual,
      part.distancia_cabecera_municipal,
      part.tiempo_cabecera_municipal,
      part.medio_transporte,
      part.vive_mismo_lugar,
      part.lugar_vivienda,

      //DATOS DEL CONTACTO DEL BENEFICIARIO
      part.direccion_correspondencia,
      part.numero_telefonico_1,
      part.numero_telefonico_2,
      part.correo_electronico,

      //DATOS CONECTIVIDAD
      part.telefonica,
      part.internet,

      //INFORMACIÓN BANCARIA
      part.establemcimiento_bancario,
      part.cuenta_con_productos_financieros,
      part.cuenta_de_ahorro,
      part.tarjeta_de_credito,
      part.otros_creditos,
      part.tipo_entidad,
      part.recibio_capacitacion_educacion_financiera,
      part.interesado_informacion_inclusion_financiera,

      //INFORMACIÓN ORGANIZACIONAL
      part.vinculado_alguna_organizacion,
      part.tipo_organizacion,
      part.organizacion,
      part.org_mujeres,

      //DATOS VALIDACIÓN
      part.validado_registraduria,
      part.validado_procuraduria,
      part.validado_policia,
      part.RNMC,
      part.validado_contraloria,
      part.progress

    ]);


    // Agregar los datos a la hoja
    worksheet.addRows(dataToExport);

    // Aplicar colores a todos los rangos
    applyBackgroundColor('A', 'K', '3', 'FFD7E2F4');
    applyBackgroundColor('L', 'W', '3', 'FFE3EFD9');
    applyBackgroundColor('X', 'AE', '3', 'DEEAF6');
    applyBackgroundColor('AF', 'AJ', '3', 'FFFEF2CB');
    applyBackgroundColor('AK', 'AQ', '3', 'FFDEEAF6');
    applyBackgroundColor('AR', 'AW', '3', 'FFECECEC');
    applyBackgroundColor('AX', 'BB', '3', 'FFFEF2CB');
    applyBackgroundColor('BC', 'BF', '3', 'FFD0CECE');
    applyBackgroundColor('BG', 'BH', '3', 'FFD9E3F3');
    applyBackgroundColor('BI', 'BP', '3', 'FFFBE4D5');
    applyBackgroundColor('BQ', 'BT', '3', 'FFFFE598');



    // Ajustar el formato específico para la fila 3
    var row3 = worksheet.getRow(3);
    row3.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true }; // Aplicar negrita y tamaño de fuente 16
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Ajustar el formato de todas las celdas
    worksheet.eachRow(
      { includeEmpty: true },
      function (row, rowNumber) {
        row.eachCell(
          { includeEmpty: true },
          function (cell, colNumber) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            }; // Bordes
          }
        );
      }
    );


    // Guardar el archivo Excel
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });
  };

  //Función para buscar un participante
  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredParticipantes = participantes.filter(part =>
      part.nombre_participante.toLowerCase().includes(searchTerm)
    );
    setParticipantes(filteredParticipantes);

  };

  const handleSearchClick = () => {
    const filteredOrganizaciones = participantes.filter(org =>
      org.nombre_participante.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setParticipantes(filteredOrganizaciones); // Actualiza el estado con las organizaciones filtradas
  };



  //Función para limpiar filtro de busqueda
  const clearAllFilters = async () => {
    setFilteredInfo({});
    setSearchTerm('');
    try {
      const { status, data } = await getParticipantes();
      if (status === 200) {

        // Obtener el árbol de directorios
        const { data: dataDirectorios } = await getArbolDirectorios();

        // Recorremos los participantes para verificar archivos
        const participantesConArchivo = data.map(part => {


          // Define las rutas de búsqueda para el participante
          const rutas = [
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Cédula`, campo: 'validado_registraduria', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Procuraduría`, campo: 'validado_procuraduria', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Policía`, campo: 'validado_policia', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/RNMC`, campo: 'RNMC', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Contraloría`, campo: 'validado_contraloria', valor: 'X' }
          ];

          // Verifica los archivos para cada ruta y actualiza el campo correspondiente
          const updatedPart = { ...part };
          let progreso = 0;
          rutas.forEach(({ ruta, campo, valor }) => {
            // Convierte la ruta en una lista de nombres
            const rutaLista = transformarRutaARutaLista(ruta);

            // Verifica si el archivo existe
            if (contieneArchivo(dataDirectorios, rutaLista)) {
              progreso = progreso + 20;
              updatedPart[campo] = valor;
            } else {
              updatedPart[campo] = ''; // O cualquier valor que desees para el caso en que no se encuentra el archivo
            }
          });
          updatedPart['progress'] = progreso;
          return updatedPart;
        });


        if (nombre_participante) {

          const filteredData = participantesConArchivo.filter(part => part.nombre_participante === parseInt(nombre_participante));
          setParticipantes(filteredData || []);
        } else {
          setParticipantes(participantesConArchivo || []);
        }
      } else {
        setParticipantes([]);
      }
    } catch (error) {
      setParticipantes([]);
    }
  };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilteredInfo({ ...filteredInfo, [dataIndex]: selectedKeys });
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setFilteredInfo({ ...filteredInfo, [dataIndex]: null });
    confirm();
  };


  const getColumnSearchProps = (dataIndex) => {
    let searchInput = React.createRef();

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            id={`${dataIndex}-search-input`}
            placeholder={`Buscar ${dataIndex}`}
            value={selectedKeys ? selectedKeys[0] : ''}

            ref={node => {
              searchInput = node;
            }}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              Resetear
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput?.select();
          }, 100);
        }
      },
    };
  };




  // Función para crear un renderizador de título con tooltip
  const createTitleRenderer = (tooltipColor = '#01060E', tooltipPlacement = 'right', maxLength = 20) => {
    return (text) => {
      // Limita la longitud del texto visible
      const truncatedText = text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
      const tooltipTitle = text || 'Sin título';

      return (
        <Tooltip title={tooltipTitle} color={tooltipColor} placement={tooltipPlacement}>
          <span>{truncatedText}</span>
        </Tooltip>
      );
    };
  };

  const columns = [
    {
      title: 'DATOS BASICOS',
      children: [
        {
          title: 'ID',
          dataIndex: 'oid',
          key: 'oid',
          width: 50,
          align: 'center',
          filteredValue: null,

        },
        {
          title: 'Nombre completo',
          dataIndex: 'nombre_participante',
          key: 'nombre_participante',
          width: 50,
          align: 'left',
          filteredValue: null,
          render: createTitleRenderer() // Usa el renderizador de tooltip
        },
        {
          title: 'Tipo de Identificación',
          dataIndex: 'tipo_identificacion',
          key: 'tipo_identificacion',
          width: 150,
          align: 'left',
          filteredValue: null,

        },
        {
          title: 'Número de Identificación',
          dataIndex: 'numero_identificacion',
          key: 'numero_identificacion',
          ...getColumnSearchProps('numero_identificacion'),
          width: 150,
          align: 'left',
          filterSearch: true,
          filteredValue: filteredInfo.numero_identificacion || null,
          fixed: 'left',
        },
        {
          title: 'Sexo',
          dataIndex: 'sexo',
          key: 'sexo',
          width: 50,
          align: 'center',
          filteredValue: filteredInfo.sexo || null,
          filters: [
            {
              text: 'Hombre',
              value: 'Hombre',
            },
            {
              text: 'Mujer',
              value: 'Mujer',
            },
          ],
          onFilter: (value, record) => record.sexo.indexOf(value) === 0,
        },
        {
          title: 'Fecha de Nacimiento',
          dataIndex: 'fecha_nacimiento',
          key: 'fecha_nacimiento',
          width: 150,
          align: 'center',
          filteredValue: null,
          render: (text) => formatDate(text), // Aplica el formateo



        },
        {
          title: '¿Sabe leer y escribir?',
          dataIndex: 'sabe_leer_escribir',
          key: 'sabe_leer_escribir',
          width: 150,
          filterMode: 'tree',
          align: 'center',
          filteredValue: null,

        },
        {
          title: 'Ingresos Mensuales',
          dataIndex: 'ingresos_mensuales',
          key: 'ingresos_mensuales',
          width: 150,
          align: 'left',
          filteredValue: null,

        },

        {
          title: 'Línea Económica Principal',
          dataIndex: 'linea_economica',
          key: 'linea_economica',
          width: 100,
          align: 'left',
          filteredValue: null,
          render: createTitleRenderer() // Usa el renderizador de tooltip

        },

        {
          title: 'Nivel de Escolaridad',
          dataIndex: 'nivel_escolaridad',
          key: 'nivel_escolaridad',
          width: 150,
          align: 'left',
          filteredValue: null,

        },

        {
          title: 'Estado dentro del proyecto',
          dataIndex: 'estado',
          key: 'estado',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
      ],
    },

    {
      title: 'POBLACIÓN',
      children: [
        {
          title: 'Campesino',
          dataIndex: 'Campesino',
          key: 'Campesino',
          width: 110,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'Comunidad Indígena',
          dataIndex: 'Comunidad_Indigena',
          key: 'Comunidad_Indigena',
          width: 120,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'Comunidad NARP',
          dataIndex: 'Comunidad_NARP',
          key: 'Comunidad_NARP',
          width: 120,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'Comunidad ROM',
          dataIndex: 'Comunidad_ROM',
          key: 'Comunidad_ROM',
          width: 120,
          align: 'center',
          filteredValue: null,

        },
        {
          title: 'Victima',
          dataIndex: 'Victima',
          key: 'Victima',
          width: 110,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'Discapacidad',
          dataIndex: 'Discapacidad',
          key: 'Discapacidad',
          width: 130,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'LGBT_plus',
          dataIndex: 'LGBT plus',
          key: 'LGBT plus',
          width: 120,
          align: 'center',
          filteredValue: null,

        },
        {
          title: 'Mujer rural',
          dataIndex: 'Mujer_Rural',
          key: 'Mujer_Rural',
          width: 110,
          align: 'Center',
          filteredValue: null,

        },

        {
          title: 'Joven rural',
          dataIndex: 'Joven_Rural',
          key: 'Joven_Rural',
          width: 110,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'Adulto mayor',
          dataIndex: 'Adulto_Mayor',
          key: 'Adulto_Mayor',
          width: 110,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'Reincorporado',
          dataIndex: 'Reincorporado',
          key: 'Reincorporado',
          width: 140,
          align: 'Center',
          filteredValue: null,

        },
        {
          title: 'Otro',
          dataIndex: 'Otro',
          key: 'Otro',
          width: 100,
          align: 'Center',
          filteredValue: null,

        },
      ],
    },

    {
      title: 'INFORMACIÓN FAMILIAR',
      children: [
        {
          title: 'Cabeza de Familia',
          dataIndex: 'cabeza_familia',
          key: 'cabeza_familia',
          width: 150,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Número de Personas a Cargo',
          dataIndex: 'num_personas_cargo',
          key: 'num_personas_cargo',
          width: 200,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Total Núcleo Familiar',
          dataIndex: 'total_nucleo_familiar',
          key: 'total_nucleo_familiar',
          width: 150,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Estado Civil',
          dataIndex: 'estado_civil',
          key: 'estado_civil',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Nombre del Cónyuge',
          dataIndex: 'nombre_conyuge',
          key: 'nombre_conyuge',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip
        },
        {
          title: 'Tipo de Identificación Cónyuge',
          dataIndex: 'tipo_identificacion_conyuge',
          key: 'tipo_identificacion_conyuge',
          width: 200,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Identificación Cónyuge',
          dataIndex: 'identificacion_conyuge',
          key: 'identificacion_conyuge',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Teléfono Cónyuge',
          dataIndex: 'numero_contacto_conyuge',
          key: 'numero_contacto_conyuge',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
      ],
    },


    {
      title: 'INFORMACIÓN DEL PROYECTO VINCULADO',
      children: [
        {
          title: 'Codigo proyecto',
          dataIndex: 'codigo',
          key: 'codigo',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Nombre proyecto',
          dataIndex: 'proyecto',
          key: 'proyecto',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip

        },
        {
          title: 'Subregión PDET',
          dataIndex: 'nombre_subregion_pdet',
          key: 'nombre_subregion_pdet',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip
        },
        {
          title: 'Departamento',
          dataIndex: 'departamento',
          key: 'departamento',
          sorter: {
            compare: (a, b) => a.departamento.localeCompare(b.departamento),
            multiple: 3,
          },
          filterSearch: true,
          filters: departamentosFilters,
          filteredValue: filteredInfo.departamento || null,
          onFilter: (value, record) => (record.departamento || '').startsWith(value),
          width: 150,
        },

        {
          title: 'Municipio',
          dataIndex: 'municipio',
          key: 'municipio',
          sorter: {
            compare: (a, b) => a.municipio.localeCompare(b.municipio),
            multiple: 3,
          },
          filterSearch: true,
          filters: municipiosFilters,
          filteredValue: filteredInfo.municipio || null,
          onFilter: (value, record) => (record.municipio || '').startsWith(value),
          width: 150,
        },
        {
          title: 'Tipo de zona',
          width: 150,
          align: 'left',
          dataIndex: 'tipo_zona_influencia',
          key: 'tipo_zona_influencia',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Nombre de zona',
          width: 150,
          align: 'left',
          dataIndex: 'nombre_zona',
          key: 'nombre_zona',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip
        },
        {
          title: 'Código shape veredas ART',
          width: 150,
          align: 'left',
          dataIndex: 'codigo_shape',
          key: 'codigo_shape',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Nombre del Predio/Finca',
          width: 150,
          align: 'left',
          dataIndex: 'nombre_predio',
          key: 'nombre_predio',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip
        },
        {
          title: 'Relación de Tenencia con el Predio',
          width: 200,
          align: 'left',
          dataIndex: 'relacion_tenencia_predio',
          key: 'relacion_tenencia_predio',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Latitud',
          width: 100,
          align: 'Center',
          dataIndex: 'latitud',
          key: 'latitud',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Longitud',
          width: 100,
          align: 'Center',
          dataIndex: 'longitud',
          key: 'longitud',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'No. Hectáreas Destinadas al Proyecto',
          width: 150,
          align: 'left',
          dataIndex: 'num_hectarias_proyecto',
          key: 'num_hectarias_proyecto',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Linea productiva 1',
          dataIndex: 'linea_productiva_1',
          key: 'linea_productiva_1',
          sorter: {
            compare: (a, b) => (a.linea_productiva_1 || '').localeCompare(b.linea_productiva_1 || ''),
            multiple: 3,
          },
          filterSearch: true,
          filters: lineaProductiva_1Filters,
          compare: (a, b) => (a.linea_productiva_1 || '').localeCompare(b.linea_productiva_1 || ''),
          filteredValue: filteredInfo.linea_productiva_1 || null,
          onFilter: (value, record) => (record.linea_productiva_1 || '').startsWith(value),
          width: 150,
        },
        {
          title: 'Variedad establecida',
          width: 150,
          align: 'left',
          dataIndex: 'variedad_establecida',
          key: 'variedad_establecida',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Línea Productiva 2',
          width: 150,
          align: 'left',
          dataIndex: 'linea_productiva_segundaria',
          key: 'linea_productiva_segundaria',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Número de Hectáreas',
          dataIndex: 'num_hectareas',
          key: 'num_hectareas',
          width: 150,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Producción anual',
          dataIndex: 'produccion_anual',
          key: 'produccion_anual',
          width: 150,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip


        },
        {
          title: 'Distancia Lugar del Proyecto a Cabecera Municipal - (Kilómetros)',
          dataIndex: 'distancia_cabecera_municipal',
          key: 'distancia_cabecera_municipal',
          width: 300,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Tiempo Lugar del Proyecto a Cabecera Municipal - (Minutos)',
          dataIndex: 'tiempo_cabecera_municipal',
          key: 'tiempo_cabecera_municipal',
          width: 250,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Medio de Transporte',
          dataIndex: 'medio_transporte',
          key: 'medio_transporte',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: '¿Vive en el mismo lugar del Proyecto?',
          dataIndex: 'vive_mismo_lugar',
          key: 'vive_mismo_lugar',
          width: 200,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: '¿Lugar de Vivienda?',
          dataIndex: 'lugar_vivienda',
          key: 'lugar_vivienda',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip
        },
      ],
    },


    {
      title: 'DATOS DE CONTACTO DEL BENEFICIARIO',
      children: [
        {
          title: 'Dirección de Correspondencia',
          dataIndex: 'direccion_correspondencia',
          key: 'direccion_correspondencia',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip      
        },
        {
          title: 'Número Telefónico 1',
          dataIndex: 'numero_telefonico_1',
          key: 'numero_telefonico_1',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Número Telefónico 2',
          dataIndex: 'numero_telefonico_2',
          key: 'numero_telefonico_2',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Correo eléctronico',
          dataIndex: 'correo_electronico',
          key: 'correo_electronico',
          width: 100,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },

      ]
    },


    {
      title: 'CONECTIVIDAD',
      children: [
        {
          title: 'Telefónica',
          dataIndex: 'telefonica',
          key: 'telefonica',
          width: 110,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Internet',
          dataIndex: 'internet',
          key: 'internet',
          width: 100,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },

      ]
    },

    {
      title: 'INFORMACIÓN BANCARIA',
      children: [
        {
          title: 'Tipo Establecimiento Bancario cercano a su Viviendaónica',
          dataIndex: 'establemcimiento_bancario',
          key: 'establemcimiento_bancario',
          width: 250,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: '¿Cuenta con Productos Financieros?',
          dataIndex: 'cuenta_con_productos_financieros',
          key: 'cuenta_con_productos_financieros',
          width: 200,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Cuenta de Ahorro',
          dataIndex: 'cuenta_de_ahorro',
          key: 'cuenta_de_ahorro',
          width: 150,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Tarjeta de Crédito',
          dataIndex: 'tarjeta_de_credito',
          key: 'tarjeta_de_credito',
          width: 150,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Otros Créditos ',
          dataIndex: 'otros_creditos',
          key: 'otros_creditos',
          width: 150,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Tipo de entidad',
          dataIndex: 'tipo_entidad',
          key: 'tipo_entidad',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: '¿Ha recibido Capacitación en Educación Financiera?',
          dataIndex: 'recibio_capacitacion_educacion_financiera',
          key: 'recibio_capacitacion_educacion_financiera',
          width: 250,
          align: 'center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: '¿Interesado en  Información de Inclusión Financiera?',
          dataIndex: 'interesado_informacion_inclusion_financiera',
          key: 'interesado_informacion_inclusion_financiera',
          width: 250,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
      ]
    },
    {
      title: 'INFORMACIÓN ORGANIZACIONAL',
      children: [
        {
          title: '¿Vinculado a algúna Organización?',
          dataIndex: 'vinculado_alguna_organizacion',
          key: 'vinculado_alguna_organizacion',
          width: 200,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Tipo de Organización',
          dataIndex: 'tipo_organizacion',
          key: 'tipo_organizacion',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
          render: createTitleRenderer() // Usa el renderizador de tooltip


        },
        {
          title: 'Nombre de la organización',
          dataIndex: 'organizacion',
          key: 'organizacion',
          sorter: {
            compare: (a, b) => (a.organizacion || '').localeCompare(b.organizacion || ''),
            multiple: 3,
          },
          filterSearch: true,
          filters: organizacionesFilters,
          filteredValue: filteredInfo.organizacion || null,
          onFilter: (value, record) => (record.organizacion || '').startsWith(value),
          width: 150,
          render: createTitleRenderer() // Usa el renderizador de tooltip

        },

        {
          title: 'Es una organización de Mujeres?',
          dataIndex: 'org_mujeres',
          key: 'org_mujeres',
          width: 200,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
      ]
    },

    {
      title: 'VALIDACIÓN INTERNA ART',
      children: [
        {
          title: '¿Validado Por Registraduría?',
          dataIndex: 'validado_registraduria',
          key: 'validado_registraduria',
          width: 150,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: '¿Validado Por Procuraduría?',
          dataIndex: 'validado_procuraduria',
          key: 'validado_procuraduria',
          width: 150,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: '¿Validado Por Policía?',
          dataIndex: 'validado_policia',
          key: 'validado_policia',
          width: 150,
          align: 'Center',
          filteredValue: null, // Ajusta el valor según sea necesario

        },
        {
          title: 'Mediadas correctivas',
          dataIndex: 'RNMC',
          key: 'RNMC',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        },

        {
          title: '¿Validado contraloria?',
          dataIndex: 'validado_contraloria',
          key: 'validado_contraloria',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario

        }
      ]
    },

    {
      title: 'Progreso',
      dataIndex: 'progress',
      key: 'progress',
      render: (progress) => (
        <Progress
          percent={progress}
          steps={5}
          strokeColor={[
            red[5], // 0% - 20%
            red[5], // 21% - 40%
            yellow[5], // 41% - 60%
            orange[4], // 61% - 80%
            green[6]  // 81% - 100%
          ]}
        />
      ),
    },
  ];

  if (loading) {
    return <div className="text-center"><Spin size="large" /></div>;
  }

  return (
    <div className="container">
      <React.Fragment>
        {status === 404 || status === 500 ? (
          renderErrorMessage(status)
        ) : (
          <React.Fragment>
            <div className="row mb-2 d-flex align-items-center">
              <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                  <p>PROYECTO UTF <br /> COL 160 COL</p>
                </div>
                <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                  <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                <h2 className="text-center mb-2">Listado de Participantes</h2>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="input-group shadow-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar participantes..."
                        value={searchTerm} // Vincula el estado de búsqueda al input
                        onChange={handleSearchInputChange} // Maneja el cambio en el input
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleSearchClick} // Maneja el clic en el botón de búsqueda
                      >
                        <SearchOutlined /> {/* Incluye el icono aquí */}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group shadow-sm">

                      <Button
                        type="danger"
                        className="btn btn-primary me-2"
                        onClick={exportToExcel}
                        size="large"
                        icon={<DownloadOutlined />}
                      >
                        Excel
                      </Button>


                      <Button
                        type="danger"
                        className="btn btn-primary"
                        onClick={clearAllFilters}
                        size="large"
                        icon={<ClearOutlined />}
                      >
                        Limpiar
                      </Button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {participantes.length > 0 ? (
              viewMode === 'table' ? (
                <Table
                  columns={columns}
                  dataSource={participantes}
                  rowKey='oid'
                  bordered
                  pagination={pagination}
                  onChange={handleTableChange}
                  sortDirections={['ascend', 'descend']}
                  sortOrder={sortOrder}
                  onSorterChange={setSortOrder}
                  sortField={sortField}
                  locale={noDataConfig}
                  loading={loading}
                  filteredInfo={filteredInfo}
                  scroll={{ y: 500, x: 'max-content' }}
                />
              ) : (
                <p className="text-center">No hay participantes para mostrar.</p>
              )
            ) : (
              <p className="text-center">No hay participantes para mostrar.</p>
            )}
          </React.Fragment>
        )}


      </React.Fragment>
    </div>
  );
};

export default Participantes;